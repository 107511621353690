.news-modal {
	.news-modal__title {
		font-weight: bold;
		font-size: 18px;
	}
	.news-modal__body {
		height: 325px;
    overflow-y: auto;
    margin-bottom: 20px;
	}
}