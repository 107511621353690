@import "src/variables";

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-22 {
  font-size: 22px;
}

.txt-standard {
  font-size: 13px;
}

.txt-note {
  font-size: 11px;
}

.txt-decoration-none {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.color-gray-dark {
  color: color("gray-dark")
}

.color-bad {
  color: color("bad-red");
}

.color-primary {
  color: color("primary");
}
.color-coral-expression {
  color: color("coral-expression");
}
