@import "src/variables";

.scrollbar-wrapper {
  position: relative;
};
.scrollbar-wrapper__content {
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  // Chrome
  &::-webkit-scrollbar {
    width: 0;
  }
  // Firefox
  scrollbar-width: none;
}
.scrollbar-wrapper__scroll {
  position: absolute;
  top: 0;
  width: 10px;
  height: 100%;
  right: 0;
  > div {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: color("gray-light");
    scroll-behavior: smooth;
    &:hover {
      background-color: color("gray-hover");
    }
    &:active {
      background-color: color("gray-dark");
      &:after {
        content: '';
        z-index: 5;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}