@import "src/variables";
@import "src/mixins";

.select-popup {
  position: relative;
  &__actions {
    display: flex;
    align-items: center;
  }
  &__choice {
    padding: 0;
    margin-right: 10px;
  }
  &__content {
    width: 300px;
    box-shadow: 0 0 10px $box-shadow-color;
    border-radius: 5px;
    background-color: #ffffff;
    z-index: 1983;
    margin-top: 5px;
    &:after{
      content: '';
      display: block;
      height: 10px;
      margin-bottom: -10px;
    }
  }
  &__submenu {
    width: 245px;
    box-shadow: 0 0 10px $box-shadow-color;
    border-radius: 5px;
    background-color: #ffffff;
    z-index: 1983;
    padding: 8px 0;
  }
  &__submenu-item {
    cursor: pointer;
    line-height: normal;
    padding: 3px 15px;
    &:hover {
      background-color: #F4F4F4;
    }
  }
  &__title {
    height: 43px;
    padding: 10px 0 10px 15px;
    font-size: 18px;
    font-weight: bold;
    background-color: color("base");
    border-bottom: 1px solid #ddd;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
  }
  &__body {
    padding: 10px 0 4px;
  }
  &__search-group {
    padding: 0 15px;
    margin-bottom: 5px;
    position: relative;
  }
  &__search-input {
    text-align: left;
    color: $black;
    height: 30px;
    padding: 5px 30px 5px 10px;
    font-size: .8125rem;
    line-height: 1.5;
    border-radius: .1875rem;
    display: block;
    width: 100%;
    font-weight: 400;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &::placeholder {
      color: #CCCCCC;
      font-size: 13px;
    }
  }
  &__search-icon {
    font-size: 13px;
    color: #666666;
    position: absolute;
    right: 8%;
    top: 30%
  }
  &__item--selected,
  &__item:hover {
    background: #F4F4F4 0% 0% no-repeat padding-box;
  }
  &__item {
    cursor: pointer;
    padding: 0 15px;
    font-size: 13px;
    line-height: 26px;
    word-wrap: break-word;
  }
  &__multiple-actions {
    display: flex;
    flex-wrap: nowrap;
    padding: 0 15px 5px 15px;
    .btn-link {
      margin-right: 10px;
    }
  }
  &__multiple-limit {
    flex-grow: 1;
    font: normal 13px/26px Meiryo;
    text-align: right;
    color: color("gray-hover");
  }
  &__item--multiple {
    padding-top: 5px;
    padding-bottom: 5px;
    word-break: break-all;
  }
  &__item-list {
    margin-bottom: 10px;
    &--register {
      .scrollbar-wrapper__content {
        padding-bottom: 10px;
      }
    }
  }
  &__item-list--order {
    display: flex;
    flex-direction: column;
  }
  &__item-list--warning {
    font-size: 11px;
    text-align: center;
    padding: 5px 0;
    margin: 3px 15px 0;
    min-height: 43px;
    background-color: #F4F4F4;
  }
  &__empty {
    text-align: center;
    margin: 40px 15px 50px;
  }
  &__register {
    padding: 10px 15px;
    margin: -10px 0 10px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    &[hidden] {
      display: block !important;
    }
    &:not([hidden]) {
      border: none;
      margin-top: 0;
      padding: 0 15px 6px;
      margin-bottom: 0;
    }
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    padding: 0 15px 6px;
    .btn-cancel {
      margin-right: 10px;
    }
  }
}
.select-popup--single {
  display: flex;
  align-items: center;
  .select-popup__item-list {
    margin-bottom: 0;
  }
  .select-popup__value {
    line-height: 1.6;
    max-width: calc(100% - 90px);
    word-break: break-all;
    margin-right: 10px;
  }
  .select-popup__item {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &.select-popup--selected-value {
    .select-popup__actions {
      flex: auto;
    }
  }
}
.select-popup--multiple {
  .select-popup__value {
    font: normal 13px/16px Meiryo;
    border: 1px solid #CCCCCC;
    border-radius: 3px;
    padding: 10px 10px;
    margin-top: 5px;
    max-height: 300px;
    overflow: auto;
    @include scrollbars(15px, 5px, 5px, 5px);
    .virtual-scroll__scrollable {
      display: flex;
      flex-direction: column;
      & > div {
        white-space: nowrap;
      }
    }
  }
  .select-popup__setting-mail {
    font: normal 13px/16px Meiryo;
    border: 1px solid #CCCCCC;
    border-radius: 3px;
    padding: 10px 10px;
    margin-top: 5px;
    max-height: 300px;
    overflow: auto;
  }
  .select-popup__note {
    color: #666666;
    font-size: 11px;
    margin-top: 5px;
  }
}