@import "src/variables";

select {
  color: $black;
  &.form-control-sm {
    text-align: right;
    color: $black;
    &:hover, &:focus {
      border-color: color("gray-hover");
      color: $black;
    }
  }
}

input {
  color: $black;
  &[type="number"] {
    text-align: right;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &[inputmode="numeric"] {
    text-align: right;
  }
  &.form-control {
    color: inherit;
    &:hover, &:focus {
      color: inherit;
      border-color: color("gray-hover");
    }
    &:disabled {
      background-color: theme-color(base);
      color: color(gray-hover);
    }
    &.is-invalid {
      background-image: none;
      &:focus {
        box-shadow: none;
      }
    }
  }
  &.icon-search {
    background-image: url('assets/images/ui-icons_search_black_18x18.png');
    background-position: right 10px center;
    background-repeat: no-repeat;
    padding-right: 30px;
  }
}

textarea {
  &.form-control {
    color: $black;
    cursor: auto;
    height: 100px;
    &:hover, &:focus {
      color: inherit;
      border-color: color("gray-hover");
    }
    &:disabled {
      background-color: theme-color(base);
      color: color(gray-hover);
    }
  }
  // Chrome
  &::-webkit-scrollbar {
    width: 10px !important;
    border-radius: inherit;
  }
  &::-webkit-scrollbar-track {
    background-color: $white !important;
    border-radius: inherit;
  }
  &::-webkit-scrollbar-thumb {
    border-style: solid;
    border-color: $white;
    border-width: 0 5px 0 0;
    border-radius: inherit;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    &:hover {
      background-color: color("gray-hover");
    }
    &:active {
      background-color: color("gray-dark");
    }
  }
  // Explorer
  scrollbar-face-color: color("gray-light");
  scrollbar-track-color: $white;

  // Firefox
  scrollbar-width: thin;
}

.form-check-input {
  &.is-invalid {
    &:checked {
      border-color: #E27070;
      &:before {
        background-color: #E27070;
      }
    }
    & ~ .form-check-label {
      color: inherit;
    }
  }
  &[disabled] {
    opacity: 0.3;
    &:hover {
      border-color: color("gray-light");
    }
    & ~ .form-check-label {
      color: inherit;
    }
  }
}
.form-check--ebis-radio.form-check-inline {
  display: inline-flex;
  align-items: center;
  line-height: 1.6;
  .form-check-input {
    margin-top: -1px;
  }
  .form-check-label {
    padding-top: 0;
  }
  .dragonball-icon {
    margin-bottom: 1px;
  }
}

.form-radio-wrap {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  .form-check-label {
    flex: 1;
  }
}
