@import "src/variables";

$selected: color("link");
$hover: #DCE1F2;
$span: #DCE1F2;

.single-date-picker {
  .main-panel {
    .DayPicker {
      padding: 15px 0;
      width: 100% !important;
      > div > div {
        width: 100% !important;
      }
      .DayPicker_weekHeader {
        top: 30px;
        left: 6px !important;
        padding: 0 !important;
        line-height: 1.4;

        small {
          color: black;
          font-weight: bold;
          font-size: inherit;
        }
      }

      .DayPicker_transitionContainer {
        width: 210px !important;
        height: 185px !important;
        overflow: hidden;
      }

      .CalendarMonthGrid {
        background-color: transparent;

        .CalendarMonth_caption {
          padding: 0;
          line-height: 24px;
        }
      }

      .CalendarMonthGrid__horizontal {
        left: 0;
      }

      .DayPickerNavigation {
        position: absolute;
        width: 100%;
        height: 24px;
        .DayPickerNavigation_button {
          position: absolute;
          width: 24px;
          height: 24px;
          &:first-child {
            left: 20px;
          }
          &:last-child {
            right: 20px;
          }
          &.DayPickerNavigation_button__disabled {
            border: none;
            display: none;
          }
          .icon-hover {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .single-date-picker__day {
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: 12px;
    margin: 1px 3px 1px 3px;
    cursor: pointer;
    line-height: 1.8;
    border-radius: 50%;

    &:hover {
      border-radius: 50%;
      background-color: $hover;
    }
  }

  .single-date-picker__day--empty {
    @extend .single-date-picker__day;
    pointer-events: none;
    visibility: hidden;
  }

  .single-date-picker__day--disabled {
    @extend .single-date-picker__day;
    pointer-events: none;
    opacity: 0.3;
  }

  .single-date-picker__day--today {
    @extend .single-date-picker__day;
    color: theme-color("primary")
  }

  .single-date-picker__day--selected {
    @extend .single-date-picker__day;
    background-color: $selected;
    color: $white;
    font-weight: bold;

    &:hover {
      @extend .single-date-picker__day--selected;
    }
  }
}


