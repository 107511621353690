@import "src/variables";

.confirm-modal {
  .modal-dialog {
    .modal-header:not(.modal-header-bottom-initial) {
      margin-bottom: 10px;
    }
    .modal-body {
      .error-title {
        margin-bottom: 10px;
      }
      .notice-description {
        margin-top: 5px;
        font-size: 11px;
        color: #666666;
      }
      .error-content {
        color: $black;
        font-size: 13px;
        height: 100px;
        padding: 7px 10px;
        border-radius: $border-radius;
        cursor: auto;
        resize: none;
        // Chrome
        &::-webkit-scrollbar {
          width: 10px;
          background-color: $white;
          cursor: pointer;
        }
        &::-webkit-scrollbar-track {
          background-color: $white;
          cursor: pointer;
          border-radius: 0 $border-radius $border-radius 0;
        }
        &::-webkit-scrollbar-thumb {
          background-color: color("gray-light");
          border-style: solid;
          border-color: $white;
          border-width: 0 5px 0 0;
          border-radius: 0 $border-radius $border-radius 0;
          &:hover {
            background-color: color("gray-hover");
          }
          &:active {
            background-color: color("gray-dark");
          }
        }
        // Explorer
        scrollbar-face-color: color("gray-light");
        scrollbar-track-color: $white;

        // Firefox
        scrollbar-width: thin;
      }
    }
    .modal-footer {
      .force-confirm {
        margin-right: auto;
        .form-label {
          margin-bottom: 0;
        }
      }
    }
  }
}

.confirm-modal--loading {
  .modal-dialog {
    .modal-header {
      margin-bottom: 30px;
    }
    .modal-footer {
      margin-top: 30px;
    }
  }
}
