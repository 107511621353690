@import "src/variables";
@import "src/placeholders";

.eb-switch {
  padding: 4px;

  height: 24px;
  margin-bottom: 10px;
  width: fit-content;
  cursor: pointer;

  border-radius: 24px;

  &--active {
    @extend .eb-switch;
    color: $white;
    background-color: theme-color("secondary");

    &:hover {
      background-color: black;
      color: $white;
    }

    &.disabled {
      @extend %disabled;
      background-color: color("gray-light");
    }
  }

  &.disabled {
    @extend %disabled;
  }

  &--default {
    @extend .eb-switch;
    color: $white;
    background-color: color("gray-light");

    &:hover {
      background-color: color("gray-hover");
      color: $white;
    }
  }

  &__content {
    display: flex;

    .eb-switch__text {
      padding-right: 5px;
      padding-left: 5px;
    }

    &.active {
      flex-direction: row;
    }
    &.default {
      flex-direction: row-reverse;
    }
    .eb-switch__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      font-size: 11px;
    }

    .eb-switch__toggle {
      width: 16px;
      height: 16px;
      border: 1px solid $white;
      border-radius: 50%;
      background-color: white;
      padding: 0;
      color: color("gray-dark");
      font-size: 10px;
      line-height: 16px;
      &:focus {
        outline: none;
      }
    }

    &:focus {
      outline: none;
    }
  }

}


