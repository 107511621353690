@import 'src/mixins';

.drag-drop-list {
  padding: 15px 10px 15px 15px;
  border-radius: 3px;
  background-color: #f4f4f4;
  > ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin-bottom: 0;
    padding-right: 5px;
    overflow: auto;
    max-height: 250px;
    @include scrollbars(15px, auto, 5px, 0px, #f4f4f4);
    > li {
      cursor: move;
      border: 1px solid #dbcdb4;
      border-radius: 3px;
      background-color: #fff;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
      &:hover {
        background-color: #f9f6ef;
      }
      &.item-dragging {
        color: transparent;
        border: 1px dashed #dbcdb4;
      }
    }
  }
}
