@import "src/variables";

.transition-modal {
  .transition-modal__image {
    width: 540px;
    height: 90px;
    margin-top: 10px;
  }
  .modal-dialog {
    .modal-header {
      margin-bottom: 10px;
    }
    .modal-body {
      span {font-size: 11px; color: color("gray-dark");}
    }
    .modal-footer {
      .mr-auto {margin: 0;}
      label {
        margin-bottom: 0;
        .checkbox-item {
          vertical-align: middle;
          margin-top: -3px;
        }
      }
    }
  }
}
