@import 'src/variables';

.modal-title {
  margin-right: 10px;
}
.modal__note {
  padding-top: 5px;
  font-size: 11px;
  color: color("gray-dark");
  font-weight: normal;
  white-space: pre-line;
}
