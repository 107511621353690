@import 'src/variables';

.panel {
  width: 300px;
  box-shadow: 0 0 10px $box-shadow-color;
  .panel__title {
    height: 43px;
    padding: 10px 0 10px 15px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    background-color: color('base');
    border-bottom: 1px solid #ddd;
    border-radius: 5px 5px 0px 0px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .panel__title_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .panel__title--media-sync-icon {
    margin-left: 3px;
    margin-top: 6px;
  }
  .panel__content {
    .form-group {
      padding: 0 15px;
      margin-bottom: 0;
      margin-top: 5px;
      .form-label {
        margin-bottom: 0;
        padding: 5px 0 2px 0;
        .form-check-inline {
          align-items: end;
        }
      }
      input {
        text-align: left;
      }
      input[type='text']::placeholder {
        color: color('gray-light');
      }
      .btn-link {
        margin-right: 15px;
      }
    }
    .radio-item {
      width: 100%;
      margin: 0;
      padding: 3px 15px;
      align-items: unset;
      &:hover,
      &.active {
        background-color: theme-color('base');
      }
      .form-check-input {
        flex: none;
        margin-top: 2px;
      }
      .form-check-label {
        padding-top: 0;
      }
    }
    .form-label {
      display: flex;
      padding: 3px 20px 3px 15px;
      margin-bottom: 0;
      &:hover,
      &.active {
        background-color: theme-color('base');
      }
      .checkbox-item {
        top: 2px;
      }
    }

    .filter-message {
      margin: 3px 15px 0px 15px;
      margin-bottom: 0;
    }
    .filter-type__three-description {
      padding: 3px 20px 3px 15px;
      margin-bottom: 0;
      font-size: 11px;
      color: #666666;
    }
  }
  .panel__scrollbar {
    max-height: 160px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 10px;
  }
  .panel__footer {
    font-size: 13px;
    display: flex;
    flex-direction: row-reverse;
  }
}

#filter-panel-tooltip {
  z-index: 4;
  font-size: 11px;
  line-height: 1.6;
  color: $black;
  padding: 5px 10px;
  background-color: $white;
  outline: 1px solid color('gray-hover');
  box-shadow: 0px 0px 5px #00000029;
  word-break: break-all;
  font-weight: unset;
  max-width: 285px;
}
