.upload-csv-modal-confirm {
  .spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .modal-header {
    justify-content: unset;

    .modal-title {
      margin-right: 10px;
    }
  }
  .table-modal--confirm {
    table {
      th:first-child, td:first-child, th:last-child, td:last-child {
        width: 75px;
      }
    }
    .table-modal__tbody {
      .scrollbar-wrapper__content {
        max-height: 350px;
      }
    }
  }
}
