@import 'src/variables';

.setting-views-dropdown.dropdown-menu {
  // color: #000000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  top: 100%;
  z-index: 1000;
  // color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  padding: 0;
  border: none;
  box-shadow: 0px 0px 5px $box-shadow-color;
  border-radius: 5px;
  position: absolute;
  width: 240px;
  // max-width: 240px;
  // min-width: 200px;
  border: 0;
  right: 0;
  left: unset;
}
.setting-views-dropdown__create {
  line-height: 16px;
  padding: 3px 15px;
  margin: 5px 0;
  border-radius: 0;
  display: block;
  width: 100%;
  clear: both;
  white-space: nowrap;
  border: 0;
  color: black;
  text-decoration: none;
}
.setting-views-dropdown__create:hover {
  background: color("base") 0% 0% no-repeat padding-box;
}

.setting-views-dropdown__list {
  padding: 8px 0 5px 0;
  border-radius: 0px 0px 5px 5px;
  background-color: color("base");
  font-size: 13px;
  line-height: 18px;
  white-space: nowrap;
  max-height: 195px;
  overflow: hidden;
  cursor: default;
}
.setting-views-dropdown__list-detail {
  overflow-y: auto;
  max-height: 160px;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar-track {
    background-color: color("base");
    border-radius: inherit;
  }
  &::-webkit-scrollbar {
    width: 10px;
    border-radius: inherit;
    background-color: color("base");
  }
  &::-webkit-scrollbar-thumb {
    background-color: color("gray-light");
    border-style: solid;
    border-color: color("base");
    border-width: 0 5px 0 0px;
    border-radius: 0 5px 5px 0;
    &:hover {
      background-color: color("gray-hover");
    }
    &:active {
      background-color: color("gray-dark");
    }
  }
  .scrollbar-wrapper__content {
    display: flex;
    flex-direction: column;
  }
}

.setting-views-dropdown__summary {
  padding: 4px 15px;
  font-size: 11px;
  line-height: 18px;
  font-weight: bold;
  cursor: default;
  color: #000;
}
.setting-views-dropdown__item {
  padding: 2px 15px;
  border-radius: 0;
  width: 100%;
  white-space: nowrap;
  display: flex;
  cursor: default;
}
.setting-views-dropdown__item:not([disabled]):hover,
.setting-views-dropdown__item:not(.disabled):hover {
  background: #E2E2E2 0% 0% no-repeat padding-box;
}
.setting-views-dropdown__item-title {
  line-height: 22px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  /** issue display actions icon */
  flex-shrink: 10;
  cursor: pointer;
}
.setting-views-dropdown__item:disabled .setting-views-dropdown__item-title,
.setting-views-dropdown__item.disabled .setting-views-dropdown__item-title{
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}
.setting-views-dropdown__item-title--apply {
  font-weight: bold;
}
.setting-views-dropdown__item-title--apply {
  font-weight: bold;
}
.setting-views-dropdown__empty {
  color: color("gray-light");
  padding: 4px 15px;
  opacity: 1;
  cursor: default;
}
.setting-views-dropdown__favorite-icon.unpinned {
  height: 13px;
  width: 13px;
  background-image: url("../../../assets/images/pin-grey.svg");
  background-repeat: no-repeat;
}
.setting-views-dropdown__favorite--active.pinned {
  height: 13px;
  width: 13px;
  background-image: url("../../../assets/images/pin-red.svg");
  background-repeat: no-repeat;
}
.setting-views-dropdown__favorite.fa-stack {
  height: 15px;
  // margin: auto 0;
  margin-right: 5px;
  width: 15px;
  line-height: 22px;
  cursor: pointer;
}
.setting-views-dropdown__item .fa-circle {
  color: color("base");
  font-size: 22px;
  line-height: 22px;
}
.setting-views-dropdown__item:hover .fa-circle {
  color: #E2E2E2;
}
.setting-views-dropdown__action.fa-stack {
  display: none;
  height: 22px;
  width: 22px;
  vertical-align: top;
  margin-left: 5px;
  cursor: pointer;
}
.setting-views-dropdown__item:hover .setting-views-dropdown__action.fa-stack {
  display: block;
}
.setting-views-dropdown__action:hover .fa-circle {
  color: color("base");
}
.setting-views-dropdown__action-icon.fa-stack-1x {
  font-size: 13px;
  line-height: 22px;
  width: 22px;
  color: color("gray-dark");
  font-weight: normal;
}
.setting-views-dropdown__action-icon-bg.fa-circle {
  color: color("base");
  width: 22px;
}
