@import "src/variables";

.form-group__heading { display: flex; justify-content: flex-start; align-items: center; margin-bottom: 5px;}
.form-group__heading--cv-form { display: block; border-bottom: 2px solid black; margin-bottom: 20px; padding-bottom: 3px;}
.form-group__heading--custom-view { display: block;}
.form-group__heading-text { font-size: 14px; line-height: 21px; font-weight: bold; margin: 0;}
.form-group__heading-text--cv-form { font-size: 16px; font-weight: bold; margin: 0;}
.form-group__heading-text--custom-view { font-size: 16px; font-weight: bold; border-bottom: 2px solid black; margin-bottom: 20px; padding-bottom: 3px;}
.form-group__link { font-size: 11px; line-height: 16px; color: color("link"); margin-left: 10px; opacity: 0.3;}
.form-group__link--active { opacity: 1; cursor: pointer;}
.form-group__link--custom-view { font-weight: normal; display: inline-block;}
