@import 'src/variables';

.display-items-modal__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.display-items-modal__reset-link {
  font-size: 11px;
  line-height: 16px;
  color: color('link');
  margin-left: 10px;
  opacity: 0.3;
  font-weight: normal;
}
.display-items-modal__reset-link--active {
  opacity: 1;
  cursor: pointer;
}
.display-items-modal__footer.modal-footer {
  border: 0;
}
.display-items-modal__cancel.btn {
  font-size: 13px;
  line-height: 20px;
  color: color('link');
  margin-right: 10px;
  padding: 5px 10px;
  border: 0;
}
.display-items-modal__cancel.btn:hover {
  background: #e2e2e2;
}
.display-items-modal__apply.btn {
  font-size: 13px;
  line-height: 20px;
  padding: 5px 10px;
  background-color: theme-color('secondary');
  border: 0;
}
.display-items-modal__apply.btn:hover {
  background-color: theme-color('secondary');
}

.display-items-modal--f13 {
  .ebis-axis-group {
    .axis-selectbox {
      .dropdown-menu {
        // min-width: max-content;
      }
      &:first-child .dropdown {
        width: 198px !important;
      }
      &:nth-child(2) .dropdown {
        width: 198px !important;
      }
      &:nth-child(3) .dropdown,
      &:nth-child(4) .dropdown {
        width: 127px !important;
      }
    }
  }
}
