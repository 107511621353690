@import "src/variables";

.input-collapse {
  .form-control {
    margin-bottom: 10px;
  }
  .btn-secondary {
    margin-left: 10px;
  }
  .page-loader {
    min-height: 64px;
  }
  .spinner {
    height: 30px;
  }
  .popover {
    max-width: 210px;
  }
}