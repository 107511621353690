@import 'src/variables';
@import 'src/mixins';

.modal-sc-setting {
  .modal-title {
    margin-right: 10px;
  }
  .modal-error-container {
    margin-bottom: 20px;
  }

  .form-media-info-setting--default {
    .form-media-info-setting__notification-content {
      display: flex;
      .form-label {
        margin-right: 32px;
      }
    }
    .ebis-form-group--notification_content {
      margin-bottom: 10px;
    }
    .authenticated-google {
      font-size: 11px;
      color: #34b445;
      margin-left: 10px;
    }
  }

  .searchable-select__content {
    &.scrollbar-wrapper {
      .scrollbar-wrapper__scroll {
        display: block;
      }
      > .scrollbar-wrapper__content {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  .form-media-info-setting {
    &.scrollbar-wrapper {
      margin: 0 -30px;
      .scrollbar-wrapper__scroll {
        display: block;
      }
      > .scrollbar-wrapper__content {
        padding: 0 30px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .authenticated-google {
      font-size: 11px;
      color: #34b445;
      margin-left: 10px;
    }
  }

  .btn-google-signin {
    background-image: url('../../../../assets/images/google-signin.png');
    width: 145px;
    height: 35px;
    background-size: cover;
    // margin-bottom: 20px;
  }

  .btn-clear-authenticated-google {
    font-size: 11px;
    padding: 0px;
    margin-top: 3px;
  }

  .google-signin__notice {
    font-size: 11px;
    color: #666666;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .google-signin__notice--authen {
    font-size: 11px;
    color: #666666;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .form-media-info-setting__send-mail-user {
    .form-group {
      margin-bottom: 3px;
    }
    .grid-table__wrapper--freeze {
      border-left: 1px solid #dddddd;
      .grid-table__scrollable-wrapper {
        margin-left: -1px;
      }
    }
    .grid-table__wrapper--main {
      border-right: 1px solid #dddddd;
      .grid-table__scrollable-wrapper {
        margin-right: -1px;
      }
    }
  }

  .form-media-info-setting__send-mail-user__note {
    font-size: 11px;
    p {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
      img {
        margin-right: 5px;
      }
    }
    .user-notice {
      color: color('gray-dark');
    }
  }

  .form-confirm-setting {
    margin-right: -30px;
    overflow: auto;
    max-height: 533px;
    @include scrollbars(20px, auto, 0px, 10px);
    .form-confirm-setting__header {
      font-size: 13px;
      margin-bottom: 10px;
      color: #000000;
    }
    .ebis-form-group--media-info {
      width: calc(100% - 30px);
      .table-modal {
        .table-modal__tbody .table tr:first-child {
          border: 1px solid #dddddd;
          border-bottom: none;
        }
        .table-modal__tbody .table tr td {
          border-bottom: none;
        }
      }
    }
    .form-confirm-setting__media-info--title {
      background-color: #fff;
      width: 282px;
      min-width: 282px;
      border: 1px solid #ddd;
    }
    .form-confirm-setting__media-info--value {
      width: 459px;
      background-color: #fff;
      border: 1px solid #ddd;
    }
    .ebis-form-group--confirm-media-info {
      width: calc(100% - 10px);
      .table-modal {
        .table-modal__tbody .table tr:first-child {
          border: 1px solid #dddddd;
          border-bottom: none;
        }
        .table-modal__tbody .table tr td {
          border-bottom: none;
          vertical-align: middle !important;
        }
      }
    }
  }
  .form-confirm-setting__notice {
    font-size: 13px;
  }

  .form-confirm-setting__notice-description {
    font-size: 11px;
    color: #666666;
  }

  .modal-loader-sc-setting {
    min-height: 306px;
  }

  .modal-loader-sc-setting--media-info {
    min-height: 372px;
  }

  .form-setting__loader {
    min-height: 230px;
  }

  .modal-footer {
    margin-top: 0px;
  }

  .modal-footer--media-info {
    margin-top: 20px;
  }

  .scrollbar-wrapper__content {
    overflow-x: unset;
  }

  .scrollbar-wrapper__scroll {
    display: none;
  }

  .searchable-select__content {
    min-height: auto;
  }

  .dropdown-select {
    .dropdown-toggle {
      display: flex;
      justify-content: space-between;
      width: 250px;
    }
  }
}

.sc-dropdown {
  .dropdown-label {
    &::after {
      content: '';
      display: inline-block;
      color: inherit;
      vertical-align: middle;
      margin-left: 10px;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
    & > img {
      margin-right: 15px;
    }
  }
  .active {
    .dropdown-label {
      &::after {
        content: '';
        border: none;
        border-bottom: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }
    }
  }

  position: relative;
}

.sc-menu {
  width: 102px;
  left: 0;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 8.5px;
  z-index: 11;
  padding: 5.5px 0px;
  box-shadow: 0px 0px 10px #00000029;
  &-item {
    padding: 5px 10px;
    border-radius: 3px;
    &:hover {
      background: #f4f4f4;
      border-radius: 3px;
      cursor: pointer;
    }
    & > img {
      margin-right: 5px;
    }
  }
}

.reflection-analyze {
  margin-bottom: 20px;
  .image {
    display: flex;
    justify-content: center;
  }
  .guide {
    margin-bottom: 20px;
  }
  .analyze-guide {
    font-size: 11px;
    color: #666;
  }
}

.dot {
  display: flex;
  line-height: normal;
}
.dot::before {
  content: "・";
}

.confirm-pause-flag__modal {
  &-description{
    font-size: 13px;
    margin-bottom: 10px;
    span {
      color: #d86b6b;
    }
  }
  .grid-table--basic {
    .grid-table__wrapper--freeze {
      width: 203px;
    }
    .grid-table__cell {
      &[aria-label='site_url'] {
        .grid-table__cell-content {
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    .grid-table__wrapper {
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }
  }
}
