@import "src/variables";
@import "src/mixins";

.ebis-step {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  li {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: $black;
    &:not(:first-child):before {
      content: '';
      display: inline-block;
      height: 1px;
      width: 20px;
      margin: 0 10px;
      background-color: #DDDDDD;
    }
    .circle {
      @include circle(27px, $black, $white);
      margin-right: 5px;
    }
    &:not(.active) {
      .circle, .label {
        opacity: $btn-disabled-opacity;
      }
    }
    &.active {
      .circle {
        background-color: theme-color('primary');
      }
      .label {
        font-weight: bold;
      }
    }
  }
}
