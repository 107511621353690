@import 'src/variables';
@import 'src/mixins';

.modal-email-setting {
  .modal-error-container {
    margin-bottom: 20px;
  }

  .form-confirm-setting__notice-description {
    font-size: 11px;
    color: #666666;
  }

  .modal-loader {
    min-height: 306px;
  }

  .modal-footer {
    margin-top: 20px;
  }
}

.modal-email-settings {
  height: 800px;
  min-height: 800px;
  max-height: 800px;
}

.form-group__description {
  width: 100%;
  border: 1px dashed #dbcdb4;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 5px;
  color: #666666;
  font-size: 11px;
}

.form-group:last-child {
  margin-bottom: 0;
}
