@import "./variables";

/* --- end customization ------ */

/* 3. import Bootstrap to set the changes! */
@import "../node_modules/bootstrap/scss/bootstrap.scss";

/* 4. add @mixin or @extend customizations here */
@import "./buttons.scss";
@import "./dropdowns.scss";
@import "./form.scss";
@import "./modal.scss";
@import "styles/align";
@import "styles/typography";
@import "views/atoms/checkbox/ebisCheckbox.scss";
@import "views/molecules/Tabs/tabs.scss";

:focus {
  outline: none;
}

:disabled {
  pointer-events: none;
}

body {
  min-width: 1200px;
  // Chrome
  *::-webkit-scrollbar-track {
    background-color: color("base");
  }
  *::-webkit-scrollbar {
    width: 8px;
    background-color: color("base");
  }
  *::-webkit-scrollbar-thumb {
    background-color: color("gray-light");
    &:hover {
      background-color: color("gray-hover");
    }
    &:active {
      background-color: color("gray-dark");
    }
  }
}

.clearfix:after {
  display: table;
  clear: both;
  content: "";
}

// scrollbar
.contained-overscroll {
  overscroll-behavior: contain;
}

.pagination-wrap {
  justify-content: flex-end;
  padding: 10px 30px 10px 0;
  margin-left: auto;
  text-align: right;
  line-height: initial;
  &:not(:last-child) {
    padding-right: 20px;
  }
  .pagination-sm {
    margin-left: 5px;
    margin-bottom: 0px;
    .page-item {
      text-align: center;
      min-width: 28px;
      margin-left: 2px;
      .page-link {
        font-size: $font-size-base;
        line-height: 20px;
        border: none;
        margin-left: 0px;
        i {
          font-size: 10px;
        }
      }

      &:first-child {
        margin-left: 0;
        .page-link {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }
      }

      &:last-child .page-link {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }

      &.ellipsis {
        .page-link {
          border: none;
        }
      }
      &:not(.ellipsis):hover {
        font-weight: 700;
        .far {
          font-weight: bold;
        }
      }
      &.disabled .page-link {
        color: #000000;
      }
    }

    .active {
      z-index: 0;
      font-weight: 700;
    }
  }
}

.btn-group.has-border {
  .btn {
    border: 1px solid $border-color;
  }
}

.input-group {
  .dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.badge-pill {
  & img {
    margin-left: 3px;
    vertical-align: text-bottom;
  }
}

// Make a button look and behave like a link
.below-panel {
  button.btn-link {
    &:focus, &.focus, &:hover {
      background-color: #E2E2E2;
      text-decoration: none;
      box-shadow: none;
    }
  }
}

.badge-pill {
  & img {
    margin-left: 3px;
    vertical-align: text-bottom;
  }
}

.badge-required {
  font-size: 11px;
  padding: 0 5px;
  line-height: normal;
  margin-left: 3px;
  color: $white;
  border-radius: 30px;
  background-color: #E27070;
}

button.btn-link {
  &:focus, &.focus, &:hover {
    text-decoration: underline;
    box-shadow: none;
  }
}

button.btn-light {
  background-color: white;
  border-color: white;
  color: $link-color;
  &:focus, &.focus, &:hover, &:active {
    color: $link-color;
    text-decoration: none;
    box-shadow: none;
    background-color: theme-color("base");
    border-color: theme-color("base");
  }
}

button.btn-info {
  &:focus, &:active, &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}

// Close button icon in modal
button.close {
  width: 22px;
  height: 22px;
  line-height: 22px;
  position: relative;
  top: -15px;
  right: -10px;
  span:first-child {
    display: none;
  }
  .sr-only {
    display: inline-block;
    width: auto;
    height: auto;
    overflow: unset;
    clip: unset;
    opacity: 1;
    position: relative;
    color: #666666;
    font-size: 18px;
  }
  &:focus {
    outline: none;
  }
}

// Ommited Tooltip
.tooltip[variant=ommited] {
  .tooltip-inner {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 5px #00000029;
      border: 1px solid #AAAAAA;
      opacity: 1;
      text-align: left;
      font: normal normal normal 11px/14px Meiryo;
      letter-spacing: 0px;
      color: #666666;
      width: 210;
      height: auto;
  }
  .arrow {
      display: none;
  }
}

// Tooltip
.tooltip[variant=disabled] {
  .arrow {
    width: 18px;
    height: auto;
    top: auto;
    &::before {
      border-width: 0 0.563rem 0.563rem;
      border-top-color: $tooltip-disabled-bg-color;
      border-bottom-color: $tooltip-disabled-bg-color;
    }
  }
  .tooltip-inner {
    text-align: left;
    background-color: $tooltip-disabled-bg-color;
    color: $tooltip-disabled-color;
    font-size: 11px;
    line-height: 17px;
    padding: 5px 10px;
    box-shadow: 0 0 5px $box-shadow-color;
  }
}

// Explain Tooltip
.tooltip[variant=explain] {
  .arrow {
    &::before {
      border-top-color: $tooltip-explain-bg-color;
      border-bottom-color: $tooltip-explain-bg-color;
    }
  }
  .tooltip-inner {
    text-align: left;
    background-color: $tooltip-explain-bg-color;
    color: $tooltip-explain-color;
    font: normal normal normal 11px/14px メイリオ, Meiryo, sans-serif;
    box-shadow: 0px 0px 5px #00000029;
    padding: 5px 10px;
  }
}

// Tooltip
.tooltip-common {
  margin-top: 3px;
  margin-bottom: 3px;
  .tooltip-inner {
    line-height: 1.6;
    font-size: 13px;
    padding: 7px 10px;
    border-radius: 3px;
    box-shadow: 0 0 5px $box-shadow-color;
  }
  &[variant=disabled] {
    .tooltip-inner {
      max-width: 210px;
      font-size: 11px;
      line-height: 17px;
      padding: 5px 10px;
    }
  }
}

.tooltip-common--bookmark {
  @extend .tooltip-common;
  display: inline-block;
  margin-bottom: 1px;
  .tooltip-inner {
    max-width: initial;
  }
}

// Capture html without being clipped by window viewport
.html2canvas-container { width: 3000px !important; height: 3000px !important; }

// Global disabled class
// .disabled {
//   opacity: 0.3 !important;
//   &:hover {
//     cursor: default !important;
// }

input.error,
input.error:hover,
input.error:active,
input.error:focus,
textarea.error,
textarea.error:hover,
textarea.error:active,
textarea.error:focus
{
  border: 1px solid $input-border-color-error;
}

.table__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  border-bottom: 1px solid #DDDDDD;
}

.highcharts-container .highcharts-tooltip .highcharts-shadow {
  transform: translate(0px, 0px);
}

.ebis--disabled {
  opacity: 0.3 !important;
  pointer-events: none !important;
  &:hover {
    cursor: default !important;
    background: none !important;
  }
  [disabled], .disabled {
    opacity: revert;
  }
}
.disabled-event-tooltip {
  opacity: 0.3 !important;
  &:hover, &:focus, &:active {
    cursor: default !important;
    background-color: inherit !important;
  }
}

// Tooltip error
$error-color: #E27070;
.bs-popover-top.is-error {
  font-size: 11px;
  border-color: $error-color;
  margin-bottom: 5px !important;
  transition: 0s ease-out;
  background-color: $error-color;
  & > .arrow {
    bottom: -8px;
    transition: 0s ease-out;
  }
  .popover-body {
    color: white;
    background-color: $error-color;
    box-shadow: 0 0 5px $box-shadow-color;
  }
  & > .arrow::before, & > .arrow::after {
    border-top-color: $error-color;
  }
}

$disabled-color: #999999;
.bs-popover-bottom.is-disabled {
  font-size: 11px;
  border-color: $disabled-color;
  top: 4px !important;
  max-width: 210px;
  border-radius: 5px;
  & > .arrow {
    display: block !important;
    top: -8px;
    &::before, &::after {
      border-bottom-color: $disabled-color;
    }
  }
  .popover-body {
    color: white;
    background-color: $disabled-color;
    border-radius: 3px;
    box-shadow: 0 0 5px $box-shadow-color;
  }
}

.bs-popover-bottom {
  box-shadow: unset !important;
  .popover-body {
    margin-top: -1px;
    box-shadow: 0 0 5px $box-shadow-color;
  }
}


.card--shadow {
  box-shadow: 0px 0px 10px $box-shadow-color;
  &:hover {
    box-shadow: 0px 0px 10px #00000042;
  }
}

// Class to highlight an element
.highlight {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .5);
  position: relative;
  z-index: 1060;
  pointer-events:  none;
  transition: all 0.5s ease;
}
#root.highlight-is-active {
  pointer-events:  none;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

// Typography
.text-page-title, .text-modal-title {
  font-weight: bold;
  font-size: 18px;
}
.text-section-title {
  font-weight: bold;
  font-size: 16px;
}
.text-standard {
  font-weight: normal;
  font-size: 13px;
}
.icon-hover {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  color: color(gray-dark);
  cursor: pointer;
  border-radius: 50%;
  .fa-clone {
    transform: scale(1, 1.077);
  }
  .fa-pen {
    font-size: 12px;
  }
  .fa-chevron-left, .fa-chevron-right {
    font-size: 12px;
    transform: scale(0.933, 1);
  }
  .fa-times {
    font-size: 16px;
    line-height: 11px;
    transform: scale(1.1, 1);
    margin-top: 1px;
  }
  &:hover {
    background-color: theme-color(base);
  }
}

.cursor-default {
  cursor: default !important;
}

.d-content {
  display: contents !important;
}

.hook-tooltip {
  font-size: 11px;
  line-height: initial;
  color: $black;
  padding: 5px 10px;
  background-color: $white;
  outline: 1px solid color('gray-hover');
  box-shadow: 0px 0px 5px $box-shadow-color;
  max-width: 210px;
  word-break: break-all;
}
