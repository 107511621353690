/* .ebisSwitch */
$content: '期間比較';
$with: 85px;

.switch-button {
  width: $with;
  height: 26px;
  background-color: color("gray-light");
  position: relative;
  border-radius: 50px;

  &.check {
    background-color: theme-color("secondary");

    &:after {
      left: 10px;
      right: auto;
    }

    &:hover {
      background-color: black;
    }
  }

  &:hover {
    background-color: color("gray-hover");
  }

  &:after {
    content: $content;
    color: theme-color("info");
    position: absolute;
    right: 10px;
    z-index: 0;
    font: 12px/26px Arial, sans-serif;
    font-weight: bold;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, .15);
  }

  &:before {
    color: theme-color("info");
    position: absolute;
    left: 10px;
    z-index: 0;
    font: 12px/26px Arial, sans-serif;
    font-weight: bold;
  }

  label {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 1;
    background-color: theme-color("info");
    border-radius: 50%;
    transition: all 0.4s ease;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  }

  input[type=checkbox] {
    visibility: hidden;

    &:checked+label {
      left: $with - 22px;
    }
  }
}

/* end .ebisSwitch */



/* .ebisCheckbox */
$chk-width : 15px;
$chk-height: 15px;

.checkbox-item {
  width: $chk-width;
  height: $chk-height;
  position: relative;
  margin-right: 5px;
  margin-top: -1px;
  vertical-align: middle;

  label {
    width: $chk-width;
    height: $chk-height;
    border: 2px solid $border-color;
    cursor: pointer;
    font-size: 13px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    box-shadow: none;
    background-color: theme-color("info");
    &::after {
      content: '';
      width: 10px;
      height: 6px;
      position: absolute;
      top: 0.1rem !important;
      left: 0.05rem;
      border: 3px solid #fcfff4;
      border-radius: 2px;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }
  }

  input[type=checkbox] {
    visibility: hidden;

    &:checked+label:after {
      opacity: 1;
    }

    &:checked+label {
      background-color: theme-color("secondary");
    }

    &:disabled+label {
      background-color: white;
      border-color: theme-color("disable");
    }

    &:disabled:checked+label {
      background-color: theme-color("secondary");
      opacity: 0.3;
      border-color: theme-color("secondary");
      cursor: default;
    }
  }

  &+span {
    font-size: 13px;
  }
}

.form-label:hover {
  .checkbox-item label {
    border-color: $border-hover-color;
  }

}
.checkbox-item  {
  input[type=checkbox]:checked:not([disabled])+label{
    border: 2px solid theme-color("secondary");
    &:after {
      top: 2px;
    }
  }
  .form-check-label:hover {
    border-color: $border-hover-color;
  }
}

/* end .ebisCheckbox */
$radio-width: 15px;
$radio-height: 15px;
input[type='radio'] {
  -webkit-appearance: none;
  width: $radio-width;
  height: $radio-height;
  border-radius: 50%;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid color("gray-light");
  background-color: white;
  &:hover {
    border-color: color("gray-hover");
  }
  &:before {
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }
  &:checked {
    border-color: theme-color("secondary");
    &:before {
      background: theme-color("secondary");
    }
  }
}
.form-check-inline {
  .form-check-label {
    padding-top: 2px;
  }
  :not(.align-items-center) {
    .form-check-input {
      margin-top: calc(#{$font-size-base}*.25);
    }
  }
}
.form-check-input {
  cursor: pointer;
  & + .form-check-label {
    cursor: pointer;
  }
  &[disabled] + .form-check-label {
    opacity: 0.3;
    pointer-events: none;
  }
}

.vertical-checkbox {
  &.vertical-checkbox__checkbox-item {
     margin: 0;
  }
  &.vertical-checkbox__text-label {
    min-height: 36px;
    line-height: 16px;
    label {
        margin-bottom: 0;
    }
  }
}

.form-label {
  .subtext {
    height: 14px;
    text-align: left;
    font: normal normal normal 9px/16px Meiryo;
    letter-spacing: 0px;
    opacity: 1;
    color: #AAAAAA;
  }
}
