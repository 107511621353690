@import "src/variables";
@import "src/mixins";

.maintenance-page {
  width: 100vw;
  height: 100vh;
  background-color: color("base");
  display: flex;
  align-items: center;
  justify-content: center;
}

.maintenance__container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .maintenance-content {
    width: 800px;
    margin: 30px 0 0 0;
    @include box-shadow();
  }
}

.maintenance-content {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: $white;

  .maintenance-content__body {
    margin: 30px 0 25px 0;
  }
}

.maintenance-content__footer {
  display: flex;
  justify-content: flex-end;
}
