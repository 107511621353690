@import "src/variables";

.table-modal {
  .table {
    line-height: 1.6;
    margin-bottom: 0;
  }
}
.table-modal__thead {
  .table {
    border-bottom: none;
    th {
      padding: 9px 15px;
      font-weight: normal;
      background-color: theme-color('base');
      border-bottom-width: 1px;
    }
  }
  .table-shadow {
    position: relative;
    z-index: 1;
    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      width: calc(100% - 1px);
      height: 10px;
      bottom: -10px;
      left: 1px;
      box-shadow: inset 0px 5px 4px -2px $box-shadow-color;
    }
  }
}
.table-modal__tbody {
  .table {
    border-top: none;
    word-break: break-all;
    tr {
      td {
        border: none;
        padding: 9px 15px;
        vertical-align: middle;
        &:not(:first-child) {
          border-left: 1px solid $table-border-color;
        }
      }
      &:not(:last-child) td {
        border-bottom: 1px solid $table-border-color;
      }
    }
  }
  .table-striped {
    tr {
      &:nth-of-type(odd) {
        background-color: transparent;
      }
      &:nth-of-type(even) {
        background-color: theme-color('base');
      }
    }
  }
  .table-hover tr:hover {
    color: inherit;
    background-color: $table-hover-row;
  }
}
.table-modal__empty {
  text-align: center;
  border: 1px solid $table-border-color;
  border-top: none;
  padding: 10px 0;
}
