@import "src/variables";

.ebis-form-group {
  .form-label {
    display: flex;
    align-items: center;
    .label {
      font-size: 14px;
      font-weight: bold;
    }
    .dragonball-icon {
      margin-bottom: 0;
    }
  }
  .form-text {
    color: color("gray-dark");
    font-size: 11px;
    margin-top: 5px;
  }
  .form-input {
    position: relative;
  }
}
.ebis-form-group--error-keep-position {
  .form-input {
    align-items: flex-start !important;
    flex-flow: column-reverse;
    position: relative;
  }
  .form-control.error {
    margin-top: 5px;
  }
  .popover.is-error {
    position: relative !important;
    transform: none !important;
  }
}
.ebis-form-group--disabled {
  opacity: 0.3;
  pointer-events: none;
}