@import 'src/variables';
.filter-container {
  .Panel {
    border-radius: 5px;
    .Panel__title {
      border-radius: 5px 5px 0 0;
    }
  }
  .filter-selection-menu {
    top: 26px;
    width: 250px;
    height: fit-content;
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 0 10px $box-shadow-color;
    border-radius: 5px;
    position: absolute;
    z-index: 3;
    margin-bottom: 7px;
    .scrollbar-wrapper__content {
      padding: 5px 0;
      display: flex;
      flex-direction: column;
    }
    .filter-selection-item {
      padding: 3px 20px 3px 15px;
      width: 100%;
      text-align: left;
      background-color: white;
      border: none;
      font-size: 13px;
      display: flex;
      word-wrap: break-word;
      white-space: normal;
      &:hover {
        background-color: color('base');
        cursor: pointer;
      }
      &:focus,
      &:active {
        outline: none;
      }
    }
    .filter-selection-item__sync-icon {
      margin-left: 5.5px;
      margin-top: 2.5px;
    }
    .filter-selection-group {
      margin: 5px 15px;
      padding-top: 5px;
      font-size: 11px;
      color: #666666;
      border-top: 1px solid #dddddd;
    }
  }
}
.filter-selection-menu-spacer--view {
  top: 26px;
  width: 250px;
  height: calc( 250px + 7px );
  position: absolute;
}
.filter-container--settings {
  .filter-selection-menu {
    height: auto;
  }
}
.filter-container--log-page-analyze {
  .filter-selection-menu {
    height: 200px;
  }
}
.filter-container--log-period-analyze {
  .panel {
    width: 310px;
  }
  .filter-selection-menu {
    height: 171px;
  }
  .filter-type__note {
    padding: 5px;
    color: #000000 !important;
    background-color: #F4F4F4;
  }
}
