@import 'src/variables';

.display-setting {
  margin: 30px 30px 0;
  .display-setting__screen {
    width: 740px;
    hr {
      margin: 30px 0;
      border-color: $table-border-color;
    }
    .table-view-detail {
      line-height: 20px;
    }
    .table-view-detail--label {
      td:last-child {
        width: 478px;
      }
    }
    .table-view-detail--conversion:not(.table-view-detail--empty) {
      td {
        &:first-child {
          display: none;
        }
        &:last-child {
          width: 100%;
        }
      }
    }
    .ebis-form-group {
      margin-bottom: 30px;
    }
    .ebis-form-group--conversion .form-label .label span:nth-child(2) {
      font-weight: normal;
      margin-left: 10px;
      font-size: 13px;
    }
    .ebis-form-group--rcv {
      .form-label {
        margin-bottom: 10px;
      }
    }
  }
}
.display-setting__modal-label {
  .ebis-form-group--label {
    .form-label {
      margin-bottom: 10px;
    }
    .form-items {
      display: grid;
      grid-template-columns: 87px 250px max-content;
      grid-row-gap: 5px;
      grid-column-gap: 7px;
      align-items: center;
      line-height: initial;
    }
    .form-item {
      display: contents;
    }
    .form-item--error {
      grid-column: 1 / 4;
      color: color('bad-red');
      font-size: 13px;
      line-height: 18px;
      padding-top: 5px;
    }
  }
  .ebis-form-group--conversion {
    margin-bottom: 0;
    .drag-drop-list {
      width: 545px;
    }
  }
  .modal-content {
    .page-loader {
      height: 150px;
    }
  }
}
.display-setting__modal-rcv {
  .modal-content {
    width: fit-content !important;
    min-width: 1044px;
    .page-loader {
      height: 150px;
    }
  }
  .modal-scroll {
    width: calc(100% - 10px) !important;
    margin-bottom: 1px;
  }
  .rcv-pattern {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .form-group {
      margin-bottom: 0;
      margin-right: 7px;
    }
    .dropdown-toggle,
    .dropdown-menu {
      min-width: auto;
      width: 146px;
    }
    .dropdown-toggle::after {
      margin-left: 15px;
    }
  }
  .rcv-table {
    display: grid;
    grid-template-columns: 47px repeat(11, 77px) minmax(77px, max-content);
    column-gap: 1px;
    line-height: 20px;
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px #dddddd;
    margin-left: 1px;
  }
  .rcv-row {
    display: contents;
    &:nth-of-type(odd) .rcv-cell {
      background-color: #f4f4f4;
    }
    &:first-child .rcv-cell {
      z-index: 1;
      box-shadow: 0 0 0 1px #dddddd;
    }
  }
  .rcv-cell {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child) {
      box-shadow: 1px 0 0 0 #dddddd;
    }
    .form-input {
      width: 65px;
      margin: auto;
      .form-control {
        padding: 5px 9px;
        text-align: right;
      }
    }
  }
  .rcv-table--custom {
    .rcv-row:not(:first-child) .rcv-cell:last-child {
      color: #34b445;
      padding: 0 15px;
      justify-content: end;
      background-color: #edf6ee !important;
      &.rcv-cell--highlight {
        color: #d86b6b;
      }
    }
  }
}
