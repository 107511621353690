@import "src/variables";

.grid-table {
  position: relative;
}
#grid-table__tooltip {
  position: fixed;
  z-index: 4;
  font-size: 11px;
  line-height: 1.6;
  color: $black;
  padding: 5px 10px;
  background-color: $white;
  outline: 1px solid color('gray-hover');
  box-shadow: 0px 0px 5px $box-shadow-color;
  max-width: 210px;
  word-break: break-all;
  * {
    padding: 0;
    margin: 0;
    font-size: inherit;
    text-align: left;
  }
}
.grid-table__container {
  display: flex;
  align-items: stretch;
  position: relative;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.grid-table__wrapper {
  align-self: start;
  display: grid;
  grid-template-areas: 'header' 'main';
  color: $table-color;
  font-size: $font-size-base;
  line-height: 1.6;
  width: 100%;
}
.grid-table__wrapper--freeze {
  width: auto;
  flex: none;
}
.grid-table__wrapper--maxwidth {
  max-width: 40%;
}
.grid-table__separator {
  position: sticky;
  z-index: 2;
  top: 0;
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    border-left: 1px solid $table-border-color;
  }
}
.grid-table__separator-resize {
  z-index: 1;
  position: absolute;
  width: 11px;
  height: 100%;
  left: -5px;
  mix-blend-mode: multiply;
  cursor: e-resize;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:after{
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    left: 5px;
  }
  &:hover:after {
    background-color: $table-border-hover;
  }
  &:active:before{
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
.grid-table__separator--left{
  &::after {
    width: 10px;
    height: calc(100% - 9px);
    box-shadow: -5px 0px 4px -2px $box-shadow-color;
    left: 0;
  }
  .grid-table__separator-resize:after {
    left: 4px;
  }
}
.grid-table__separator--right::after {
  width: 10px;
  height: calc(100% - 9px);
  box-shadow: inset 5px 0px 4px -2px $box-shadow-color;
}
.grid-table__separator--hidden::after {
  border-left-color: transparent;
}
.grid-table__header-wrapper {
  overflow-x: auto;
  position: sticky;
  z-index: 1;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.grid-table__header-wrapper--fixed {
  padding-bottom: 10px;
  margin-bottom: -10px;
  &:after {
    content: '';
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 10px;
    bottom: 0;
    left: 0;
    box-shadow: inset 0px 5px 4px -2px $box-shadow-color;
  }
}
.grid-table__header-container {
  overflow-x: auto;
  border-bottom: 1px solid $table-border-color;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.grid-table__header {
  display: grid;
  grid-area: header;
  min-height: 40px;
  background-color: theme-color('base');
  .grid-table__row {
    display: contents;
  }
}
.grid-table__body-wrapper {
  overflow: hidden;
  overflow-x: auto;
  border-bottom: 1px solid $table-border-color;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.grid-table__body-container {
  overflow: hidden;
  overflow-x: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.grid-table__body {
  display: grid;
  grid-area: main;
  & > .grid-table__row {
    display: grid;
    grid-template-columns: inherit;
    grid-column: inherit;
    grid-auto-flow: row dense;
    &:nth-of-type(even):not(:where(.grid-table__row--even, .grid-table__row--odd)),
    &.grid-table__row--even {
      .grid-table__cell {
        background-color: theme-color('base');
      }
      .grid-table__cell--highlight {
        background-color: $table-highlight-cell;
      }
    }
    &:nth-of-type(odd):not(:where(.grid-table__row--even, .grid-table__row--odd)),
    &.grid-table__row--odd {
      .grid-table__cell {
        background-color: $white;
      }
      .grid-table__cell--highlight {
        background-color: $table-highlight-cell;
      }
    }
  }
  & > .grid-table__row--total .grid-table__cell {
    background-color: #e7f6e8 !important;
  }
}
.grid-table__scrollable-wrapper[aria-orientation="horizontal"] {
  height: 10px;
  z-index: 3;
  position: sticky;
  bottom: 0;
  overflow: hidden;
  background-color: $white;
  border-top: 1px solid $table-border-color;
  margin-top: -1px;
  scroll-behavior: smooth;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .grid-table__scroller {
    cursor: pointer;
    position: absolute;
    height: 10px;
    border-radius: 0px;
    background-color: color("gray-light");
    opacity: 1;
    bottom: 0px;
    scroll-behavior: smooth;
    transform: translateX(0px);
    transition: top 0.08s;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:hover {
      background-color: color("gray-hover");
    }
    &:active, &--active {
      background-color: color("gray-dark");
    }
  }
}
.grid-table__scrollable-wrapper--display[aria-orientation="horizontal"] {
  &:hover, &:active {
    background-color: #E2E2E2;
  }
}
.grid-table__scrollable-wrapper[aria-orientation="vertical"] {
  position: absolute;
  z-index: 0;
  bottom: 10px;
  right: 1px;
  width: 10px;
  background-color: #fff;
  overflow: hidden;
  &:hover, &:active {
    background-color: #E2E2E2;
  }
  .grid-table__scroller {
    cursor: pointer;
    width: 100%;
    position: absolute;
    background-color: color("gray-light");
    transform: translateY(0px);
    transition: top 0.08s;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:hover {
      background-color: color("gray-hover");
    }
    &:active, &--active {
      background-color: color("gray-dark");
    }
  }
}
.grid-table__column {
  border-top:1px solid $table-border-color;
  background-color: theme-color('base');
  position: relative;
  &:not([aria-colindex="1"]) {
    border-left: 1px solid $table-border-color;
  }
  .form-checkbox {
    display: flex;
    flex-wrap: wrap;
    .form-check-input {
      top: auto;
      &:not(:last-child) {
        margin: 0 auto 5px;
      }
      &:checked:after {
        left: 3px;
      }
    }
    .form-check-label {
      width: 100%;
      padding-left: 0;
      line-height: 1.4;
    }
  }
}
.grid-table__column--child {
  font-size: 11px;
}
.grid-table__column-link-copy {
  margin-right: 3px;
  color: #2347b5;
  cursor: pointer;
}
.grid-table__column-subName {
  color: #ce4242;
  font-size: 8px;
}
.grid-table__column-name {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  &[aria-sort="other"]:not(:hover) .grid-table__column-sort {
    display: none;
  };
  &[aria-sort="other"]:hover .grid-table__column-sort {
    background-color: $table-hover-header;
  };
  &[aria-sort="desc"]:hover  .grid-table__column-sort {
    background-color: $table-hover-header;
  };
  &[aria-sort="asc"]:hover .grid-table__column-sort {
    background-color: $table-hover-header;
  };
  &:not([aria-sort="none"]) span {
    display: inline-block;
    max-width: 100%;
    padding-right: 7px;
    position: relative;
    line-height: 20px;
  }
  &:hover:not([aria-sort="none"]) {
    cursor: pointer;
    background-color: $table-hover-header;
  }
  .dragonball-icon {
    margin-left: 3px;
  }
}
.grid-table__column-filter {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 0 -2px 3px;
  background: url("../../../assets/images/filtered-item-icon.svg") no-repeat;
}
.grid-table__column-sort {
  font-size: 11px;
  line-height: 20px;
  color: color("gray-dark");
  padding: 0 7px 0 5px;
  position: absolute;
  right: -15px;
  background-color: color("base");
}
.grid-table__column-resize {
  height: calc(100% + 1px);
  width: 11px;
  z-index: 1;
  position: absolute;
  right: -6px;
  mix-blend-mode: multiply;
  cursor: e-resize;
  bottom: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:after{
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    left: 5px;
  }
  &:hover:after {
    background-color: $table-border-hover;
  }
  &:active:before{
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
.grid-table__cell {
  overflow: hidden;
  position: relative;
  padding: 10px 15px;
  min-height: 38px;
  &:not(:first-child) {
    border-left: 1px solid $table-border-color;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: inherit;
    background-color: inherit;
  }
}
.grid-table__cell-content {
  line-height: 18px;
  word-break: break-all;
}
.grid-table__content-item--inline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: calc(100% + 1px); //allow for browser reporting a difference of 1px
}
.grid-table__cell--center .grid-table__cell-content {
  text-align: center;
}
.grid-table__cell--block-center {
  display: block;
  text-align: center;
}
.grid-table__cell--text-right .grid-table__cell-content {
  text-align: right;
}
.grid-table__cell--checkbox {
  padding-top: 8px;
  padding-bottom: 8px;
  .grid-table__cell-content {
    display: flex;
    align-items: flex-start;
    line-height: 1;
    .form-checkbox {
      margin-top: 3px;
    }
    .action-icon {
      margin-left: 10px;
    }
  }
}
.grid-table__cell--icon-check {
  .grid-table__cell-content {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #34b445;
    font-size: 14px;
  }
}
.grid-table__cell--inline-nowrap {
  .grid-table__cell-content {
    white-space: nowrap;
  }
}
.grid-table__cell--inline-truncate {
  .grid-table__cell-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.grid-table__cell--btn-link {
  .grid-table__cell-content {
    .btn-link {
      display: contents;
      padding: 0;
      border: 0;
      line-height: inherit;
    }
  }
}

.grid-table__subgrid {
  display: contents;
  &:not(:first-child) {
    .grid-table__cell {
      border-left: 1px solid $table-border-color;
    }
  }
  .grid-table__row {
    display: contents;
    .grid-table__cell {
      min-height: auto;
    }
    &:not(:first-child) {
      .grid-table__cell {
        padding-top: 0;
      }
    }
  }
}

.grid-table--scrolling {
  .grid-table__container {
    overflow: hidden;
    overflow-y: auto;
  }
  .grid-table__header-wrapper {
    top: 0;
  }
}
.grid-table--empty {
  position: relative;
  .grid-table__body-wrapper, .grid-table__body {
    min-height: 140px;
    grid-template-rows: 20px !important;
  }
  .grid-table__body > .grid-table__row {
    visibility: hidden;
  }
  .grid-table__empty {
    z-index: -1;
    position: absolute;
    left: 0;
    bottom: 0;
    text-align: center;
    width: 100%;
    min-height: 140px;
    line-height: 140px;
  }
  .grid-table__empty--has-scroller {
    bottom: 10px;
  }
  .grid-table__wrapper--main .grid-table__column[aria-colindex="1"] {
    border-left: 1px solid $table-border-color;
  }
}
.grid-table--center {
  .grid-table__cell {
    display: flex;
  }
  .grid-table__cell-content {
    flex: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
}
.grid-table--rendering {
  height: 100%;
  .grid-table__container {
    visibility: hidden;
  }
  .grid-table__body {
    grid-template-rows: 38px !important;
    .grid-table__row {
      display: contents !important;
    }
    .grid-table__cell {
      visibility: hidden;
      grid-row: 1 !important;
    }
  }
  .grid-table__loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.grid-table--resizing {
  .grid-table__wrapper--freeze .grid-table__header-wrapper {
    z-index: 2;
  }
  .grid-table__header, .grid-table__body {
    width: auto;
  }
}
.grid-table--hover {
  .grid-table__body {
    & > .grid-table__row {
      &.grid-table__row--hover {
        .grid-table__cell {
          background-color: $table-hover-row;
        }
      }
      &.grid-table__row--selected {
        .grid-table__cell {
          background-color: $table-selected-row;
        }
      }
    }
  }
}