@import 'src/placeholders';
@import "src/variables";

.collapsable-filter__view {
  max-width: 270px;
  background-color: #E2E2E2;
  border: none;
  margin: -3px 10px 0 0px;
  border-radius: 10rem;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  span {
    max-width: 230px;
    font-size: 11px;
    padding: 4px 0px 3px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  &:after {
     content: '';
    position: relative;
    top: 9px;
    margin-left: 12px;
    color: color("gray-dark");
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}
.collapsable-filter__view.active {
  &:after {
    content: '';
    border: none;
    border-bottom: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  @-moz-document url-prefix(){
    &:after {
      top: -9px;
    }
  }
}
.collapsable-filter {position: relative;}
.collapsable-filter__panel {
  position: absolute;
  left: 0px;
  top: 30px;
  flex-direction: column;
  box-shadow: 0 0 10px $box-shadow-color;
  width: 245px;
  font-size: 13px;
  background-color: white;
  border-radius: 5px;
  z-index: 3;
  margin-bottom: 7px;
  .form-group {
    padding-top: 10px;
    padding-left: 15px;
    margin-bottom: 0;

    .form-check {
      margin-bottom: 5px;
      margin-left: 0;
      font-size: 13px;
      label {
        padding-left: 5px;
      }
    }
  }
  .btn.btn-link {
    margin-right: 15px;
    font-size: 13px;
    padding: 0;
    border:none;
    &:disabled {
      color: #2347B5;
      opacity: 0.3;
    }
  }
  .below-panel {
    padding-top: 7px;
    .btn.btn-link {
      padding: 0 10px;
      margin-right: 0;
    }
    .btn.btn-secondary {
      margin-left: 10px;
      border: none;
    }
  }
  .below-panel .btn-secondary {
    margin-left: 0
  }
}
.collapsable-filter__list {
  display: flex;
  flex-direction: column,
}
.collapsable-filter__clear-all,
.collapsable-filter__check-all {padding: 0;}
.collapsable-filter__clear-all:disabled,
.collapsable-filter__check-all:disabled {color: #2347B5; opacity: 0.3;}
.collapsable-filter__clear-all.btn-link:hover,
.collapsable-filter__check-all.btn-link:hover {text-decoration: none;}
.collapsable-filter__view:disabled {
  cursor: default;
  &::after {
    opacity: 0.3;
  }
}
