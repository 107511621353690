@import "src/variables";

.file-upload__placeholder {
  cursor: pointer;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-weight: bold;
  color: color("gray-hover");
  border-radius: 3px;
  border: 1px dashed color("gray-hover");
}
.file-upload__content {
  display: flex;
  border-radius: 3px;
  padding: 10px 20px;
  background-color: color("base");
  .file-upload__name {
    display: inline-block;
    max-width: calc(100% - 50px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .file-upload__clear {
    margin-left: 10px;
    margin-top: 2px;
  }
}
.file-upload__clear {
  font-size: 13px;
  padding: 0;
  border: 0;
}
.file-upload__note {
  color: color("gray-dark");
  font-size: 11px;
  margin-top: 5px;
}
.file-upload__container--uploaded {
  line-height: 1.6;
}

.file-upload__container--error {
  .file-upload__placeholder {
    border-color: $input-border-color-error;
  }
  .file-upload__content {
    box-shadow: 0px 0px 0px 1px $input-border-color-error;
  }
}