@import 'src/variables';
@import 'src/mixins';

.data-export {
  .grid-table__cell-loading {
    display: inline-flex;
    text-align: center;
    align-items: center;
    .spinner {
      width: 30px;
      height: 14px;
      & > div {
        width: 3px;
        margin: 0 1px 0 0;
      }
    }
  }
  .grid-table__cell {
    &[aria-label="type"] {
      .btn {
        font-size: 11px;
        padding: 2px 6px;
        line-height: 1;
        border-radius: 3px;
        position: relative;
        top: -1px;
        margin-left: 3px;
        cursor: default;
      }
      .btn-regular-report {
        color: #A8926A;
        border-color: #A8926A;
      }
      .btn-data-export {
        color: #FFFFFF;
        border-color: #A8926B;
        background-color: #A8926B;
      }
    }
    &[aria-label="output"] {
      padding-top: 8px;
      padding-bottom: 8px;
      .btn {
        max-height: 24px;
      }
    }
    &[aria-label="modifyDate"], &[aria-label="completeDatetime"] {
      .grid-table__cell-content {
        word-break: initial;
      }
    }
  }
  .grid-table__cell-content {
    button {
      &.btn-text {
        padding: 3px 0;
      }
    }
  }
}

.modal--view-history {
  .modal-content {
    .modal-header {
      margin-bottom: 10px;
    }
    .modal-body {
      .table-modal {
        th,
        td {
          text-align: center;
        }
        .table-modal__tbody {
          .table {
            tbody {
              tr {
                td {
                  &:first-child {
                    text-align: start;
                  }
                }
              }
            }
            word-break: break-word;
          }
        }
      }
    }
    .modal-footer {
      margin-top: 15px;
    }
  }
}
