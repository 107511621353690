.sort-item {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 5px 10px;
  margin: 5px 0px 5px 5px;
  border: 1px solid #dbcdb4;
  border-radius: 3px;
  cursor: move;
  user-select: none;
  i {
    color: #dbcdb4;
    margin-right: 7px;
  }
  &:hover {
    background-color: #f9f6ef;
  }
  &.sort-item__dragging {
    color: white !important;
    background: white !important;
    position: relative;
    cursor: move;
    border: none;
    &::before {
      content: '';
      color: #dbcdb4;
      text-transform: uppercase;
      width: 100%;
      height: 100%;
      border: 1px dashed #dbcdb4;
      border-radius: 3px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
    }
  }
}
