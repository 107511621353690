@import 'src/variables';

.axis-selectbox {
  display: flex;
  flex-direction: column;
}
.axis-selectbox--disabled {
  opacity: 0.3;
  pointer-events: none;
  // Reset opacity for disabled children
  .disabled,
  :disabled {
    opacity: 1;
  }
}
.axis-selectbox__label {
  font-size: 13px;
  line-height: 20px;
}
.axis-selectbox__button.dropdown-toggle {
  border: 1px solid color('gray-light');
  border-radius: 3px;
  font-size: 13px;
  line-height: 18px;
  padding: 5px 10px;
  min-width: auto;
  width: 136px;
  box-shadow: none;
}
.axis-selectbox__button--view.dropdown-toggle {
  width: 144px;
}
.axis-selectbox__button.dropdown-toggle:focus {
  box-shadow: none;
}
.axis-selectbox__dropdown .dropdown-item {
  font-size: 13px;
  line-height: 20px;
  padding: 3px 10px;
  word-wrap: break-word;
  white-space: normal;
}
.axis-selectbox__dropdown .dropdown-item.active {
  background: color('base') 0% 0% no-repeat padding-box;
  color: #000000;
}
.axis-selectbox__dropdown .dropdown-menu.show {
  border-color: color('gray-light');
  min-width: 136px;
  padding: 0;
  &:hover {
    border-color: color('gray-hover');
  }
}
.axis-selectbox__dropdown--view .dropdown-menu.show {
  min-width: 144px;
}
