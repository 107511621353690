@import "src/variables";

.custom-view-modal {
  .modal-content {
    padding: 30px 10px 30px 30px;
  }
  &__body {
    position: relative;
    overflow: hidden;
    > .scrollbar-wrapper__content {
      margin-right: 20px;
    }
    .custom-view-modal__items {
      .display-items-form {
        .form-group:last-child {
          margin-bottom: 8px;
        }
      }
      .dropdown {
        .dropdown-toggle {
          margin-bottom: 2px;
        }
      }
      .view-menu-form__menu-wrapper {
        .dropdown {
          .dropdown-toggle {
            width: 370px;
            height: 30px;
            font-size: 13px;
            margin-bottom: 2px;
          }
          .dropdown-menu {
            width: 370px;
          }
        }
      }
      .view-menu-form__menu-text--hidden {
        display: none;
      }
      .date-range-picker {
        margin: 0px;
        width: 260px;
        &__input-container {
          text-align: end;
          background:color("base");
          .date-range-picker__icon {
            padding: 1px 7px 3px 19px;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            .fa-calendar-alt {
              left: 7px;
            }
          }
          .date-range-picker__input {width: 230px; height: 29px; border-right: none;}
        }
      }
      .date-range-picker__modal {
        z-index: 1061;
      }

      .note-wrapper {
          padding-top: 5px;
      }
      .note-wrapper .note {
          color: #666666;
          font-size: 11px;
          padding-left: 0;
      }
      .filter-container-contact-history {padding-bottom: 0;}
      .custom-view-modal__view-name {
        &::placeholder {color: color("gray-light");}
        &:hover, &:focus {
          border-color: $border-hover-color;
        }
      }
      .custom-view-modal__view-name--error {
        border: 1px solid #E27070;
        &:focus, &:hover {
          border-color: #E27070;
        }
      }
    }
  }
  .modal-footer {border: none;padding: 0 20px 0;
    .btn-secondary {
        &:focus {
          &:hover {background-color: $black;border-color: $black;}
          background-color: theme-color("secondary");
          border-color: theme-color("secondary");
        }
    }
}
}
.custom-view-modal__item-title {font-size: 16px; font-weight: bold; border-bottom: 2px solid black; margin-bottom: 20px; padding-bottom: 3px;}
.custom-view-modal__item-title-sub {font-size: 14px; font-weight: bold; margin: 20px 0 5px 0; padding-bottom: 3px;}
.custom-view-modal__items .filter-container {min-height: 40px;}
.custom-view-modal__items .filter-container.filter-container--custom-view {margin: 0; padding: 0; margin-bottom: 8px; padding-right: 20px;}
.custom-view-modal__items--margin-bottom {margin-bottom: 30px;}
.custom-view-modal__items {
  &:last-child {margin-bottom: 1px;}
}
.custom-view-modal__compare-period {
  border: 1px solid color("gray-light");
  background-color: color("base");
  border-radius: 3px; width: 260px; padding: 5px 10px; color: color("gray-hover"); cursor: default;
  padding-bottom: 3px;
}
.custom-view-modal__view-name {
    border: 1px solid color("gray-light");
    background: theme-color("info");
    border-radius: 3px;
    width: 250px;
    padding: 5px 10px;
}
.custom-view-modal__view-name:focus {
  outline: none;
  border: 1px solid color("gray-light");
}
.custom-view-modal__reset-link { font-size: 11px; line-height: 16px; color: color("link"); margin-left: 10px; opacity: 0.3; font-weight: normal;}
.custom-view-modal__reset-link--active {opacity: 1; cursor: pointer;}
.custom-view-modal__date-specify { margin-bottom: 20px; }
.custom-view-modal__note {font-size: 11px; color: color("gray-dark");}
.badge-required-middle {vertical-align: middle; margin-bottom: 2px;}

.display-items-form-container {
  border: 1px solid #CCCCCC;
  border-radius: 3px 3px 0px 0px;
}
.display-items-form-container-sub {
  padding: 20px;
}
.display-items-form-title {
  border-bottom: 1px solid #DDDDDD;
  background-color: #F4F4F4;
  padding: 10px 0 10px 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  .mb-0 {
    height: 25px;
  }
}
.display-items-form-title-inflow {
  height: 61px;
}
.display-items-form-title-not-inflow {
  height: 44px;
}