@import 'src/variables';

.modal-dialog--route-setting {
  .modal-title {
    font-size: 18px;
  }
  .modal-body {
    height: 533px;
  }
  .modal-scroll {
    height: inherit;
  }
  .ebis-form-group{
    &:last-child {
      margin-bottom: 0;
    }
    .dragonball-icon {
      margin-left: 2px;
    }
  }
  .route-setting:not(:last-child) {
    margin-bottom: 30px;
  }
  .route-setting__title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    span {
      color: #AAAAAA;
      font-size: 11px;
      font-weight: normal;
      margin-left: 10px;
    }
    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      background-color: #000000;
    }
  }
  .route-setting__inflow-mode {
    padding: 10px 20px;
    margin-top: 3px;
    background-color: #F5F5F5;
    border: 1px solid #F5F5F5;
    border-radius: 3px;
    .select-popup__value {
      background-color: #ffffff;
    }
  }
  .route-setting__inflow-mode--url {
    .ebis-form-group {
      .dropdown-toggle, .dropdown-menu {
        min-width: 130px;
      }
      .form-group {
        margin-bottom: 0;
        display: inline-block;
      }
      .form-input {
        display: inline-block !important;
        margin-left: 5px;
        width: calc(100% - 135px);
      }
    }
  }
  .route-setting__inflow-mode--error {
    border-color: #E27070;
  }
  .route-setting--channel {
    .form-label:not(:first-child) {
      display: inline-block;
      margin-bottom: 0;
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }
  .route-setting--inflow {
    .form-check--ebis-radio:not(:last-child) {
      margin-right: 30px;
    }
    .select-popup--single.select-popup--selected-value .select-popup__actions {
      flex: none;
      width: 702px;
      padding: 10px 20px;
      border-radius: 3px;
      background-color: #F5F5F5;
      .hook-tooltip--page-selected {
        div:first-child {
          color: #455DA8;
          font-size: 13px;
          font-weight: bold;
          margin-bottom: 5px;
        }
      }
    }
  }
  .route-setting--display {
    .dropdown-toggle, .dropdown-menu {
      min-width: 130px;
    }
    .form-input {
      width: 130px;
      margin-right: 7px;
      display: inline-block !important;
      .form-control[name=display_rate] {
        text-align: right;
      }
    }
    .form-note {
      display: inline-block;
      margin-top: 0;
      font-size: 13px;
      color: #000000;
    }
    .form-label:not(:first-child) {
      display: inline-block;
      margin-bottom: 0;
      &:not(:last-child) {
        margin-right: 50px;
      }
    }
    .bs-popover-top.is-error {
      width: max-content;
    }
  }
  .checkbox-item + span:not(.ebis--disabled) {
    cursor: pointer;
  }
}
