@import "src/variables";

.freeze-table {
  display: flex;
  padding: 0;
  width: auto;
  position: relative;
  &__freeze-content {
    min-width: 100px;
    max-width: 80%;
  }
  &__wrapper-header {
    position: sticky;
    z-index: 1;
    min-width: 100px;
    overflow: hidden;
    top: 160px;
    &.header-fixed {
      padding-bottom: 10px;
      & + .freeze-table__wrapper-body {
        margin-top: -10px;
      }
    }
    &:after {
      content: '';
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 10px;
      bottom: 0;
      left: 0;
      box-shadow: inset 0px 5px 4px -2px $box-shadow-color;
    }
    .freeze-table__cell-content {
      white-space: nowrap;
      overflow: hidden;
      line-height: 20px;
    }
  }
  &__wrapper-body {
    overflow-x: auto;
    overflow-y: hidden;
    // Chrome
    &::-webkit-scrollbar {
      height: 8px;
      background-color: $white;
    }
    &::-webkit-scrollbar-track {
      background-color: $white;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $white;
      &:hover {
        background-color: $white;
      }
      &:active {
        background-color: $white;
      }
    }
    // Firefox
    scrollbar-width: thin;
    scrollbar-color: $white $white;

    .freeze-table__cell-content {
      &.check_all {
        display: flex;
        align-items: center;
        padding: 8px 15px;
        .action-icon {
          margin: 1px 0 0 10px;
        }
      }
      button.btn-link:focus {
        text-decoration: none;
      }
      button.btn-link:hover {
        text-decoration: underline;
      }
    }
  }
  &__header {
    border-bottom: 0;
    white-space: nowrap;
    &.table {
      background-color: color("base");
      position: relative;
      margin-bottom: 0;
      th:not([rowspan="1"]) {
        position: relative;
        span {
          display: inline-block;
          align-self: baseline;
          max-width: 100%;
          padding-right: 7px;
          position: relative;
          .icon-sort {
            font-size: 11px;
            line-height: 20px;
            color: color("gray-dark");
            background-color: color("base");
            padding: 0 7px 0 5px;
            position: absolute;
            right: -15px;
            top: calc(50% - 1px);
            transform: translateY(-50%);
            &:before {
              margin-top: -4px;
            }
          }
        }
        // If the end is ")", set the padding to 0px so that there is no margin between it and the sort icon.
        // ex) "LTV売上（合計）"
        span[data-text$="）"] {
          padding-right: 0px;
        }
        .resize-handle {
          position: absolute;
          top: 0;
          right: -5px;
          height: 100%;
          margin-right: 0px;
          background: none;
          z-index: 3;
          cursor: e-resize;
          &:after {
            left: 4.5px;
          }
          &:hover{
            &:after {
              background-color: #E5BDBA;
            }
          }
        }
        &:last-child .resize-handle {
          display: none;
          right: 0;
          &:after {
            left: 9px;
          }
        }
        &.last-child .resize-handle {
          height: calc(200% + 2px);
          top: calc(-100% - 2px);
        }
      }
    }
  }
  &__total {
    background-color: #E7F6E8;
  }
  &__cell-content {
    min-width: 100px;
    font-size: 13px;
    line-height: 20px;
    padding: 9px 15px;
    white-space: pre-line;
    word-break: break-word;
    overflow: hidden;
    &.check_all {
      min-width: auto;
    }
    .form-checkbox {
      vertical-align: middle;
    }
    &.text-multi {
      padding: 0;
    }
    .text-item {
      padding: 0 15px;
      margin: 9px 0;
    }
  }

  &__loader, &__empty {
    position: absolute;
    width: 100%;
    height: calc(100% - 70px);
    bottom: 10px;
    background-color: theme-color("info");
    border-bottom: 1px solid $table-border-color;
  }
  &__loader {
    .spinner-container {
      position: sticky;
      height: 120px;
    }
  }

  /** override bootstrap table style */
  .table {
    color: $black;
    position: relative;
    margin-bottom: 0;
    border: none;
    border-spacing: 0;
    border-collapse: separate;
    &:not(.table-hover) {
      border-top: 1px solid #dee2e6;
    }
    th, td {
      padding: 0;
    }
    th:first-child, td:first-child {
      border-left: none;
    }
    thead th {
      border-bottom: solid 1px $table-border-color;
      font-weight: 400;
      &[rowspan="2"] .freeze-table__cell-content {
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        flex-flow: column;
        justify-content: center;
        min-height: 58px;
      }
      &[rowspan="1"] .freeze-table__cell-content {
        line-height: 29px;
        min-height: 29px;
        padding-top: 0;
        padding-bottom: 0;
        pointer-events: none;
      }
      &[rowspan="1"] .freeze-table__cell-content.rowspan1-pointer {
        line-height: 29px;
        min-height: 29px;
        padding-top: 0;
        padding-bottom: 0;
        pointer-events: auto;
      }
      &:not([rowspan="1"]) .freeze-table__cell-content:not(.event-none):hover {
        background-color: $table-hover-header;
        cursor: pointer;
        .icon-sort {
          background-color: $table-hover-header;
          &.d-none {
            display: inline !important;
          }
        }
      }
      &[rowspan="1"] .freeze-table__cell-content.rowspan1-pointer:hover {
        background-color: $table-hover-header;
        cursor: pointer;
      }
    }
    tbody tr {
      .freeze-table__cell-content {
        min-height: 38px;
        &.text-nowrap--has-tooltip {
          text-overflow: ellipsis;
          a, button, .text-item {
            display: initial;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          button {
            display: contents;
          }
          .text-item {
            display: block;
          }
        }
      }
      .copyBtn {
        margin-left: 10px;
      }
    }
  }

  .table-bordered {
    th, td {
      border: 0;
      border-left: solid 1px $table-border-color;
      vertical-align: top;
    }
    thead th, thead td {
      border-bottom-width: 1px;
    }
    thead tr {
      &:nth-child(2) th {
        .freeze-table__cell-content {
          font-size: 11px;
          line-height: 28px;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      &.freeze-table__header-total th {
        background-color: #E7F6E8;
        .freeze-table__cell-content:hover {
          background-color: inherit;
        }
      }
    }
    .hover {
      background-color: #FCFFD5;
    }
    .selected {
      background-color: #FFFFBF;
    }
  }

  .table-striped {
    border-bottom: 1px solid #dee2e6;
    tbody tr{
      &:nth-of-type(odd) {
        background-color:theme-color("info");
      }
      &:nth-of-type(even) {
        background-color: color("base");
      }
      &.hover td {
        background-color: #FCFFD5;
      }
      &.selected td{
        background-color: #FFFFBF;
      }
    }
  }

  .table-width th:not(:last-child),
  .table-width td:not(:last-child) {
    width: 100px;
  }
  &__media-sync-icon {
    line-height: 20px;
    top: calc(50% - 1px);
  }
  background: repeating-linear-gradient(#f4f4f4,#f4f4f4 38px,transparent 0,transparent 76px);
}

.data-cell[axis=priority_axis_ebis] {
  background-image: linear-gradient(-45deg, #313166 7px, transparent 0) !important;
}

.data-cell[axis=priority_axis_media_sync] {
  background-image: linear-gradient(-45deg, #313166 7px, transparent 0) !important;
}

.resize-handle {
  position: relative;
  top: -15px;
  opacity: 0;
  width: 10px;
  mix-blend-mode: multiply;
  opacity: 1;
  z-index: 2;
  cursor: e-resize;
  &:after {
    content: '';
    position: absolute;
    height: calc(100% + 1px);
    width: 1px;
    background-color: transparent;
  }
  &:hover:after {
    background-color: #E5BDBA;
  }
  &:active:before{
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.resize-handle--right {
  margin-right: -10px;
  box-shadow: inset 5px 0px 4px -2px $box-shadow-color;
}

.resize-handle--left {
  margin-right: -11px;
  box-shadow: -5px 0px 4px -2px $box-shadow-color;
  &:after {
    left: -1px;
  }
}

.resize-handle--shadow-none:not(.resize-handle--left) {
  box-shadow: none;
}

.scrollable {
  position: relative;
  &:not(.freeze-table__freeze-content) {
    .table {
      th:first-child, td:first-child {
        border-left: 1px solid $table-border-color;
      }
      th.merge-left-group, td.merge-left-group {
        border-left: none;
      }
    }
  }
  &__content-wrapper {
    width: 100%;
    height: calc(100% - 12px);
  }
  &__scroll-container{
    position: sticky;
    bottom: 0px;
    height: 11px;
    min-width: 100px;
    scroll-behavior: smooth;
    border-top: 1px solid $table-border-color;
    background-color: $white;
    margin-top: -4px;
    z-index: 3;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &[display="block"] {
      &:hover, &:active {
        background-color: #E2E2E2;
      }
    }
    .scroller {
      z-index: 3;
      cursor: pointer;
      position: absolute;
      height: 10px;
      border-radius: 0px;
      background-color: color("gray-light");
      opacity: 1;
      left: 0px;
      bottom: 0px;
      -webkit-transition: top 0.08s;
      -moz-transition: top 0.08s;
      -ms-transition: top 0.08s;
      -o-transition: top 0.08s;
      transition: top 0.08s;
      scroll-behavior: smooth;
      &:hover {
        background-color: color("gray-hover");
      }
      &:active, &--is-active {
        background-color: color("gray-dark");
        &:after {
          content: '';
          z-index: 5;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.resize-handle.d-none + .scrollable {
  .table th:first-child, .table td:first-child {
    border-left: none;
  }
}

.freeze-table--loading, .freeze-table--empty {
  position: relative;
  min-height: 190px;
  .freeze-table__total {
    display: none;
  }
}
.freeze-table--scroll-none {
  .scrollable__scroll-container {
    margin-top: 0;
    visibility: hidden;
  }
}

.freeze-table__row--odd {
  background-color: theme-color("info") !important;
}
.freeze-table__row--even {
  background-color: color("base") !important;
}

.freeze-table--settings {
  .table thead th[rowspan="2"] .freeze-table__cell-content {
    min-height: 38px;
  }
  .freeze-table__loader, .freeze-table__empty {
    height: calc(100% - 50px);
  }
}
.prediction-setting{
  line-height: 19px;
  text-align: center;
}
.prediction-default{
  font-style: normal;
}

.prediction-icon{
  width: 36px;
  height: 17px;
  background: #DBCDB4 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  font-size: 11px;
  span {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-11)/var(--unnamed-line-spacing-16) var(--unnamed-font-family-meiryo);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: right;
    font: normal normal normal 11px/16px Meiryo;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding-left: 7px;
  }
}

.filtered-item {
  .filtered-item-setting {
    .icon-svg {
      width: 12px;
      height: 13px;
      opacity: 1;
      &.filtered-group-item-icon {
        margin: 0 0 -2px 3px;
        background: transparent url("../../../assets/images/filtered-group-item-icon.svg") 0% 0% no-repeat padding-box;
      }
      &.filtered-item-icon {
        margin: 0 0 -3px 3px;
        background: transparent url("../../../assets/images/filtered-item-icon.svg") 0% 0% no-repeat padding-box;
      }
    }
  }
}

.image-cell {
  white-space: nowrap;
  display: flex;
  align-items: center;
  .value-style{
    width: 42px;
    flex: none;
  }
  span {
    height: 10px;
    background: #6D84C9 0% 0% no-repeat padding-box;
    opacity: 1;
    display: block;
  }
}
