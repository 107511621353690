@import 'src/variables';

.single-date-picker-input {
  &__modal {
    background: theme-color('info');
    border: 1px solid color('gray-hover');
    box-shadow: 0px 0px 10px $box-shadow-color;
    width: 210px;
    display: flex;
    flex-direction: row;
    position: absolute;
    border-radius: 3px;
    z-index: 3;
    margin-top: 2px;
    overflow: hidden;
    visibility: hidden;
    height: 0px;
    &-visible {
      visibility: visible;
      height: auto;
    }
  }
}
