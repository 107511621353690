@import 'src/variables';

.controls {
  display: inline-flex;
  align-items: stretch;
  margin: 10px 30px;
  .page-title {
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  .page-sub-title {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  .controls__selected {
    color: color(gray-hover);
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    &:not(:first-child) {
      margin-left: 20px;
      padding-left: 20px;
      border-left: 1px solid #dddddd;
    }
  }
  .controls__button {
    .btn + .btn {
      margin-left: 10px;
    }
    .btn.disabled {
      pointer-events: all;
    }
    .controls__button-sort {
      padding-left: 20px;
      border-left: 1px solid #dddddd;
    }
    .controls__button-margin-right {
      margin-right: 20px;
    }
  }
  & ~ .paginations {
    float: right;
    margin: 5px 0;
  }
  & ~ .btn--settings {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.controls-not-show__delete {
  height: 30px;
}
