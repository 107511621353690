@import "src/variables";

.upload-csv-modal {
  .modal-header {
    justify-content: unset;
    flex-wrap: wrap; // used for line break of note text. Can apply flex-basis for note text instead
    .link_redirect {
      display: inline-block;
        i {
          margin-left: 1px;
          margin-right: 4px;
        }
    }
  }
  .modal-header__description {
    flex-basis: 100%; // used for line break
    font-size: 11px;
    color: color(gray-dark);
  }
  .modal-title {
    margin-right: 10px;
  }

  .page-loader {
    height: 100%;
  }

  .order-history {
    margin-left: 4px;
    & + .txt-note {
    color: $black;
    font-size: 13px;
    margin-top: 8px;
    }
  }

  .error-message {
    margin-bottom: 10px;
    white-space: pre-line;
  }

  .table-confirm-detail {
    margin-bottom: 30px;
  }
}
