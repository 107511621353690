@import "src/variables";
@import "src/mixins";

.list-items-card {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
  background-color: #FFFFFF;
  height: auto;
  max-height: 100px;
  overflow-y: auto;
  @include scrollbars(15px, 5px, 5px, 5px);
}
.list-items-card__item {
  line-height: 18px;
  white-space: nowrap;
}
