@import 'src/variables';
@import 'src/mixins';

.cookie_program {
  height: 100%;
}

.cookie_program__list {
  .table-control {
    // width: -webkit-fill-available;
    width: 95%;
    float: left;
    margin: 10px 15px 10px 15px;
    .form-checkbox {
      margin: 0 0 0 10px;
      vertical-align: middle;
    }
    .count-item {
      margin: 0 0 0 auto;
      padding: 8px 0 4px 0;
    }
  }

  .visuallyHidden {
    display: none;
  }
  .freeze-table--settings
    .table
    thead
    th[rowspan='2']
    .freeze-table__cell-content {
    min-height: 58px;
  }
  .freeze-table--settings
    .table
    thead
    th
    .freeze-table__cell-content
    .dragonball-icon {
    pointer-events: auto;
  }
  .noscroller-list .scrollable__scroll-container,
  .noscroller-list .scrollable__scroll-container .scroller {
    display: none !important;
  }
  .freeze-table .table tbody tr .freeze-table__cell-content.text-nowrap {
    text-overflow: ellipsis;
  }
  .table-control__icon {
    margin-left: 15px;
    &:after {
      content: '';
      display: block;
      width: 1px;
      margin: 0 20px;
      background-color: $table-border-color;
    }
    > .btn-icon--no-text {
      margin-right: 10px;
    }
  }
  .table-control__remain {
    margin-left: 10px;
    align-self: center;
  }
  .pagination-wrap {
    padding: 15px 30px 15px 0;
  }
  .first_party_cookie_program_url_status,
  .first_party_cookie_program_cookie_value {
    display: inline-flex;
    text-align: center;
    align-items: center;
    .spinner {
      width: 30px;
      height: 14px;
      & > div {
        width: 3px;
        margin: 0 1px 0 0;
      }
    }
  }
}

.cookie_program__tabs {
  padding-left: 20px;
  padding-top: 13px;
  border-bottom: 1px solid $table-border-color;

  a {
    color: $black;
    &:hover {
      text-decoration: none;
      font-weight: bold;
    }
  }
}

.modal-cookie-program-setting {
  .modal-title {
    margin-right: 10px;
  }
  .modal-header {
    display: flex;
  }
  .form-page-setting--min-height {
    min-height: 533px;
  }
  form > .ebis-form-group:last-child {
    margin-bottom: 0;
  }
  .ebis-form-group--page_id,
  .ebis-form-group--page_title,
  .ebis-form-group--cv_title {
    .form-control {
      width: 250px;
    }
  }
  .ebis-form-group--condition {
    margin-bottom: 5px;
    .form-group {
      margin-bottom: 0;
    }
    .dropdown-toggle,
    .dropdown-menu {
      min-width: 144px;
    }
  }
  .ebis-form-group--matching {
    background-color: #f5f5f5;
    border-radius: 3px;
    padding: 10px 20px;
    margin-left: 20px;
    > .form-group {
      margin-bottom: 17px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .form-label .label {
      font-weight: normal;
    }
    .form-matching {
      padding: 0;
    }
    .checkbox-item {
      margin-top: -2px;
    }
  }
  .ebis-form-group--route_urls {
    .form-label:not(:first-child) {
      margin-bottom: 0;
      > span {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .form-matching {
      margin: 5px 0;
    }
  }
  .ebis-form-group--owned_media_flag {
    margin-bottom: 5px;
  }
  .ebis-form-group--content_category_id {
    background-color: #f4f4f4;
    padding: 10px 20px;
    border-radius: 3px;
  }
  .ebis-form-group--disabled {
    .form-matching[disabled] {
      .form-matching__note,
      .form-control,
      .btn:disabled {
        opacity: unset;
      }
    }
  }
  .form-check--ebis-radio.form-check-inline {
    margin-right: 30px;
  }
  .table-modal {
    th:first-child,
    td:first-child {
      width: 187px;
    }
    .table {
      table-layout: fixed;
    }
    .table tr td {
      padding: 5px 15px;
    }
    .table tr:not(:last-child) td {
      border-bottom: none;
    }
    .scrollbar-wrapper__content {
      max-height: none;
    }
    .form-control[disabled] {
      background-color: #ffffff;
      opacity: 0.3;
    }
  }
  .page-loader--default {
    min-height: 271px;
  }
  .page-loader--attribute {
    min-height: 413px;
  }
  .page-loader--pageview {
    min-height: 408px;
  }

  .modal-content {
    .modal-header {
      margin-bottom: 10px;
    }

    .modal-body {
      .scrollable__content-wrapper {
        border: 1px solid $border-color;
        border-radius: $border-radius;
      }
      .bordered {
        border: 1px solid $border-color;
        border-radius: $border-radius;
      }
      .confirm-list {
        padding: 8px 11px 10px 11px;
        max-height: 100px;
        overflow-y: auto;
        @include scrollbars(15px, 5px, 5px, 5px);
        p {
          line-height: 18px;
          margin: 0;
        }
      }
      .table-modal__tbody .table .d-flex {
        display: block !important;
      }

      .modal-error-message {
        text-align: left;
        color: #d86b6b;
        opacity: 1;
        padding-top: 5px;
        padding-bottom: 6px;
      }

      .deleteModalSpinner,
      .checkModalSpinner {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
      }

      .cookie_program_check_modal_status,
      .cookie_program_check_modal_cookie_value {
        display: inline-flex;
        text-align: center;
        align-items: center;
        font-size: 13px;
        .spinner {
          width: 30px;
          height: 14px;
          & > div {
            width: 3px;
            margin: 0 1px 0 0;
          }
        }
      }
      .cookie_program_check_modal_status_header,
      .cookie_program_check_modal_cookie_value_header {
        font-size: 11px;
      }
      .cookie_program_check_modal .dragonball-icon {
        pointer-events: auto;
      }
      .freeze-table .table tbody tr .freeze-table__cell-content.text-nowrap {
        text-overflow: unset;
        white-space: unset !important;
      }
      .freeze-table .table:not(.table-hover) {
        border-top: unset;
      }
      th .cookie_program_check_modal_domain,
      th .cookie_program_check_modal_url {
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        flex-flow: column;
        justify-content: center;
        min-height: 58px;
      }
      .cookie_program_check_modal .table {
        table-layout: unset;
      }

      .cpcc_domain {
        width: 187px;
      }
      .cpcc_url {
        width: 348px;
      }
      .cpcc_check {
        width: 200px;
      }
      .cpcc_status,
      .cpcc_cookie_value {
        width: 100px;
      }
    }
  }

  .modal-footer__has-ebis-checkbox {
    justify-content: space-between;
    align-items: baseline;
  }
  .modal-footer__has-ebis-checkbox .check-modal-footer-notes-text {
    /* Layout Properties */
    margin-top: 10px;
    margin-bottom: 25px;
    padding: 15px 20px;
    width: 740px;
    height: 72px;
    /* UI Properties */
    background: #f4f4f4 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
  }
}

.basic-setting {
  padding: 30px;
}

.measurement-setting {
  padding: 0 30px;
  .measurement-setting__form {
    padding: 30px 0;
    border-bottom: 1px solid #dddddd;
    max-width: 740px;
  }
  .measurement-setting__form:last-child {
    border-bottom: none;
  }
  .measurement-setting__pre-wrap {
    white-space: pre-wrap;
  }
}

.basic-setting-modal {
  .modal-content {
    .modal-header {
      margin-bottom: 17px;
    }
    .form-check-inline {
      margin-right: 30px;
    }
    .error-table-container {
      margin-bottom: 20px;
    }
    .modal-body {
      .modal-scroll {
        width: 730px;
      }
      .basic-setting__textarea {
        width: 100%;
        height: 100px;
        color: #000000;
        cursor: auto;
        padding: 0.3125rem 0.625rem;
        font-size: 0.8125rem;
        line-height: 1.5;
        border-radius: 0.1875rem;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #cccccc;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
      .basic-setting__textarea:disabled {
        background-color: #f4f4f4;
        color: #aaaaaa;
      }
      .page-loader {
        height: 150px;
      }
    }
  }
}

.measurement-setting-modal {
  .modal-content {
    .modal-header {
      margin-bottom: 17px;
    }
    .form-group__number {
      display: flex;
      align-items: center;
      .form-input {
        width: 100px;
        margin-right: 7px;
      }
    }
    .form-group__description {
      width: 453px;
      border: 1px dashed #dbcdb4;
      border-radius: 3px;
      padding: 10px;
      margin-bottom: 5px;
      color: #666666;
      font-size: 11px;
    }
    .form-group__cross-device {
      margin-top: 7px;
      width: 741px;
    }
    .target-media-area {
      margin-top: 20px;
    }
    .wrap-medium {
      display: grid;
      grid-template-columns: 0fr 1fr;
      margin-left: 1px;
      .max-content {
        width: 200px;
      }
    }
    .consent {
      background-color: #f4f4f4;
      padding: 5px;
      margin-top: 20px;
    }
    .consent-wrap-content {
      max-height: 335px;
      padding: 10px 5px 10px 15px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    }
    .gap-top-10 {
      margin-top: 10px;
    }
    .gap-bottom-20 {
      margin-bottom: 20px;
    }
    .consent-wrap__footer {
      text-align: end;
    }
    .sub-content {
      padding-left: 30px;
    }
    .consent-wrap__header {
      font-weight: 700;
      margin-bottom: 20px;
      text-align: center;
    }
    .target-media-content {
      display: flex;
    }
    .form-group__description-bold {
      font-weight: bold;
      margin-bottom: 5px;
    }
    .measurement-setting__content {
      .grid-table__wrapper--freeze {
        border-left: 1px solid #dddddd;
        .grid-table__scrollable-wrapper {
          margin-left: -1px;
        }
      }
      .grid-table__wrapper--main {
        border-right: 1px solid #dddddd;
        .grid-table__scrollable-wrapper {
          margin-right: -1px;
        }
      }
    }
    .form-check-inline {
      margin-right: 30px;
    }
    .page-loader {
      height: 150px;
    }
    .mr-auto {
      .form-label {
        margin-bottom: 0;
        .form-check-inline {
          margin-right: 5px;
        }
      }
    }
  }
}

.system-setting__pre-wrap {
  white-space: pre-wrap;
}

.other-setting {
  padding: 30px;
}

.other-setting__login_ip_note {
  font-size: 11px;
  color: #666666;
  margin-bottom: 5px;
}

.other-setting-modal {
  .modal-content {
    .modal-header {
      margin-bottom: 20px;
    }
    .modal-footer {
      margin-top: 0px;
    }
    .page-loader {
      height: 150px;
    }
    .grid-table__wrapper--freeze {
      border-left: 1px solid #dddddd;
      .grid-table__scrollable-wrapper {
        margin-left: -1px;
      }
    }
    .grid-table__wrapper--main {
      border-right: 1px solid #dddddd;
      .grid-table__scrollable-wrapper {
        margin-right: -1px;
      }
    }
    .other-setting__user-note {
      font-size: 11px;
      margin-top: 10px;
      p {
        margin-bottom: 5px;
        &:last-child {
          margin: 0;
        }
        img {
          margin-right: 5px;
        }
      }
    }
  }
  .form-group__login-ip-flag {
    margin-bottom: 5px;
    .form-check--ebis-radio:first-child {
      margin-right: 30px;
    }
  }
  .form-group__login-ip-note {
    color: #666666;
    font-size: 11px;
    margin-top: 5px;
  }
}

.dns-setting-table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  .paginations {
    padding-right: 30px;
  }
  .dns-setting__title {
    font-size: 16px;
    font-weight: bold;
    margin-left: 30px;
  }
  .dns-setting__left {
    display: flex;
    align-items: center;
    flex: 1;
    .controls {
      margin-left: 10px;
    }
  }
}

.dns-setting-table__complete {
  padding: 13px 30px 30px 30px;
  width: 100%;
  .dns-setting__title {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .dns-setting-table__complete-bold {
    font-weight: bold;
    padding-bottom: 10px;
  }
  .dns-setting-table__complete-note {
    padding: 15px 20px;
    margin-top: 10px;
    background: #f4f4f4;
    border-radius: 3px;
  }
}

.dns-setting {
  #free-head-area-dns {
    position: sticky;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .settings {
      width: 100%;
    }
    .btn--settings {
      padding: 6px 10px;
      background-color: $white;
      border-color: $link-color;
      color: $link-color;
      font-size: 13px;
      line-height: 16px;
      margin-right: 30px;
      &:hover {
        background-color: #f4f4f4;
      }
    }
  }
  .grid-table__container {
    .grid-table__body-container {
      .grid-table__body {
        grid-template-rows: auto !important;
      }
    }
  }
  .table-confirm-detail {
    .table {
      line-height: 1.6;
      margin-bottom: 0;
    }
  }
  .table {
    th {
      padding: 9px 15px;
      font-weight: normal;
      background-color: theme-color('base');
      border-bottom-width: 1px;
    }
  }
  .table-shadow {
    position: relative;
    z-index: 1;
    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 10px;
      bottom: -10px;
      left: 0;
      // box-shadow: inset 0px 5px 4px -2px $box-shadow-color;
    }
  }
  .table {
    border-top: none;
    word-break: break-all;
    thead {
      th {
        padding: 9px 15px;
        font-weight: normal;
        background-color: theme-color('base');
        border-bottom-width: 1px;
        border-bottom: none;
      }
      td {
        border: none;
        padding: 9px 15px;
        vertical-align: middle;
        &:not(:first-child) {
          border-left: 1px solid $table-border-color;
        }
      }
    }
    tbody {
      td {
        padding: 9px 15px;
        vertical-align: middle;
      }
      .dns-row-record {
        width: 140px;
      }
    }
    tr {
      &:nth-of-type(odd) {
        background-color: #ffffff !important;
      }
      &:nth-of-type(even) {
        background-color: theme-color('base') !important;
      }
    }
    tr:hover {
      &:nth-of-type(odd) {
        background-color: #ffffff !important;
      }
      &:nth-of-type(even) {
        background-color: theme-color('base') !important;
      }
    }
    .break-line {
      line-height: 1.2;
    }
  }
  .table-hover tr:hover {
    color: inherit;
    background-color: #fcffd5;
  }

  .blur-column {
    opacity: 0.3;
  }
  .dns-expand-table {
    margin-top: 10px;
    margin-bottom: 6px;
  }
  .dns-contain-email {
    display: flex;
    background-color: #f4f4f4;
    padding: 15px 20px;
    margin-top: 10px;
    div:first-child {
      font-weight: bold;
      width: 140px;
      padding-right: 10px;
      font-weight: bold;
      width: max-content;
      padding-right: 30px;
      word-break: keep-all;
      display: flex;
      align-items: center;
    }
  }
  .dns-setting__empty-hostname {
    text-align: center;
    padding: 40px;
  }
}

.dns-status {
  display: flex;
  align-items: center;
  width: max-content;
  & > img {
    margin-right: 5px;
  }
  .fa-check {
    font-size: 14px;
    color: #34b445;
    text-align: center;
    padding-right: 5px;
  }
}

.dns-status__extra {
  color: #d86b6b;
}

.dns-note {
  font-size: 11px;
  color: #666666;
  font-weight: normal;
}

.dns-setting-modal {
  .modal-content {
    .dns-description {
      margin-bottom: 30px;
    }
    .modal-header {
      margin-bottom: 10px;
    }
    .modal-body {
      .dns-modal-row {
        display: flex;
        align-items: center;
        & > span {
          margin-left: 5px;
        }
      }
      .dns-cname__contain-table {
        width: calc(100% - 20px);
      }
      .dns-modal-row__error {
        color: #d86b6b;
        padding-top: 5px;
        text-align: left;
        font-size: 11px;
      }
      .table-modal {
        th {
          vertical-align: middle;
        }
        td {
          text-align: center;
          padding: 5px 15px;
        }
        th:first-child {
          width: 72px;
        }
        td:first-child {
          width: 72px;
        }
        th:nth-child(3), td:nth-child(3) {
          width: 120px;
        }
      }
      .dns-error__main-message {
        color: #d86b6b;
        margin-bottom: 10px;
      }
      .modal-loading__contain-text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        height: 400px;
        font-size: 16px;
        .page-loader {
          height: auto;
          margin-bottom: 30px;
        }
      }
      .dns-cname__note {
        font-size: 11px;
        padding: 10px;
        border: 1px dashed #dbcdb4;
        border-radius: 3px;
        color: #666666;
        margin-bottom: 5px;
        width: fit-content;
        button {
          padding: 0;
          font-size: 11px;
        }
      }
      .dns-email__note-container {
        margin-top: 10px;
      }
      .dns-email__note-title {
        padding: 10px 0;
      }
      .dns-email__note-content {
        padding: 15px 20px;
        background-color: #f4f4f4;
      }
    }
    .modal-footer {
      justify-content: space-between;
      .modal-footer__control-button {
        display: flex;
        justify-content: flex-end;
        flex: 1;
      }
    }
  }
}

.dns-confirm-modal {
  .modal-content {
    .dns-description {
      margin-bottom: 20px;
    }
    .modal-header {
      margin-bottom: 30px;
    }
    .modal-body {
      .table-modal {
        td {
          padding-top: 15px;
          padding-bottom: 15px;
          text-align: left;
        }
        th:first-child, td:first-child {
          width: 235px !important;
        }
        th:nth-child(3), td:nth-child(3) {
          width: 365px !important;
        }
      }
    }
  }
}

// 2. mail-settings
.dns-description-email {
  margin-bottom: 20px;
}
.dns-error__email-main-message {
  color: #d86b6b;
  margin-bottom: 10px;
}

// 3. cofirm
.dns-setting-email-settings {
  margin-top: 10px;
}
.dns-setting-return-top {
  font-size: 14px;
  margin: 0 0 5px 10px;
}
