@import "src/variables";

.action-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  color: color(gray-dark);
  cursor: pointer;
  border-radius: 50%;

  &:hover:not([disabled]) {
    background-color: theme-color(base);
  }

  &[disabled] {
    cursor: unset;
    opacity: 0.3;
  }

  .fa-clone {
    transform: scale(1, 1.077);
  }

  .fa-pen {
    font-size: 12px;
  }

  .fa-chevron-left,
  .fa-chevron-right {
    font-size: 12px;
    transform: scale(0.933, 1);
  }

  .fa-times {
    font-size: 16px;
    line-height: 11px;
    transform: scale(1.1, 1);
    margin-top: 1px;
  }
}
