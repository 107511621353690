@import 'src/variables';

.data-list-tabs {
  .nav-tabs {
    border-bottom: 0;
    .nav-link {
      color: $black;
      font-size: 13px;
      border: 0;
      padding: 0 3px 3px 3px;
      margin: 0 10px 0 10px;
      &.active {
        font-weight: bold;
        border: 0;
        border-bottom: 2px solid theme-color('primary');
      }
    }
  }
}

.tabs {
  width: 100%;
  border-bottom: 1px solid $table-border-color;
  padding: 12px 0 1px 30px;
  .tab {
    text-decoration: none;
    color: #000000;
    margin-right: 26px;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      font-weight: bold;
      border-bottom: 2px solid theme-color('primary');
      padding: 0 3px;
    }
    &:hover {
      font-weight: bold;
    }
  }
}

.nav-tabs__container {
  .tab {
    &.active {
      border-bottom: 2px solid theme-color('primary');
    }
  }
}

#popover-sub-tab {
  box-shadow: 0px 0px 5px #00000029 !important;
  padding: 5px 0;
  border-radius: 5px;
  .popover-header {
    padding: 3px 15px;
    &:hover {
      background-color: #f4f4f4;
    }
  }
}
