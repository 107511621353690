@import "src/variables";

.form-matching {
  padding: 10px 20px;
  border-radius: 3px;
  background-color: #f5f5f5;
  line-height: 1.6;
  &[disabled] {
    pointer-events: none;
    .form-matching__note, .form-control {
      opacity: 0.3;
    }
    .btn-link {
      color: #2347B5;
    }
    .form-control {
      background-color: #ffffff;
    }
  }
}
.form-matching__item {
  display: flex;
  align-items: center;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}
.form-matching__option {
  .dropdown-toggle, .dropdown-menu {
    min-width: 127px;
  }
  .form-group {
    margin-bottom: 0;
  }
  & + .form-matching__input {
    margin-left: 5px;
  }
}
.form-matching__input {
  flex: auto;
  padding-right: 30px;
}
.form-matching__icon-delete {
  position: absolute;
  right: 0;
}
.form-matching__note {
  color: color("gray-dark");
  font-size: 11px;
  margin-top: 5px;
}
.form-matching__addition {
  padding: 0;
  border: none;
  margin-top: 5px;
}