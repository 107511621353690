/*------- Footer ---------*/
#footer {
  font-size: 11px;
  text-align: center;
  margin-top: 50px;
  padding-bottom: 20px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
	a {
    &::before {
      content: "|";
      display: inline-block;
      color: #969696;
      padding: 0 15px;
    }
    &:hover {
      text-decoration: underline !important;
    }
  }
}
