@import "src/variables";
@import "src/placeholders";

$width: 90px;
$color-period-current-selected: #DCE1F2;
$color-text-today: theme-color("primary");
$color-panel-selected: color("link");

.date-range-picker--simple .main-panel {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .DayPicker {
    margin: 14px 0;
    width: 420px !important;
    height: 188px;
    border-top-right-radius: 3px;
    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      width: 1px;
      background-color: #DDDDDD;
    }
    > div > div {
      width: 100% !important;
    }
    .DayPicker_weekHeader {
      top: 23px;
      padding: 0 20px 0 16px;
      small {
        color: black;
        font-weight: bold;
        font-size: 80%;
      }
      &:nth-child(1) {
        left: -6px !important;
      }
      &:nth-child(2) {
        left: 201px !important;
      }
    }

    .DayPicker_transitionContainer {
      width: 420px !important;
      height: 188px !important;
    }
    .CalendarMonth {
      padding: 0 14px !important;
    }
    .CalendarMonthGrid {
      background-color: transparent;
      &.CalendarMonthGrid__horizontal {
        left: 0px;
      }

      .CalendarMonth_caption {
        padding-top: 0px;
        padding-bottom: 18px;
      }

      .CalendarDay {
        font-size: 12px;
        margin: 10px;
        height: 20px !important;
        padding: 2px 0 0;
        border-top: 3px solid white !important;
        border-bottom: 3px solid white !important;
        border-left: none !important;
        border-right: none !important;

        &__default {
          color: $black;
          &:hover {
            background-color: $color-period-current-selected;
          }
        }

        &__blocked_out_of_range {
          color: #CACCCD;
          cursor: default;
        }

        &__selected_start,
        &__selected_end,
        &__selected_span {
          background-color: $color-period-current-selected;
          color: $black;

        }

        &__hovered_span {
          color: $black;
          background-color: $white;
        }

        &__today {
          color: $color-text-today;
        }

        &__selected_span {
          &:hover {
            background-color: $color-period-current-selected;
          }
        }

        &.CalendarDay__defaultCursor_2 {
          border: none;
          cursor: default;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .DayPickerNavigation {
      position: absolute;
      width: 100%;
      height: 24px;
      .DayPickerNavigation_button {
        position: absolute;
        width: 24px;
        height: 24px;
        &:first-child {
          left: 20px;
        }
        &:last-child {
          right: 20px;
        }
        &.DayPickerNavigation_button__disabled {
          border: none;
          display: none;
        }
        .icon-hover {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .drp-buttons {
    align-items: center;
    border-top: 1px solid #dddddd;
    padding: 16px 20px;
    .drp-inputed {
      font-size: 12px;
      span, input {
        margin-left: 5px;
      }
      input {
        border: none;
        border-radius: unset;
        padding: 0;
        width: 70px;
        height: 18px;
        text-align: center;
        border-bottom: 2px solid transparent;
        border-top: 2px solid transparent;
        &:active,
        &:focus,
        &:hover {
          box-shadow: none;
          outline: none;
        }
      }
      .input-date {
        input {
          background: #DCE1F2;
          &:focus {
            border-bottom-color: $link-hover-color;
          }
        }
      }
    }
    .btn-control {
      text-align: right;
      .btn-link {
        margin-right: 10px;
        &:hover {
          background-color: #E2E2E2;
          text-decoration: none;
          box-shadow: none;
        }
      }
    }
  }
}

.date-range-picker--simple {
  &:active,
  &:focus {
    box-shadow: none;
    outline: none;
  }

  &.disabled {
    @extend %disabled;
  }

  position: relative;
  margin-right: 30px;

  &__input-container {
    &:active,
    &:focus {
      box-shadow: none;
      outline: none;
    }
    position: relative;
    border: 1px solid color("gray-light");
    border-radius: 3px;
    cursor: pointer;
    &::after {
      position: absolute;
      top: 12px;
      right: 10px;
      content: '';
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid color("gray-dark");
    }
    input {
      &:active,
      &:focus {
        box-shadow: none;
        outline: none;
        border-left: 1px solid color("gray-light");
      }
      &:hover {
        border-left: 1px solid color("gray-hover");
      }
    }
    &.show {
      border: 1px solid color("gray-hover");
      &::after {
        transform: rotate( 180deg );
      }
      input {
        border-left: 1px solid color("gray-hover");
      }
    }
    &:hover {
      border: 1px solid color("gray-hover");
      input {
        border-left: 1px solid color("gray-hover");
      }
    }
  }
  &__icon {
    padding: 4px 5px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: theme-color(base);
    .fa-calendar-alt {
      color: color("gray-dark");
      font-size: 16px;
      transform: scale(1, 0.739);
      position: relative;
      top: 2px;
    }
  }
  &__input {
    cursor: pointer;
    font-size: 13px;
    width: 223px;
    height: 28px;
    border-radius: 0 3px 3px 0;
    padding: 0 0 0 10px;
    border: none;
    border-left: 1px solid color("gray-light");
  }
  &__modal {
    background: $white;
    width: 421px;
    height: 280px;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: calc(100% + 2px);
    right: 0px;
    z-index: 1;
    box-shadow: 0px 0px 10px $box-shadow-color;
    border: 1px solid color("gray-hover");
    border-radius: 3px;
    .side-panel {
      background-color: theme-color('base');
      display: flex;
      flex-direction: column;
      padding: 5px 0;
      width: 104px;
      border-radius: 3px 0 0 3px;

      .side-panel-item {
        color: $black;
        background-color: theme-color('base');
        font-size: 12px;
        border: 1px color("gray-hover");
        padding: 4px 10px 4px 10px;

        &.selected {
          color: $color-panel-selected;
          font-weight: bold;
          background-color: white;
        }

        &.disabled {
          opacity: 0.3;
          &:hover {
            cursor: auto;
          }
          pointer-events: none;
        }

        &:hover {
          cursor: pointer;
          color: $color-panel-selected;
          font-weight: bold;
          background-color: white;
        }
      }

      .dropdown {
        .dropdown-toggle {
          background-color: color("base");
          color: $black;
          border: none;
          font-size: 12px;
          padding: 4px 10px 0px;
          cursor: default;
          min-width: 104px;
          border-radius: 0;
          &:hover,
          &:active,
          &:focus {
            cursor: pointer;
            color: $color-panel-selected;
            font-weight: bold;
            background-color: white;
            box-shadow: none !important;
          }
          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 72px;
          }
          &::after {
            top: -11px;
            right: 0;
            margin-left: 2px;
          }
          &:hover {
            color: $color-panel-selected;
            font-weight: bold;
            background-color: white;
          }
          &.selected {
            color: $color-panel-selected;
            font-weight: bold;
            background-color: white;
            &:hover {
              font-weight: bold !important;
              color: $color-panel-selected !important;
            }
          }
        }

        .dropdown-menu {
          background: $white;
          font-weight: normal;
          min-width: 172px;
          left: 10px;
          top: 22px;
          line-height: 1.5;
          overflow: hidden;
          .side-panel-item {
            padding: 3px 10px;
            background: transparent;
            &:hover {
              font-weight: normal;
              color: $black;
              background-color: color("base");
            }
          }
        }
        .btn-primary:disabled {
          cursor: default;
          color: $black;
          font-weight: normal;
          font-size: 12px;
          opacity: 0.3;
          background-color: transparent;
        }
      }

      .show.dropdown {
        > .btn.btn-primary.dropdown-toggle {
          background: white;
        }
      }

    }
  }
}
.date-range-picker--simple__error, 
.date-range-picker--simple__error .date-range-picker--simple__input {
  border-color: $input-border-color-error;
}

.date-range-picker--drop-right {left: 0; right: unset;}
