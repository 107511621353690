@import "src/variables";

.selectLabel {
  display: flex;
  width: 320px;
  height: 30px;
}

.selectLabel-text {
  display: inline-block;
  width: 320px;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectMenu {
  width: 320px;
}
