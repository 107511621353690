@import 'src/variables';
@import 'src/mixins';

.sort-list__modal {
  .modal-header {
    flex-direction: column;
    & > span {
      font-size: 11px;
      color: #666666;
    }
    .controls_button-sort-bulk {
      margin-top: -5px;
      margin-left: 10px;
      font-size: 11px;
      padding:4px 7px;
    }
  }
  .modal-content {
    .modal-body {
      .sort-list__container {
        display: flex;
        flex-direction: column;
      }
      .sort-list__box {
        padding: 15px 10px;
        background-color: #f4f4f4;
        border-radius: 3px;
        .sort-list__box-table {
          max-height: 486px;
          overflow-y: auto;
          @include scrollbars(10px);
          &::-webkit-scrollbar-track {
            background-color: #f4f4f4;
          }
          list-style-type: none;
          padding-inline-start: 0;
          margin-bottom: 0;
          padding-right: 10px;
          li {
            display: flex;
            align-items: center;
            background-color: #ffffff;
            padding: 5px 10px;
            margin: 5px 0px 5px 5px;
            border: 1px solid #dbcdb4;
            border-radius: 3px;
            cursor: move;
            user-select: none;
            li:first-child {
              margin-top: 0;
            }
            li:last-child {
              margin-bottom: 0;
            }
            &:hover {
              background-color: #f9f6ef;
            }
            &.sort-item__dragging {
              color: white !important;
              background: white !important;
              position: relative;
              cursor: move;
              border: none;
              &::before {
                content: '';
                color: #dbcdb4;
                text-transform: uppercase;
                width: 100%;
                height: 100%;
                border: 1px dashed #dbcdb4;
                border-radius: 3px;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                left: 0;
              }
            }
            i {
              color: #dbcdb4;
              margin-right: 7px;
            }
            img {
              margin-right: 7px;
            }
            span {
              line-height: 18px;
            }
          }

        }
      }
      .pagination-wrap--settings {
        padding: 0 0 10px 0;
      }
      .sort-list__modal-loading {
        height: 230px;
      }
    }
  }
}
