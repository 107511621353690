@import "src/variables";

$dropdown-margin-menu: 5px;

.dropdown {
  line-height: 1.6;
}
.dropdown-toggle {
  min-width: 160px;
  cursor: pointer;
  border: 1px solid $border-color;
  border-radius: $border-radius;

  &::after {
    float: right;
    position: relative;
    top: 7px;
    margin-left: 10px;
    color: color("gray-dark");
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  &[aria-expanded="true"]::after {
    border: none;
    border-bottom: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  &:active,
  &:focus,
  &:hover {
    box-shadow: none;
  }
  &:active, &:hover {
    border-color: $border-hover-color;
  }
}
.dropdown-toggle--is-error {
  border-color: #E27070 !important;
}
button.dropdown-toggle {
  text-align: left;
  outline: none;
  background-color: white;

  &.btn-link {
    border: none;

    &:after {
      content: none;
    }

    min-width: unset;
  }
}
.dropdown-item {
  padding: 3px 10px;
  &:first-child {
    border-radius: 3px 3px 0px 0px;
  }
  &:last-child {
    border-radius: 0px 0px 3px 3px;
  }
  &.disabled,
  &:disabled {
    opacity: 0.3;
  }
}
.link-text {
  text-align: right;
}
.dropdown-menu {
  margin-bottom: 2px;
  padding: 0;
  &:active,
  &:focus,
  &:hover {
    border-color: $border-hover-color;
  }
}
.dropdown-menu-padding {
  padding: 5px 0;
  .dropdown-item {
    padding: 3px 15px;
    .click-id-csv-wrap {
      display: flex;
      align-items: center;
    }
    .click-id-csv {
      margin-right: 4px;
    }
    &:first-child {
      border-radius: 0;
    }
    &:last-child {
      border-radius: 0;
    }
  }
}
.dropdown-menu-shadow {
  border: none;
  box-shadow: 0px 0px 5px $box-shadow-color;
  border-radius: 5px;
  &:hover {
    box-shadow: 0px 0px 5px #00000042;
  }
  .custom-view-sub {
    margin-top: 5px;
    border-radius: 0px 0px 5px 5px;
    .dropdown-header {
      padding: 10px 15px 0 15px;
    }
    .dropdown-item {
      padding: 8px 15px 3px 15px;
    }
  }
}
.dropdown-menu-scrollbar {
  // Chrome
  &::-webkit-scrollbar-track {
    background-color: $white;
    border-radius: 0px 3px 3px 0;
  }
  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 0px 3px 3px 0;
    background-color: $white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: color("gray-light");
    border-style: solid;
    border-color: $white;
    border-width: 0 5px 0 0px;
    &:hover {
      background-color: color("gray-hover");
    }
    &:active {
      background-color: color("gray-dark");
    }
  }
  // Explorer
  scrollbar-face-color: color("gray-light");
  scrollbar-track-color: $white;

  // Firefox
  scrollbar-width: thin;
}
.dropdown-menu-icon {
  margin-top: 5px;
  min-width: auto;
}
.dropdown-submenu {
  background-color: theme-color("base");

  &.bottom {
    margin-bottom: -$dropdown-padding-y;
    padding-bottom: $dropdown-padding-y;
  }

  &.top {
    margin-top: -$dropdown-padding-y;
    padding-top: $dropdown-padding-y;
  }

  .dropdown-header {
    font-size: 11px;
    font-weight: bold;
    color: $black;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  }

  .dropdown-item {
    &.disabled {
      color: color("gray-light");
    }

    &:hover {
      background-color: #E2E2E2;
    }
  }
}

.dropdown--hover {
  .dropdown-menu {
    transform: none !important;    
    &[x-placement="bottom-start"] {
      margin-top: $dropdown-margin-menu !important;
      inset: 100% auto auto 0px !important;
      &::before{
        content: '';
        height: $dropdown-margin-menu;
        width: 100%;
        position: relative;
        top: -$dropdown-margin-menu;
        display: block;
        margin-top: -$dropdown-margin-menu;
      }
    }
    &[x-placement="top-start"] {
      margin-bottom: 5px !important;
      inset: auto auto 100% 0px !important;
      &::after{
        content: '';
        height: $dropdown-margin-menu;
        width: 100%;
        position: relative;
        bottom: -$dropdown-margin-menu;
        display: block;
        margin-bottom: -$dropdown-margin-menu;
      }
    }
  }
}

.csv-setting {
  padding: 0;
  color: $link-color;
  &:hover {
    color: $link-color;
  }
}