@import "src/variables";

.searchable-select {
  display: flex;
  align-items: center;
  .dropdown:not(.show) {
    .btn-link:focus {
      text-decoration: none;
    }
  }
  .bs-popover-top.is-error {
    position: relative !important;
    transform: none !important;
  }
}
.dropdown-select {
  flex-direction: column-reverse;
  align-items: flex-start !important;
  position: relative;
  &-search__label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.searchable-select__label {
  line-height: 1.6;
  max-width: calc(100% - 90px);
  word-break: break-all;
  margin-right: 10px;
}
.searchable-select__toggle {
  padding: 0;
  height: 20px;
}
.searchable-select__menu {
  min-width: auto;
  z-index: 1061;
  margin-top: 5px !important;
  transform: none !important;
  inset: auto !important;
  &.dropdown-menu-right {
    inset: auto 0 auto auto !important;
  }
  &:after {
    content: '';
    position: absolute;
    height: 5px;
    width: 100%;
  }
}
.searchable-select__header {
  font-size: 18px;
  font-weight: bold;
  padding: 7px 15px;
  border-bottom: 1px solid $border-color;
  background-color: theme-color('base');
}
.searchable-select__search {
  margin: 10px 15px;
  .form-group {
    margin-bottom: 0;
    &.has-icon i {
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.searchable-select__content {
  min-height: 160px;
  margin-bottom: 5px;
  .dropdown-item {
    padding: 3px 15px;
    white-space: break-spaces;
    word-break: break-all;
  }
  .page-loader {
    min-height: inherit;
  }
}
.searchable-select__empty {
  text-align: center;
  margin: 50px 15px;
}
.searchable-select__message {
  font-size: 11px;
  padding: 5px 20px;
  margin: 3px 15px 0 15px;
  text-align: center;
  background-color: theme-color('base');
}
.searchable-select__footer {
  padding: 10px 15px;
  border-top: 1px solid $border-color;
}
.searchable-select__clear {
  padding: 0;
  margin-left: 10px;
}
