@import 'src/variables';
.nav-link {
  .icon-svg {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .icon-svg {
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 3px;
    &.icon-svg__w36 {
      width: 36px;
      height: 36px;
    }
    &.icon-registration-status {
      background-image: url('../../../../assets/images/registration-status-completed.svg');
    }
    &.icon-registration-status-completed {
      background-image: url('../../../../assets/images/registration-status-completed.svg');
      background-position: center center !important;
    }
  }
  &:hover,
  &.active {
    .icon-svg {
      background-color: theme-color('primary');
      &.icon-registration-status-completed {
        background-image: url('../../../../assets/images/registration-status-completed-hover.svg');
        background-position: center center;
      }
    }
  }
}
.registration-status {
  width: 320px;
  .modal-loader-registration-status {
    min-height: 291px;
  }
  .registration-status__header {
    font-size: 14px;
    color: $black;
    border-bottom: 1px solid #dddddd;
    padding: 10px 15px 10px 15px;
    background-color: #f4f4f4;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .registration-status__title {
    font-weight: bold;
    margin-bottom: 0px;
  }
  .registration-status__note {
    display: block;
    font-size: 11px;
    margin: 0px;
    color: #666666;
  }

  .registration-status__content {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .registration-status__detail {
    display: flex;
    padding: 10px 0 10px 15px;
    &:hover {
      background-color: #f4f4f4;
    }

    .registration-status__detail__left {
      width: 202px;

      .detail__file-name {
        font-size: 13px;
        color: $black;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .detail__upload-start-time {
        font-size: 11px;
        color: #666666;
        line-height: 16px;
      }
      .detail__ad-type {
        font-size: 11px;
        color: #666666;
        line-height: 16px;
      }
    }
    .registration-status__detail__right {
      width: 63px;
      height: 51px;
      right: 0;
      margin: 0 20px;
      .status-new {
        float: right;
      }
      .status-registering {
        width: 100%;
        height: 100%;
        display: inline-flex;
        text-align: center;
        align-items: center;
        .spinner {
          width: 38%;
          height: 14px;
          & > div {
            width: 3px;
            margin: 0 1px 0 0;
          }
        }
      }
      .status-completed,
      .status-cancelled {
        float: right;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .status-error {
        float: right;
        display: flex;
        img {
          padding-bottom: 4px;
        }
      }
      .btn-stop {
        width: 36px;
        height: 22px;
        font-size: 11px;
        padding: 0px;
        border: 0px;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .registration-status__detail__tooltip {
      width: 240px;
      font-size: 11px;
      padding: 5px 10px;
      word-break: break-all;
      border: 1px solid #aaaaaa;
      background-color: #ffffff;
      box-shadow: 0px 0px 10px $box-shadow-color;
      .tooltip-content {
        display: flex;
        .tooltip-content__upload-start-time {
          display: block;
          width: 45%;
        }
        .tooltip-content__upload-start-time-value {
          display: block;
          width: 55%;
        }
        .tooltip-content__ad_type-value {
          display: flex;
          width: 90%;
          justify-content: right;
        }
        .tooltip-content__registered_records-value {
          display: flex;
          width: 79%;
          justify-content: right;
        }
      }
    }
  }
}
.dragonball-tooltip {
  &.variant-warning {
    .tooltip-inner {
      max-width: 230px;
    }
  }
}
.tooltip-registration-status {
  .tooltip-inner {
    max-width: 220px;
    width: 220px;
  }
}
