@import "src/variables";

/*----------Popup Modal -----------*/
/*---------Custom Model Display View ------------*/

.bs-popover-bottom:not(.have-arrow){
	-webkit-box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.61);
	-moz-box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.61);
	box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.61);
	border: unset !important;
	>.arrow {
		display: none !important;
	}
}
.popover {
	.popover-header {
		padding: 15px 10px;
		background-color: theme-color("info");
		border-bottom: none;
		cursor: pointer;
	}
	.popover-body {
		background: #f5f5f5;
		div {
			font-weight: bold;
		}
		span {
			color: color("gray-hover");
		}
		.popover-download {
			font-weight: unset !important;
			div {
				font-weight: unset !important;
				padding: 5px 0;
				cursor: pointer;
				&:hover {
					background: #efefef;
				}
			}
		}
	}
}