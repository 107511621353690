@import "variables";
// Custom scrollbars
@mixin scrollbars($width: 10px, $height: auto, $alignLeft: 0px, $alignRight : 0px, $background: $white, $borderRadius: $border-radius) {
  // Chrome
  &::-webkit-scrollbar {
    cursor: pointer;
    width: $width;
    height: $height;
    border-radius: $borderRadius;
    background-color: $white;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: color("gray-light");
    &:hover {
      background-color: color("gray-hover");
    }
    &:active {
      background-color: color("gray-dark");
    }
  }
  &::-webkit-scrollbar-track {
    background: $background;
    border-radius: inherit;
  }
  &::-webkit-scrollbar-thumb:vertical {
    border-style: solid;
    border-radius: inherit;
    border-color: $background;
    border-width: 0 $alignRight 0 $alignLeft;
  }

  // Explorer
  & {
    scrollbar-face-color: color("gray-light");
    scrollbar-track-color: $white;
  }

  // Firefox
  & {
    scrollbar-width: thin;
  }
}

@mixin box-shadow($type: panel) {
  @if $type == dropdown {
    box-shadow: 0 0 5px $box-shadow-color;
  } @else {
    // default box shadow
    box-shadow: 0 0 10px $box-shadow-color;
  }
}
@mixin circle($size, $bgColor: $black, $color: $white) {
  width: $size;
  height: $size;
  line-height: $size;
  text-align: center;
  display: inline-block;
  color: $color;
  background-color: $bgColor;
  border-radius: 50%;
}