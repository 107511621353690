@import 'src/variables';

#cell-tooltip {
  display: none;
  z-index: 4;
  font-size: 11px;
  line-height: 1.6;
  color: $black;
  padding: 5px 10px;
  background-color: $white;
  outline: 1px solid color('gray-hover');
  box-shadow: 0px 0px 5px #00000029;
  max-width: 210px;
  word-break: break-all;
  * {
    padding: 0;
    margin: 0;
    font-size: 11px;
    text-align: left;
  }
}
