@import 'src/variables';

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-15 {
  margin-bottom: 15px;
}


.mb-30 {
  margin-bottom: 30px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-30 {
  margin-right: 30px;
}

.ml-15 {
  margin-left: 15px;
}

.pr-10 {
  padding-right: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.px-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
