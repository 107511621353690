@import "src/variables";

// Display Item
.modal.display-item {
  &.display-item__conversion {
    .modal-title {
      button {
        font-size: 11px;
        margin-top: -6px;
      }
    }
    .modal-body {
      h5 {
        font-size: 16px;
        border-bottom: 2px solid $black;
        line-height: 24px;
        margin-bottom: 20px;
      }
      .first-contact {
        margin: 15px 0 0 0;
      }
      .dash-border {
        border: 1px dashed color("gray-light");
        border-radius: $border-radius;
        padding: 10px 20px 0 20px;
        margin: 0 0 30px 0;
        span.header {
          margin-bottom: 5px;
        }
        .col {
          label {
            margin-right: 30px;
          }
        }
      }
      div.gray-panel-bk {
        background-color: color("base");
        border-radius: $border-radius;
        padding: 10px 20px 2px 20px;
        margin-left: 80px;
      }
    }
    .modal-footer {
      margin-top: 8px;
    }
  }
  .modal-body {
    h6 {
      font-size: 14px;
      line-height: 21px;
      margin-top: 10px;
      .btn-link {
        border: none;
      }
    }
    button.btn-link {
      font-size: 11px;
      margin-top: -4px;
      border: none;
      padding: 0 0 0 10px;
      &:disabled {
        color: $link-color;
        opacity: 0.3;
      }
    }
    .container {
      margin-top: 5px;
      label {
        margin-bottom: 0.6rem;
      }
      .row {
        .cv-att-box {
          max-width: 170px;
        }
        .form-label {
          margin-bottom: 10px;
          display: inline-flex;
        }
        .col {
          .form-label {
            margin-bottom: 0;
          }
        }
      }
      .dropdown {
        .dropdown-menu {
          margin-top: 2px !important;
        }
      }
      .form-text {
        margin-top: 5px;
      }
    }
    .container {
      //&:first-of-type {
      &:nth-of-type(1) {
        .display-item-col {
          &:last-child {
            .form-label {
              margin-bottom: 20px;
              .checkbox-item {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
    .gray-panel-bk {
      .row {
        .form-label {
          margin-bottom: 5px;
        }
      }
    }
    .dash-border {
      .row {
        .col {
          .form-label {
            margin-bottom: 5px;
          }
        }
      }
    }
    .display-item-col {
      max-width: 170px;
      padding-right: 20px;
    }
  }
  .modal-footer {
    margin-top: 8px;
  }
}


// end Display Item