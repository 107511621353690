.drag-drop-item-default {
	display: flex;
	align-items: center;
	padding: 5px 10px;
	img {
		margin-top: -1px;
	}
	span {
		font-size: 12px;
		overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
		margin-left: 7px;
	}
}