@import "src/variables";

.ebis-alert {
  z-index: 9999999999;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  margin: 0;
  padding: 5px 15px;
  border: none;
  border-radius: 3px 3px 0 0;
  background-color:#455DA8;
  box-shadow: 0px 0px 10px $box-shadow-color;
  .ebis-alert-link {
    text-decoration: underline;
  }
}
