/*---------- Main Body ----------*/
@import "src/variables";

.main-content-wrapper {
  width:  calc(100% - 240px);
  min-height: calc(100vh - 60px);
  transition: all .6s;
  position: relative;
  display: flex;
  flex-direction: column;
  #main-content{
    flex: 1;
    position: relative;
    .spinner-initial {
      z-index: 4;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: $white;
    }
  }
  #common-bar {
    position: sticky;
    top: 60px;
    z-index: 5;
    border-left: 1px solid #ddd;
  }
  #pagination-table, #freeze-head-area {
    position: sticky;
    top: 120px;
    z-index: 3;
  }
  #freeze-head-area {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .settings {
      width: 100%;
    }
    .btn--settings {
      padding: 6px 10px;
      background-color: $white;
      border-color: $link-color;
      color: $link-color;
      font-size: 13px;
      line-height: 16px;
      margin-right: 30px;
      &:hover {
        background-color: #F4F4F4;
      }
    }
  }
  &:after{
    content: '';
    z-index: 4;
    position: absolute;
    left: -10px;
    top: -1px;
    width: 10px;
    height: 100%;
    transition: all .6s;
    -webkit-box-shadow: 2px 2px 5px 0px $box-shadow-color;
    -moz-box-shadow: 2px 2px 5px 0px $box-shadow-color;
    box-shadow: 2px 2px 5px 0px $box-shadow-color;
  }
}
.hide-navbar {
  .main-content-wrapper {
    width:  100%;
    &:after{
      width: 0;
    }
  }
}
