@import "src/variables";

.table-confirm-detail {
  .table {
    line-height: 1.6;
    margin-bottom: 0;
  }
}
.table-confirm-detail__thead {
  .table {
    border-bottom: none;
    th {
      padding: 9px 15px;
      font-weight: normal;
      background-color: theme-color('base');
      border-bottom-width: 1px;
    }
  }
  .table-shadow {
    position: relative;
    z-index: 1;
    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 10px;
      bottom: -10px;
      left: 0;
      box-shadow: inset 0px 5px 4px -2px $box-shadow-color;
    }
  }
}
.table-confirm-detail__tbody {
  .table {
    border-top: none;
    word-break: break-all;
    td {
      border: none;
      padding: 9px 15px;
      vertical-align: middle;
      white-space: pre-line;
      &:not(:first-child) {
        border-left: 1px solid $table-border-color;
      }
    }
    .break-line {
      line-height: 1.2;
    }
  }
  .table-striped {
    tr {
      &:nth-of-type(odd) {
        background-color: transparent;
      }
      &:nth-of-type(even) {
        background-color: theme-color('base');
      }
    }
  }
  .table-hover tr:hover {
    color: inherit;
    background-color: #FCFFD5;
  }
}