.priority-axis {
    &__container{
        width: 100%;
        height: 32px;
        display: flex;
        position: sticky;
        top: 120px;
        flex-shrink: 0;
        z-index: 4;
        background: #F9F9F9 0% 0% no-repeat padding-box;
        opacity: 1;
    }
    &__message {
        padding-bottom: 8.5px;
        padding-top: 8.5px;
        text-align: left;
        font: normal normal normal 11px/16px Meiryo;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }
    &__message--ebis {
        padding-left: 30px;
    }
    &__message--sync {
        padding-left: 25px;
    }
    &--sync {
        border-left: #313166 solid 5px;
    }
}
