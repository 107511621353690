@import "src/variables";

.btn {
  &.btn-xs {
    padding: 4px 6px;
    font-size: 11px;
    line-height: 1.3;
    border-radius: 3px;
  }

  &.btn-secondary {
    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: $black;
        border-color: $black;
      }
      &.btn-no-focus:focus {
        background-color: theme-color('secondary');
        border-color: theme-color('secondary');
      }
    }
  }

  &.btn-link {
    &:not(:hover) {
      &.btn-no-focus:focus {
        text-decoration: unset;
      }
    }
  }
  &.btn-link--border {
    margin-left: 10px;
    border: 1px solid #2347B5;
    &:hover {
      background-color: #F4F4F4;
      text-decoration: none;
    }
  }

  &.btn-cancel {
    &.focus, &:focus, &:hover {
      background-color: #E2E2E2;
      text-decoration: none;
      box-shadow: none;
    }
  }

  &.btn-text {
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: default !important;
  }

  &:focus, &:active, &:focus:active {
    box-shadow: none !important;
  }

  &.btn--disabled {
    opacity: 0.3;
    cursor: default;
    &:focus, &:hover, &:active {
      text-decoration: none;
    }
  }

  &.btn-icon--no-text {
    line-height: 0;
    padding: 0;
    &.btn-sm {
      width: 30px;
      height: 30px;
    }
  }

  &.btn-icon--with-text {
    i {
      margin-right: 6px;
    }
  }
}
