@import "src/variables";

.dragonball-icon {
  font-size: 13px;
  color: #DBCDB4;
  &[variant="warning"] {
    color: #FFD76A;
  }
  &[variant="title"] {
    font-size: 18px;
    color: #CBBB9F;
    background: $white;
    border-radius: 50%;
  }
  &[variant="text"] {
    font-size: 13px;
    color: #CBBB9F;
    background: $white;
    border-radius: 50%;
  }
  &:not([variant="text"]){
    margin-left: 3px;
    margin-bottom: 3px;
  }
}
i.dragonball-icon {
  background: $white;
  border-radius: 50%;
}

.bs-tooltip-bottom {
  .tooltip-inner {
    font-size: 13px;
    padding: 4px 10px;
    box-shadow: 0 0 5px $box-shadow-color;
  }
}
.dragonball-tooltip {
  &.top {
    bottom: 3px !important;
  }
  &.bottom {
    top: 3px !important;
  }
  $background-color: #EAE1D3;
  &.tooltip.show {
    opacity: 1;
  }
  .tooltip-inner {
    color: color("gray-dark");
    font-size: 11px;
    max-width: 210px;
    padding: 5px 10px;
    text-align: left;
    box-shadow: 0 0 5px $box-shadow-color;
  }
  &.variant-base, &.variant-form-label {
    $normal-color: #EAE1D3;

    &.bs-tooltip-top .arrow:before{
      border-top-color: $normal-color;
    }
    &.bs-tooltip-bottom .arrow:before{
      border-bottom-color: $normal-color;
    }
    &.bs-tooltip-left .arrow:before{
      border-left-color: $normal-color;
    }
    &.bs-tooltip-right .arrow:before{
      border-right-color: $normal-color;
    }
    .tooltip-inner {
      background-color: $normal-color;
      box-shadow: 0 0 5px $box-shadow-color;
    }
  }
  &.variant-warning, &.variant-title, &.variant-text {
    .arrow:after {
      border-color: transparent;
      border-style: solid;
      content: "";
      position: absolute;
      z-index: 1;
    }
    &.bs-tooltip-top .arrow:after {
      border-top-color: #fff;
      border-width: 0.4rem 0.4rem 0;
      left: 50%;
      top: -2px;
      transform: translateX(-50%);
    }
    &.bs-tooltip-bottom .arrow:after {
      border-bottom-color: #fff;
      border-width: 0 0.4rem 0.4rem;
      bottom: -2px;
      left: 50%;
      transform: translateX(-50%);
    }
    &.bs-tooltip-left .arrow:after {
      border-left-color: #fff;
      border-width: 0.4rem 0.4rem;
      left: -2px;
      top: 50%;
      transform: translateY(-50%);
    }
    &.bs-tooltip-right .arrow:after {
      border-right-color: #fff;
      border-width: 0.4rem 0.4rem;
      right: -2px;
      top: 50%;
      transform: translateY(-50%);
    }
    .tooltip-inner {
      background-color: #fff;
      box-shadow: 0 0 5px $box-shadow-color;
    }
  }
  &.variant-warning {
    $warning-color: #FFD76A;
    &.bs-tooltip-top .arrow:before{
      border-top-color: $warning-color;
    }
    &.bs-tooltip-bottom .arrow:before{
      border-bottom-color: $warning-color;
    }
    &.bs-tooltip-left .arrow:before{
      border-left-color: $warning-color;
    }
    &.bs-tooltip-right .arrow:before{
      border-right-color: $warning-color;
    }
    .tooltip-inner {
      border: 1px solid $warning-color;
      box-shadow: 0 0 5px $box-shadow-color;
      max-width: 270px;
    }
  }
  &.variant-error {
    $error-color: #E27070;
    &.bs-tooltip-top .arrow:before{
      border-top-color: $error-color;
    }
    &.bs-tooltip-bottom .arrow:before{
      border-bottom-color: $error-color;
    }
    &.bs-tooltip-left .arrow:before{
      border-left-color: $error-color;
    }
    &.bs-tooltip-right .arrow:before{
      border-right-color: $error-color;
    }
    .tooltip-inner {
      border: 1px solid $error-color;
      box-shadow: 0 0 5px $box-shadow-color;
      max-width: 270px;
    }
  }
  &.variant-title {
    $title-color: #CBBB9F;
    &.bs-tooltip-top .arrow:before{
      border-top-color: $title-color;
    }
    &.bs-tooltip-bottom .arrow:before{
      border-bottom-color: $title-color;
    }
    &.bs-tooltip-left .arrow:before{
      border-left-color: $title-color;
    }
    &.bs-tooltip-right .arrow:before{
      border-right-color: $title-color;
    }
    .tooltip-inner {
      border: 1px solid $title-color;
      box-shadow: 0 0 5px $box-shadow-color;
    }
  }
  &.variant-text {
    $title-color: #CBBB9F;
    &.bs-tooltip-top .arrow:before{
      border-top-color: $title-color;
    }
    &.bs-tooltip-bottom .arrow:before{
      border-bottom-color: $title-color;
    }
    &.bs-tooltip-left .arrow:before{
      border-left-color: $title-color;
    }
    &.bs-tooltip-right .arrow:before{
      border-right-color: $title-color;
    }
    .tooltip-inner {
      border: 1px solid $title-color;
      box-shadow: 0 0 5px $box-shadow-color;
    }
  }
  &.variant-form-label {
    white-space: pre-line;
  }
  &.variant-modal-white {
    bottom: auto !important;
    padding: 0 !important;
    $normal-color: #fff;
    margin-top: -2px;

    .arrow {
      display: none;
    }
    .dragonball-tooltip &.bs-tooltip-top .arrow:before {
      border-top-color: $normal-color;
    }
    &.bs-tooltip-bottom .arrow:before {
      border-bottom-color: $normal-color;
    }
    &.bs-tooltip-left .arrow:before {
      border-left-color: $normal-color;
    }
    &.bs-tooltip-right .arrow:before {
      border-right-color: $normal-color;
    }
    .tooltip-inner {
      background-color: $normal-color;
      box-shadow: 0 0 5px $box-shadow-color;
      color: #000000;
      font-size: 13px;
      min-width: max-content;
    }
  }
  &.variant-view {
    max-height: fit-content;
    $view-color: #999999;
    color: #fff;
    max-height: 200px;
    &.bs-tooltip-top .arrow:before{
      border-top-color: $view-color;
      color: #fff;
    }
    &.bs-tooltip-bottom .arrow:before{
      border-bottom-color: $view-color;
      color: #fff;
    }
    &.bs-tooltip-left .arrow:before{
      border-left-color: $view-color;
      color: #fff;
    }
    &.bs-tooltip-right .arrow:before{
      border-right-color: $view-color;
      color: #fff;
    }
    .tooltip-inner {
      background-color: $view-color;
      box-shadow: 0 0 5px $box-shadow-color;
      color: #fff;
    }
  }
}
