
@import "src/variables";

.filter-item {
  &.badge-pill.badge-secondary{
    height: 22px;
    max-width: 270px;
    padding: 0 5px;
    margin: 0 10px 5px 0;
    &:hover {
      background-color: black;
    }
  }
  &.badge-pill.badge-danger{
    height: 22px;
    max-width: 270px;
    padding: 0 5px;
    margin: 0 10px 5px 0;
    background-color:#CCCCCC
  }

  &.badge-pill.badge-info{
    height: 22px;
    max-width: 270px;
    padding: 0 5px;
    margin: 0 10px 5px 0;
    background-color:#E2E2E2;
    color: #000000;
    & > .filter-item__close {
      width: 16px;
      height: 16px;
      border: 1px solid #707070;
      border-radius: 50%;
      background: #707070;
      padding: 0;
      cursor: pointer;
      color: #fff;
      font-size: 10px;
      line-height: 16px;
      &:focus {
        outline: none;
      }
    }
    &:hover {
      background: #CCCCCC;
      & > .filter-item__close {
        border: 1px solid #5F5F5F;
        background: #5F5F5F;
      }
    }
  }

  .filter-item__text {
    max-width: 227px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    margin: 0 8px;
    cursor: pointer;
    white-space: pre;
    line-height: 1.6;
  }
  .filter-item__danger_text {
    max-width: 227px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    margin: 0 8px;
    cursor: default;
    white-space: pre;
    line-height: 1.6;
  }
  .filter-item__close {
    width: 16px;
    height: 16px;
    border: 1px solid theme-color("secondary");
    border-radius: 50%;
    background-color: white;
    padding: 0;
    cursor: pointer;
    color: color("gray-dark");
    font-size: 10px;
    line-height: 16px;
    &:focus {
      outline: none;
    }
  }
  &__close-warning {
    margin-left: 8px;
  }
}

.popover-body {
  color: $white;
  font-size: 11px;
  line-height: 1.4;
  border-radius: 3px;
  background-color: #999999;
  box-shadow: 0 0 5px $box-shadow-color;
  img {
    filter: invert(100%) brightness(200%) contrast(100%);
  }
}

.data-export-setting {
  .badge-danger {
    .filter-item__close {
      border: 1px solid #ccc;
    }
  }
}

.tooltip-title-filter {
  font-size: 11px;
  line-height: 1.4;
  color: color("gray-dark");
  max-width: 210px;
  padding: 6px 10px 3px;
  border: 1px solid color("gray-hover");
  background-color: theme-color("info");
}
