@import "src/variables";

.display-setting__modal-label {
  .modal-content {
    width: 635px !important;
  }
  .modal-scroll {
    width: 575px !important;
  }
  .modal-header {
    margin: 0 0 20px 0;
  }
  .modal__note {
    padding: 0;
  }
  .ebis-form-group--container {
    margin-bottom: 0px;
  }
  .container__settings--csv {
    background-color: #F4F4F4;
    padding: 10px 20px;
  }
  .ebis-form-group--currencySymbol {
    margin-bottom: 20px;
  }
  .ebis-form-group--commaAmount {
    margin: 0px;
  }
  #radio-currencySymbol-f,#radio-commaAmount-f {
    margin-left: 45px;
  }
}
