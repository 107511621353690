
.media-sync-icon {
  content: "";
  width: 13px;
  height: 13px;
  margin-left: 3px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/media-sync-icon.svg");
}