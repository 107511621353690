@import "src/variables";
$chk-width : 15px;
$chk-height: 15px;
.form-checkbox {
  line-height: 1.6;
  margin-right: 0;
  .form-check-label {
    cursor: pointer;
    padding-top: 0px;
    padding-left: 5px;
  }
  .form-check-input[type="checkbox"] {
    position: relative !important;
    cursor: pointer;
    flex: none;
    width: $chk-width;
    height: $chk-height;
    margin-right: 0;
    overflow: hidden;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    box-shadow: none;
    outline: none;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      background-color: theme-color("info");
      border: 2px solid $border-color;
    }
    &:hover {
      &:before {
        border-color: color(gray-hover);
      }
    }
    &:checked {
      &:before {
        border: none;
        background-color: theme-color("secondary");
      }
      &:after {
        content: '';
        width: 10px;
        height: 6px;
        border: 3px solid #fcfff4;
        border-top: none;
        border-right: none;
        border-radius: 2px;
        position: absolute;
        top: 0.25rem;
        left: 0.2rem;
        transform: rotate(-45deg);
      }
    }
    &:disabled {
      cursor: default;
      & + .form-check-label {
        opacity: 0.3;
        cursor: default;
      }
    }
    &:not(.position-static) {
      top: -1px;
    }
  }
  &.form-checkbox--mixed {
    .form-check-input[type="checkbox"] {
      &:before {
        border: none;
        background-color: theme-color("secondary");
      }
      &:after {
        content: '';
        width: 10px;
        height: 3px;
        background-color: $white;
        border: none;
        border-radius: 50px;
        position: absolute;
        top: 6px;
        transform: rotate(0deg) translateX(-0.5px);
      }
    }
  }
}
