@import 'src/variables';

.checkbox-element {
  margin-bottom: 0;
  user-select: none;
  & > span {
    display: flex;
    align-items: center;
  }
}
.checkbox-element__icon.fad:before {
  color: white;
}
.checkbox-element__icon {
  margin-right: 5px;
  font-size: 18px;
  cursor: pointer;
}
.checkbox-element__icon:after {
  color: color('gray-light');
  opacity: 1;
}
.checkbox-element__icon--hover:not(.checkbox-element__icon--disabled):after {
  color: color('gray-hover');
}
.checkbox-element__icon--checked {
  color: theme-color('secondary');
}
.checkbox-element__icon--checked::after {
  color: theme-color('secondary');
}
.checkbox-element__icon--disabled {
  cursor: default;
}
.checkbox-element__icon--disabled:after {
  opacity: 0.3;
}
.checkbox-element__label {
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
}
.checkbox-element__label--disabled {
  cursor: default;
  opacity: 0.3;
}
.checkbox-element__media-sync-icon {
  margin-left: 5.5px;
  margin-top: auto;
  margin-bottom: auto;
}
