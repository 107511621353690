@import 'src/variables';
@import 'src/placeholders';

$checkbox-color: theme-color("secondary");

.filter-container {
  font-size: 13px;
  line-height: 1.6;
  min-height: 42px;
  margin: 0px 30px 7px;
  padding: 0 20px;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  .btn-link {
    padding: 0;
    border: none;
    font-size: inherit;
    line-height: inherit;
    &:disabled, &.disabled {
      color: $link-color;
    }
  }
  .filter-container__label {
    width: max-content;
    white-space: nowrap;
  }
  &.filter-container--custom-view {
    background-color: color("base");
    margin: 0em 1em 0em 0em;
  }
  .filter-container__content {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 20px 5px;
    position: relative;

    .filter-container__selected-area {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .FilterItem {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 10px;
      }
    }
    .filter-container__action-area {
      display: flex;
      flex-direction: row;
      white-space: nowrap;
      margin-bottom: 5px;
      align-items: center;
      .btn-secondary {
        margin: 0 10px;
        padding: 2px 7px;
        border: none;
        font-size: inherit;
        line-height: inherit;
        font-size: 12px;
      }
      .notice-text {
        // color: color("gray-hover");
        margin-left: 10px;
        font-size: 11px;
      }
    }
  }
}

.filter-container__popup-item-list {
  position: absolute;
  z-index: 3;
  background-color: white;
  top: 26px;
  left: 0;
  margin-bottom: 7px;
}

//Filter in customview
.custom-view-modal__items {
  .filter-container {
    &__selected-area {
      .filter-container__action-area {
        margin-bottom: 3px;
      }
    }
  }
}
