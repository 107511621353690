@import "src/variables";

.qr-code__label {
  margin-right: 7px;
}
.qr-code__size {
  .dropdown-toggle, .dropdown-menu {
    min-width: auto;
    width: 60px;
  }
}
.qr-code__type {
  margin: 0 7px 0 15px;
 .form-check {
    margin: 0;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
.qr-code__image {
  margin-top: 10px;
}
.qr-code__note {
  margin-top: 10px;
  font-size: 11px;
  color: color('gray-dark');
}