@import "src/variables";

.common-bar {
  background-color: color("base");
  box-shadow: 3px 2px 5px 0px $box-shadow-color;
  > .navbar {
    min-height: 60px;
  }
  &__breadcrumb {
    color: #2347B5;
  }
  &__breadcrumb:hover {
    color: #2347B5;
  }
  .navbar {
    padding: 6px 30px;
    background: #F4F4F4;
    .navbar-nav {
      margin-top: -1px;
      margin-left: 20px;
    }
    .navbar-brand {
      color: $black;
      padding: 0;
      .channel {
        font-size: 14px;
      }
      .page {
        font-size: 18px;
        font-weight: bold;
        .screen-title-icon {
          height: 21px;
          margin-right: 5px;
        }
      }
    }
    .nav-link {
      &.nav-link--icon {
        color: #000000;
        padding: 0;
        &:not(:last-child) {
          margin-right: 15px;
        }
        &:not(.disabled):hover, &:not(.disabled):focus, &:not(.disabled):active {
          color: #000000;
        }
        &:not(.disabled) > div:nth-child(1){
          cursor: pointer;
        }
        & > div:nth-child(2){
          cursor: default;
        }
      }
      .icon-svg {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center center;
        margin-bottom: 0;
        &.icon-email-setting {
          background-image: url("../../../assets/images/icon-email-setting.svg");
        }
        &.icon-setting {
          background-image: url("../../../assets/images/icon-setting.svg");
        }
      }

      &:not(.disabled):hover, &.active {
        .icon-svg {
          background-color: theme-color("primary");
          &.icon-email-setting {
            background-image: url("../../../assets/images/icon-email-setting-white.svg");
          }
          &.icon-setting {
            background-image: url("../../../assets/images/icon-setting-white.svg");
          }
          &.filter--applied {
            background-color: transparent;
            background-image: url("../../../assets/images/filter-icon.svg");
            &:after {
              border: 2px solid $link-color;
              background-color: $link-color;
            }
          }
        }
      }
      .active {
        .icon-svg {
          &.icon-setting {
            background-color: theme-color("primary");
            background-image: url("../../../assets/images/icon-setting-white.svg");
          }
        }
      }
      &.setting.disabled {
        opacity: 0.3;
        pointer-events: unset;
        cursor: default;
        &:focus {
          outline: none;
        }
        .icon-setting {
          background-color: unset;
        }
      }
      &.dropdown{
        &[disabled] {
          opacity: 0.3;
          pointer-events: none;
        }
        .dropdown-menu {
          min-width: auto;
        }
      }
    }
  }
  .nav-link {
    padding-bottom: 0;
    padding-top: 0;
    &.nav-link--icon {
      font-size: 10px;
      text-align: center;
    }
  }
  .custom-view {
    max-width: 240px;
    .dropdown-item {
      &.item {
        > span {
          &:first-child {
            max-width: 100%;
          }
          &:not(:first-child) {
            visibility: hidden;
            width: 22px;
            height: 22px;
            margin-left: 5px;
            border-radius: 50%;
          }
        }
        &:hover > span {
          &:first-child {
            max-width: calc(100% - 54px);
          }
          &:not(:first-child) {
            visibility: visible;
            &:hover {
              background-color: theme-color("base");
            }
          }
        }
      }
    }
  }
  &[screen-name=user_profile]{
    min-width: 1160px;
  }
  .dropdown-menu {
    .dropdown-divider {
      margin: 6.65px 0;
    }
    .dragonball {
      padding-left: 0px;
      padding-top: 6px;
      color: #DBCDB4;
      line-height: 6.24px;
      display: inline-block;
    }
  }
  
}
.hide-navbar .common-bar {
  box-shadow: -1px 2px 5px 0 $box-shadow-color;
  clip-path: inset(0 0 -1000px 0px);
}
