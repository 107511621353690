@import "src/variables";

.transition-modal-new {
  .modal-dialog {
    .modal-content {
      padding: 0;
    }
    .modal-header {
      background-color: theme-color('secondary');
      padding: 10px 30px;
      margin-bottom: 10px;
    }
    .modal-title {
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
    }
    .modal-body {
      padding: 0 30px 30px 30px;
      span {
        display: inline-block;
        font-size: 15px;
        font-weight: bold;
        color: theme-color('primary');
        padding: 10px 0;
      }
    }
    .modal-footer {
      .mr-auto {margin: 0;}
      padding: 0 30px 30px 30px;
      label {
        margin-bottom: 0;
        .checkbox-item {
          vertical-align: middle;
          margin-top: -3px;
        }
      }
    }
  }
}
