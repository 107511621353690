@import "src/variables";

.display-items-form__checkbox-list {display: flex; width: 740px; flex-wrap: wrap; margin-top: 5px;}
.display-items-form__checkbox-item {width: 165px; margin-bottom: 12px; margin-right: 10px;}
.display-items-form__note {font-size: 11px; color: color("gray-dark"); margin-top: 5px;}
.display-items-form__note--disabled {color: color("gray-light");}
.display-items-form__checkbox-list-hisotry-info-view {width: 680px;}

.display-items-form__direct {
  grid-area: direct;
}
.display-items-form__indirect {
  grid-area: indirect;
  width: 75px;
}
.display-items-form__actions {
  grid-area: actions;
}
.display-items-form__indirect-list {
  grid-area: indirect-items;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 20px 5px 20px;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  margin-bottom: 10px;
}
.display-items-form__indirect-item {width: 110px; margin-bottom: 5px;}
.display-items-form__first {
  grid-area: first;
}
.display-items-form__group-contact-history {margin-top: -12px; margin-bottom: 0px !important;}
.display-items-form__contact-history {
  margin-top: 5px;
  display: grid;
  grid-template-columns: 82px auto;
  grid-template-rows: auto;
  grid-template-areas:
    "direct direct"
    "indirect actions"
    ". indirect-items"
    "first first";
}
.display-items-form__action-link {color: color("link"); opacity: 1; cursor: pointer; margin-right: 10px; font-size: 11px;}
.display-items-form__action-link--disabled {color: color("link"); opacity: 0.3; cursor: default; pointer-events: none;}
.display-items-form__history-info-list {padding: 10px 20px 0 20px; border: 1px dashed color("gray-light"); border-radius: 3px; opacity: 1; margin-bottom: 30px;}
.display-items-form__history-info-heading {font-size: 13px; font-weight: bold;}
.display-items-form__history-info-heading--disabled {font-size: 13px; font-weight: bold; opacity: 0.3;}
.display-items-form__history-info-item {width: auto; margin-right: 30px; margin-bottom: 10px;}
.display-items-form__history-info-item--long {width: auto;}
