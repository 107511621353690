@import 'src/placeholders';
@import "src/variables";

.filter-container {
  .panel {
    border-radius: 5px;
    .panel__content {
      .filter-type__note {
        font-size: 11px;
        color: #666666;
        margin-top: 5px;
      }
      .filter-type__separator {
        display: flex;
        align-items: center;
        color: #666666;
        margin: 10px 15px;
        &:before, &:after {
          content: '';
          flex: auto;
          height: 1px;
          background-color: #DDDDDD;
        }
        &:before {
          margin-right: 5px;
        }
        &:after {
          margin-left: 5px;
        }
      }
      .filter-type__one {
        .filter-type__note {
          margin-left: 15px;
          margin-right: 15px;
        }
      }
      .filter-type__five {
        .form-group {
          &:first-child {
            margin-top: 0;
          }
          input {
            &.number-input {
              width: 100px;
              margin: 0 5px;
              text-align: right;
              padding-right: 10px;
            }
          }
          label.form-label {
            margin: 0;
          }
        }

      }
      .filter-type__seven {
        .form-group {
          display: flex;
          &:first-child {
            margin-top: 0;
          }
          input {
              width: 100px;
              margin-right: 6px;
              text-align: right;
              padding: 0 10px;
          }
          label.form-label {
            margin: 0;
          }
        }
        .form-label {
          &:hover {
            background-color: unset;
          }
        }
      }
      .filter-type__nine {
        &.line {
          margin-top: 10px;
          margin-bottom: 0px;
        }
        &.text {
          margin-top: 0px;
          margin-bottom: 5px;
          padding: 0px 15px;
        }
      }
      .filter-type__ten {
        padding: 0 15px;
        .form-input {
          width: 100px;
          margin-right: 6px;
        }
      }
      .filter-type__eleven {
        padding: 0 15px;
      }
      .filter-type__count-range {
        &.check-box{
          height: 23px;
        }
        .form-group {
          &:first-child {
            margin-top: 0;
          }
          input {
            &.number-input {
              text-align: right;
            }
            &.number-input:disabled {
              background-color: white;
            }
            &.number-input:disabled::placeholder {
              color: #eee;
            }
          }
          .name {
            margin: 0px;
          }
          .compare--enabled {
            margin: 0px;
            margin-right: 8px;
            color: #000000;
          }
          .compare--disabled {
            margin: 0px;
            margin-right: 8px;
            color: #ddd;
            pointer-events: none;
          }
          .trush {
            margin-top: 5px;
            margin-left: 5px;
          }
          .trush-icon {
            font-size: 18px;
          }
        }
      }
    }
    .filter-type__count-range--non-trush {
      .form-group {
        input {
          &.number-input {
            width:85px;
            margin-right: 8px;
            text-align: left;
          }
        }
      }
    }
    .filter-type__count-range--trushable {
      .form-group {
        input {
          &.number-input {
            width:75px;
            margin-right: 8px;
            text-align: left;
          }
        }
      }
    }
    .panel__footer {
      .btn.btn-link {
        padding: 0 10px;
      }
      .btn.btn-secondary {
        margin-right: 0;
        &:focus {
          background-color: theme-color('secondary');
          border-color: theme-color('secondary');
        }
        &:focus:hover {
          background-color:#000000;
          border-color: #000000;
        }
      }
    }
  }

  .filter-container__action-area {
    .panel {
      .panel__content {
        .filter-type__three {
          margin-top: 10px;
          .form-group {
            margin: 0;
            padding: 0;
          }
          .form-label {
            display: block;
            margin: 0;
            padding: 3px 15px;
            cursor: pointer;
            .checkbox-item {
              top: 2px;
            }
            &:hover, &.active {
              background-color: theme-color('base');
            }
          }
        }
      }
    }
  }

  .filter-type__two {
    padding: 0 15px;
    .dropdown {
      .dropdown-toggle, .dropdown-menu {
        width: 128px;
        min-width: auto;
      }
    }
    .form-group {
      padding: 0;
      margin-top: 0;
      &:first-child {
        margin-top: 10px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .form-label {
        flex: none;
        margin-left: 6px;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}


.form-group {
  &.has-icon {
    position: relative;
    input {
      padding-right: 30px;
    }
    i {
      font-size: 13px;
      color: color("gray-dark");
      position: absolute;
      right: 8%;
      top: 30%
    }
  }
  label.form-label {
    margin-bottom: 5px;
  }
}

  .form-check {
    input + .form-check-label {
      @extend %not-selectable;
    }
  }
.filter-type-one__checked-all.btn-link:hover,
.filter-type-one__unchecked-all.btn-link:hover {text-decoration: none;}
.filter-type-one__checked-all.btn-link:disabled,
.filter-type-one__unchecked-all.btn-link:disabled {opacity: 0.3;}
.filter-type-one__limit {
  float: right;
  color: color('gray-hover');
}
.filter-message {
  background-color: theme-color("base");
  text-align: center;
  padding: 5px 20px;
  font-size: 11px;
}

.filter-type__period {
  .single-date-picker-input {
    width: 106px;
  }
  span {
    padding-left: 7px;
    padding-right: 7px;
  }
}
