@import "src/variables";

.table-view-detail {
  line-height: 1.6;
  table-layout: fixed;
  margin-bottom: 0;
  &.table-borderless {
    border: 1px solid transparent;
  }
  &.table-even, tr:nth-child(even) {
    background-color: theme-color("base");
  }
  td {
    border: none;
    padding: 9px 15px;
    vertical-align: middle;
    word-break: break-all;
    white-space: pre-line;
    &:not(:first-child) {
      border-left: 1px solid $table-border-color;
    }
    &:last-child {
      width: 459px;
    }
    img {
      max-width: 100%;
    }
  }
  .str-wrap {
    white-space: initial;
    word-break: break-all;
  }
  .str-note {
    font-size: 11px;
    color: color('gray-dark');
  }
  .break-line:not(:last-child) {
    margin-bottom: 9px;
  }
  .new-line {
    display: block;
    content: "";
    height: 5px;
  }
}
td.table-view-detail__custom-three-columns {
  padding: 0;
  tr {
    background-color: inherit !important;
    td {
      width: 145px;
      padding-top: 4px;
      padding-bottom: 4px;
      &:first-child {
        width: 135px;
      }
    }
    &:first-child td {
      padding-top: 9px;
    }
    &:last-child td {
      padding-bottom: 9px;
    }
    &:not(:first-child) td:first-child {
      display: none;
    }
  }
  .icon-hover {
    margin-left: 3px;
  }
}
.table-view-detail--empty {
  text-align: center;
}
.sub-title {
  color: #ce4242;
}
.copy-btn {
  margin-left: 3px;
}