@import "src/variables";
/*---------- Nav Menu Left ----------*/
/*---------- Common Content ----------*/

.alert-warning-wrapper {
  background: #FFFBF3;
  border-bottom: 1px solid #ddd;
  .alerts {
    background-color: #FFF1EF;
  }
  .alert {
    padding: 3px 0 3px 30px;
    margin: 0;
    border: none;
    border-radius: 0;
    font-size: 11px;
    color: black;
    &.alert-primary {
      padding: 9px 0 9px 30px;
      line-height: 1;
      border-bottom: 1px solid #ddd;
      background-color: #FFF1EF;
      .fa-exclamation-circle.text-danger {
        color: #E27070 !important;
        font-size: 13px;
      }
    }
    &.alert-warning {
      background-color: #FFFBF3;
      color: #333333;
      padding: 4px 0 4px 22px;
      .fa-exclamation-triangle.text-warning {
        color: #ffc107 !important;
        font-size: 12px;
        margin-left: 10px;
      }

    }
    img {
      margin-right: 5px;
      margin-bottom: 2px;
      width: 14px;
      height: 14px;
    }
    i {
      margin-right: 5px;
    }
    a.page {
      width: 24px;
      height: 24px;
      display: inline-block;
      text-align: center;
      font-size: 16px;
      color: color("gray-dark");
      margin: 0 3px;
      &:hover {
        text-decoration: none;
        background-color: color("base");
        border-radius: 50%;
        cursor: pointer;
      }
      &.disabled {
        color: #d0d0d0;
        background-color: unset;
        cursor: default;
      }
      i{
        margin: auto;
        vertical-align: middle;
        font-size: 12px;
      }
    }

  }
}

