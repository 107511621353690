.lp_ltv_wrap {
    width: 100%;
    min-width: 960px;
    overflow-x: auto;
  }
  .lp_ltv {
    display: flex;
    justify-content: flex-start;
    max-width: 1120px;
    margin-right: auto;
  }
  .lp_ltv img {
    max-width: 100%;
    height: auto;
    -webkit-backface-visibility: hidden;
  }
  .lp_ltv_main {
    flex: 1 1 auto;
    padding: 30px 30px 0;
    max-width: 740px;
  }
  .lp_ltv_main h3 {
    line-height: 1.6;
  }
  .lp_ltv_main_title {
    position: relative;
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 10px;
    margin-top: 30px;
  }
  .lp_ltv_main_title::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right,#8edea0 0,#8edea0 19%,#8edea0 19%,#019489 19%,#019489 100%);
  }
  .lp_ltv_main_text {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .lp_ltv_main_hero {
    margin-bottom: 10px;
  }
  .lp_ltv_hook {
    font-weight: 700;
    -webkit-font-feature-settings: 'palt';
    font-feature-settings: 'palt';
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  .lp_ltv_hook strong {
    color: #fff;
    background: #ce4242;
    border-radius: 50px;
    padding: 5px 10px;
    white-space: nowrap;
    flex: 0 0 auto;
  }
  .lp_ltv_hook span {
    flex: 1 1 auto;
    padding-left: 10px;
  }
  .lp_ltv_main h3 {
    font-size: 18px;
    font-weight: 700;
  }
  .lp_ltv_sub {
    flex: 0 0 350px;
    padding-right: 30px;
  }
  .lp_ltv_subcolumn {
    display: flex;
    align-items: flex-end;
  }
  .lp_ltv_subcolumn_photo {
    flex: 0 0 100px;
  }
  .lp_ltv_subcolumn_text {
    flex: 1 1 auto;
    border-radius: 5px;
    margin-left: 10px;
    background: #F4F4F4;
    padding: 10px 15px;
  }
  .lp_ltv_subcolumn_name {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .lp_ltv_subcolumn_detail {
    font-size: 12px;
    margin-bottom: 0;
  }