@import "src/variables";
.fabell {
    position:relative;
}
.fabell:after {
  position: absolute;
  right: 6px;
  top: 8px;
  content: '';
  border-radius: 50%;
  color: white;
  background: theme-color("primary");
  width: 11px;
  height: 11px;
  border: 2px solid theme-color("info");
}
.fabell.active:after,
.fabell:hover:after
 {
    background: white;
    border: 2px solid theme-color("primary");
}
.fafilter{
 @extend .fabell
}
.fafilter:after {
    right: 4px;
    top: 2px;
    border: 2px solid color("base");
    background-color: color("link");
}
.pagination-icon {
  .fa-chevron-left {
    border-radius: 0;
    padding: 5px 11px 5px 12px;
    margin-left: 3px;
  }
  i {
    background: color("base");
    border-radius: 0 2px 2px 0;
    font-size: 10px;
    padding: 5px 11px 5px 12px;
    height: 20px;
    &:hover {
      color: $white !important;
      text-decoration: none;
      background-color:theme-color("primary");
      cursor: pointer;
      font-weight: bold;
      padding: 5px 11px 5px 11px;
    }
  }
}
.newicon {
  width: 35px;
  height: 15px;
  border-radius: 30px;
  opacity: 1;
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  letter-spacing: 0px;
  padding-left: 0px;
  margin-top: 7px;
}
.newicon-margin {
  font-size: 10px;
  margin-top: 1px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
}
