.priority-axis-checkbox {
    display: flex;
    justify-content: left;
    margin-bottom: 19px;
    margin-top: 8px;
    flex-wrap: wrap;

    &__note {
        padding-top: 5px;
        text-align: left;
        font: normal normal normal 11px/14px Meiryo;
        letter-spacing: 0px;
        color: #666666;
        opacity: 1;
        flex-shrink: 0;
        width: 100%;
    }

    &__dragonball {
        padding-left: 5px;
        padding-top: 6px;
        color: #DBCDB4;
        line-height: 6.24px;
        display: inline-block;
    }

    &__item {
        padding: 0px !important;
        margin: 0px !important;
        height: 20px;

        .form-check-input {
            padding: 0px !important;
            margin: 0px !important;
        }
    }

    &__item--media-sync {
        .form-check-label {
            width: 91px;
        }
    }

    &__item--ebis {
        margin-left: 20px !important;
        .form-check-label {
            width: 130px;
        }
    }

    &__label {
        margin-left: 20px;
    }
    
    .form-check-label {
        text-align: left;
        font: normal normal normal 13px/14px Meiryo;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }
}