.filter-container-contact-history {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1px;
  padding-bottom: 5px;
  padding-left: 50px;
  &__item {
    .filter-tooltip {vertical-align: text-bottom; margin-top: -3px;}
    .form-label {
      .form-check {
        margin-right: 5px;
      }
      span {vertical-align:text-bottom;}
    }
    .collapsable-filter {margin-right: -20px;}
  }
}
.filter-container-contact-history--setting-view {
  padding-left: 0
}
.filter-container-contact-history__item {
  margin-right: 20px;
  line-height: 14px;
}
.filter-container-contact-history__item label {
  margin-bottom: 0;
  line-height: 20px;
}
.filter-container-contact-history__item {
  .collapsable-filter__panel {
    .form-group {
      .form-label {
        margin-bottom: 1px;
      }
    }
  }
}
.filter-container-contact-history__item--indirect {
  margin-right: 7px;
}