@import 'src/variables';

/*---------- Nav Menu Left ----------*/
/*---------- Common Content ----------*/
.menu-wrapper {
  position: sticky;
  top: 60px;
  z-index: 5;
  flex: auto;
  max-height: calc(100vh - 60px);
  background: theme-color('base');
  transition: margin 0.6s;
  flex-shrink: 0;
  width: 240px !important;
  .nav-tabs {
    border: none;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    .nav-link {
      border: none;
      border-radius: unset;
      cursor: pointer;
      padding: 0 2px 2px 2px;
      color: #000000;
      font-size: 14px;
      line-height: 1.7;
      border-bottom: 3px solid transparent;
      &.active,
      &.subActive,
      &:hover {
        font-weight: bold;
        border-bottom: 3px solid color('channel-all');
        color: color('channel-all');
        background-color: theme-color('base');
        padding-left: 2px;
      }
      &:focus {
        outline: none;
      }
    }

    & > a.nav-link:first-child {
      margin-right: 35px;
      width: 33px;
      padding-left: 3px;
      &.active,
      &.subActive,
      &:hover {
        border-color: theme-color('primary');
        color: theme-color('primary');
      }
    }
  }
  .tab-content {
    li.menu-item {
      color: black;
      -webkit-box-direction: normal;
      -webkit-box-flex: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      flex-grow: 1;
      -ms-flex-positive: 1;
      float: none;
      margin: 0;
      position: relative;
      // text-indent: 15px;
      a,
      .menu-label {
        cursor: pointer;
        padding: 11px 0;
        font-size: 14px;
        text-decoration: none;
        color: inherit;
        background-color: inherit;
        display: flex;
        align-items: center;
        span[role='button'] {
          margin-left: 3px;
          margin-right: 6px;
          .fa-stack--menu-item {
            margin-left: 3px;
          }
        }
      }

      .menu-item-sub {
        padding-left: 0;
        li {
          a {
            text-indent: 46px;
          }
        }
      }

      & > a:hover,
      &.active > a,
      &.subActive > a,
      & > .menu-label:hover,
      &.active > .menu-label,
      &.subActive > .menu-label {
        background-color: white;
        font-weight: bold;
        span.fa-stack--menu-item {
          i.fa-circle {
            display: none;
          }
          i:not(.fa-circle) {
            &::before {
              position: relative;
              z-index: 1;
            }
            &::after {
              content: '';
              z-index: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-52%, -52%);
              width: 27px;
              height: 27px;
              border-radius: 50%;
            }
          }
        }
      }
    }

    li.menu-item--sub {
      > .menu-label {
        &::after {
          content: '';
          display: inline-block;
          color: inherit;
          vertical-align: middle;
          margin-left: 10px;
          border-top: 4px solid;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
        }
      }
      .menu-nav {
        display: none;
        height: auto;
        padding-bottom: 0;
      }
      &.subActive {
        > .menu-label {
          &::after {
            border: none;
            border-bottom: 4px solid;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
          }
        }
        .menu-nav {
          display: block;
          position: relative;
          padding-bottom: 0;
          &::before {
            content: '';
            width: 100%;
            height: 10px;
            position: absolute;
            top: 0;
            box-shadow: inset 0px 5px 4px -2px rgba(0, 0, 0, 0.16);
          }
        }
      }
      .menu-item {
        position: initial;
      }
    }

    .tab-pane {
      .menu-item {
        .fa-stack.has-background {
          margin-right: 10px;
        }
        .bk-svg {
          background-repeat: no-repeat;
          background-position: center center;
          border-radius: 50%;
          width: 26px;
          height: 26px;
          display: block;
          margin: 0 10px 0 7px;
          &.dashboard {
            background-image: url('../../../assets/images/navbar/dashboard.svg');
          }
          &.category {
            background-image: url('../../../assets/images/navbar/category.svg');
          }
          &.cv_attr {
            background-image: url('../../../assets/images/navbar/cv_attr.svg');
            background-position-x: 10px;
          }
          &.log_ebis {
            background-image: url('../../../assets/images/navbar/log_ebis.svg');
          }
          &.log_analyze {
            background-image: url('../../../assets/images/navbar/log_analyze.png');
          }
          &.lpo_ebis {
            background-image: url('../../../assets/images/navbar/lpo_ebis.svg');
          }
          &.audience {
            background-image: url('../../../assets/images/navbar/audience.svg');
          }
        }
      }
    }

    .tab-pane.left {
      li.menu-item {
        &:hover,
        &.active,
        &.subActive {
          & > a,
          & > .menu-label {
            color: theme-color('primary');
            span.fa-stack--menu-item {
              i:not(.fa-circle) {
                color: color('primary') !important;
                &::after {
                  background-color: #f5e5e3;
                }
              }
            }
            .bk-svg {
              background-color: #f5e5e3;
              &.dashboard {
                background-image: url('../../../assets/images/navbar/dashboard_ad.svg');
              }
              &.category {
                background-image: url('../../../assets/images/navbar/category_ad.svg');
              }
              &.cv_attr {
                background-image: url('../../../assets/images/navbar/cv_attr_ad.svg');
              }
              &.lpo_ebis {
                background-image: url("../../../assets/images/navbar/lpo_ebis_active.svg");
              }
              &.audience {
                background-image: url('../../../assets/images/navbar/audience_active.svg');
              }
            }
          }
        }
      }
    }
    .tab-pane.right {
      text-align: left;
      li.menu-item {
        &:hover,
        &.active,
        &.subActive {
          & > a,
          & > .menu-label {
            color: color('channel-all');
            span.fa-stack--menu-item {
              i:not(.fa-circle) {
                color: color('channel-all') !important;
                &::after {
                  background-color: #f5eddf;
                }
              }
            }
            .bk-svg {
              background-color: #f5eddf;
              &.dashboard {
                background-image: url('../../../assets/images/navbar/dashboard_all.svg');
              }
              &.category {
                background-image: url('../../../assets/images/navbar/category_all.svg');
              }
              &.cv_attr {
                background-image: url('../../../assets/images/navbar/cv_attr_all.svg');
              }
              &.log_ebis {
                background-image: url('../../../assets/images/navbar/log_ebis_active.svg');
              }
              &.log_analyze {
                background-image: url('../../../assets/images/navbar/log_analyze_active.png');
              }
              &.log_ebis {
                background-image: url("../../../assets/images/navbar/log_ebis_active.svg");
              }
              &.audience {
                background-image: url('../../../assets/images/navbar/audience_active.svg');
              }
            }
          }
        }
      }
    }
    .menu-nav {
      height: calc(100vh - 133px);
      list-style: none;
      margin: 0;
      padding: 0;
      overflow-y: auto;
      @media screen and (max-height: 780px) {
        padding-bottom: 85px;
      }
      .menu-item {
        .menu-item-sub {
          &:hover {
            background: none;
          }
        }
      }

      .border-top-menu {
        border-top: 1px solid #dddddd;
        margin: 0px 0px;
        margin-top: 10px;
        padding-top: 10px;

        a {
          padding: 6px 0;

          .justify-content-center {
            font-size: 13px;
          }
        }
      }

      .middle a {
        padding: 11px 0;

        .justify-content-center {
          font-size: 14px;
        }
      }
      .border-top-menu.external ~ .border-top-menu.external {
        border-top: none;
        margin: 0px 0px;
        padding: 0px;
      }
    }
  }
}

.hide-navbar {
  .menu-wrapper {
    margin-left: -240px;
  }
}

.tab--disabled {
  opacity: 0.3 !important;
  color: #999999 !important;
  pointer-events: auto !important;
  &:active {
    pointer-events: none !important;
  }
  &:hover {
    cursor: default !important;
    font-weight: normal !important;
    color: #999999 !important;
    border-color: transparent !important;
    border-bottom: 3px solid transparent !important;
    opacity: 0.3 !important;
  }
}

@media screen and (max-width: 1199px) {
  .menu-wrapper {
    max-height: calc(100vh - 77px);
  }
}
