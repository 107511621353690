@import "src/variables";

.display-items-form {
    .axis-group__item--five {
        margin-right: 15px;
        .dropdown {
            width: 136px;
        }
    }
    .ebis-time-axis-radio {
        .form-check--ebis-radio {
            width: 160px;
            margin-right: 10px;
        }
    }
}
.display-items-form__checkbox-list {display: flex; width: 740px; flex-wrap: wrap; margin-top: 5px;}
.display-items-form__checkbox-item {width: 160px; margin-bottom: 12px; margin-right: 10px;}
.display-items-form__checkbox-item .width-fit-content {width: fit-content;}
.display-items-form__note {font-size: 11px; color: color("gray-dark"); margin-top: 5px;}
.display-items-form__note--disabled {color: color("gray-light");}
.display-items-form__radiobtn-items {width: 320px; height: 20px; white-space: nowrap; margin-bottom: 20px;}
.display-items-form__radiobtn-items:last-child {margin-bottom: 0px;}
.display-items-form__inflow-period-list {padding: 10px 20px; border: 1px dashed color("gray-light"); border-radius: 3px; opacity: 1; margin-bottom: 30px;}
.display-items-form__inflow-period-title {font-size: 13px; font-weight: bolder;}
.compared-switch__inflow {margin: 8px 0;}

.display-items-form__radio-list { display: flex; width: 740px; flex-wrap: wrap; }
.display-items-form__radio-item {
    margin-right: 30px;
    .form-check-inline {
        margin-right: 0;
    }
}

.display-items-form__indirect-list {
    grid-area: indirect-items;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px 5px 20px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
    margin-bottom: 10px;
}

.display-conditions-form__conversion-list {
    grid-area: conversion-items;
    padding: 10px 20px 10px 20px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
}

.display-conditions-form__conversion-item {
    grid-area: conversion-items;
    display: flex;
    flex-wrap: wrap;
    padding: 0px 20px 5px 0px;
    border-radius: 3px;
    opacity: 1;
    margin-bottom: 10px;
}

.display-items-form__checkbox-item--ad-management {
    display: flex;
}