@import "src/variables";

.modal--delete-trash {
  .modal-header {
    margin-bottom: 10px;
  }
  .modal-footer {
    .form-label {
      margin-right: auto;
    }
  }
}