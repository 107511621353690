
@import "src/variables";
@import 'src/mixins';

.modal {
  z-index: 1060;
  overscroll-behavior: contain;
  &:after {
    content: '';
    display: block;
    height: calc(100% + 1px);
  }
  &.show {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
  &.modal-static .modal-dialog {
    transform: none;
  }
}
.modal-backdrop {
  z-index: 1060;
}
.modal-open {
  overflow: auto !important;
  padding-right: 0 !important;
  .modal {
    display: block;
  }
}
.modal-dialog {
  position: sticky;
  top: 0;
  margin: 0;
  min-height: 100%;
  overflow-y: auto;
  width: 100%;
  pointer-events: auto;
  // Chrome
  &::-webkit-scrollbar {
    width: 0;
  }
  // Firefox
  scrollbar-width: none;
  .modal-content {
    border: none;
    border-radius: 5px;
    padding: 30px;
    margin: 30px auto;
    box-shadow: 0px 0px 10px $box-shadow-color;
  }
  &.modal-w800 .modal-content {
    width: 800px;
  }
  &.modal-w600 .modal-content {
    width: 600px;
  }
  .modal-header {
    border: none;
    padding: 0;
    margin-bottom: 30px;
  }
  .modal-title {
    font-size: 18px;
    font-weight: bold;
    span:not(.badge) {
      font-size: 12px;
    }
  }
  .modal-body {
    padding: 0;
    .display-items-form {
      margin-bottom: -10px;
      .form-group {
        .form-group__elements {
          .dropdown {
            width: 141px;
            .dropdown-toggle {
              width: inherit;
            }
            .dropdown-menu {
              margin-top: 2px !important;
              width: inherit;
            }
          }
          .axis-group__item--five .dropdown {
            width: 136px;
          }
        }
      }
    }
  }
  .modal-footer {
    border: none;
    padding: 0;
    margin-top: 20px;
    > * {
      margin: 0;
    }
    button {
      margin: 0;
      &:not(:last-child) {
        margin-right: 10px;
      }
      &.btn-secondary {
        &:hover {
          background-color: $black;
        }
      }
      &.btn-link {
        &:focus, &.focus, &:hover {
          background-color: #E2E2E2;
          text-decoration: none;
          box-shadow: none;
        }
      }
    }
  }
}
.modal-content--settings {
  .modal-body {
    margin: 0 -30px;
    padding: 0 30px;
    overflow: hidden;
  }
}
.modal--loading {
  .modal-dialog {
    .modal-content {
      .modal-header {
        margin-bottom: 30px;
      }
      .modal-footer {
        margin-top: 30px;
      }
    }
  }
}
.modal--scroll {
  .modal-body {
    // max-height: 533px;
    max-height: 600px;
    margin-right: -30px;
    overflow: auto;
    @include scrollbars(20px, auto, 0px, 10px);
  }
  .modal-w800 {
    .modal-scroll {
      width: 740px;
    }
  }
}

.modal--error-bad-request {
  .modal-header {
    margin-bottom: 10px;
  }
}

// custom scrollbar for firefox
@-moz-document url-prefix() {
  .modal.show {
    scrollbar-width: none;
    scrollbar-color: color("gray-light") $white;
  }
}
