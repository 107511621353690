/* 1. import only the necessary Bootstrap files */
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

/* 2. begin customization ---- */
//(variable changes as before here...)
$black: #000000;
$white: #FFFFFF;
$link-color: #2347B5;
$link-hover-color: #2347B5;
$coral-expression-color: #D86B6B;
$theme-colors: map-merge($theme-colors, (
  "primary": #CE4242,
  "primary-hover": #B92727,
  "secondary": #313166,
  "info": #FFFFFF,
  "disable": #CCCCCC,
  "base": #f4f4f4,
  "dark": $black,
));
$colors: map-merge($colors, (
  "primary": #CE4242,
  "gray-dark": #666666,
  "gray-light": #CCCCCC,
  "base": #f4f4f4,
  "gray-hover": #AAAAAA,
  "link": $link-color,
  "link-hover": $link-hover-color,
  "channel-all": #B79863,
  "bad-red": #D86B6B,
  "coral-expression": $coral-expression-color
));

// Override bootstrap button opacity
$btn-disabled-opacity: 0.3;

$input-btn-focus-width: 0;
$btn-focus-width: $input-btn-focus-width;

// Pagination hover box shadow
$pagination-focus-box-shadow: none;

// Body
$body-color: $black;
$font-size-base: 0.8125rem; // 13px
$font-family-base: メイリオ, Meiryo, sans-serif;
$border-radius: 0.1875rem; // 3px
$border-color: color("gray-light");
$border-hover-color: color("gray-hover");
$component-active-bg: green;
$padding-x: 1.5625rem;
$padding-y-sm: 0.3125rem;
$padding-x-sm: 0.625rem;
$tooltip-arrow-color: color("gray-dark");
$tooltip-bg: color("gray-dark");
$tooltip-opacity: 1;
$box-shadow-color: rgba(0,0,0,0.16);

// Layout
$grid-gutter-width: 0;

// Input
$input-border-color: color("gray-light");
$input-border-color: color("gray-light");
$input-color: color("gray-dark");
$input-focus-box-shadow: none;
$input-focus-border-color: none;
$input-height-sm: 30px;
$input-font-size-sm: $font-size-base;
$input-border-radius-sm: $border-radius;
$input-padding-y-sm: $padding-y-sm;
$input-padding-x-sm: $padding-x-sm;
$input-placeholder-color: color("gray-light");
$input-border-color-error: #E27070;

// Popover
$popover-border-color: color("gray-hover");
$popover-header-padding-y: $padding-y-sm;
$popover-header-padding-x: $padding-x-sm;
$popover-body-color: $body-color;
$popover-body-padding-y: $popover-header-padding-y;
$popover-body-padding-x: $popover-header-padding-x;


// Dropdown
$dropdown-font-size: $font-size-base;
$dropdown-item-padding-x: 0.625rem;
$dropdown-link-color: $black;
$dropdown-link-hover-bg: color("base");
$dropdown-link-active-bg:color("base");
$dropdown-link-active-color: $black;
$dropdown-link-disabled-color: $black;
$dropdown-border-color: color("gray-light");
$dropdown-border-radius: $border-radius;
$dropdown-padding-y: 5px;
$dropdown-item-padding-x: 15px;


// Button
$input-btn-padding-y-sm: .313rem !default;
$input-btn-padding-x-sm: .625rem !default;
$btn-line-height-sm: 1.4;
$btn-font-size-sm: $font-size-base;
$btn-padding-y-sm: $padding-y-sm;
$btn-padding-x-sm: $padding-x-sm;
$btn-border-radius-sm: $border-radius;

//Badge
$badge-font-size: 12px;
$badge-padding-y: 5px;
$badge-pill-padding-x: 10px;
$badge-font-weight: normal;

// Pagination
$pagination-bg: #f4f4f4;
$pagination-padding-y-sm: 0rem;
$pagination-padding-x-sm: .313rem;
$pagination-hover-color: white;

$pagination-hover-bg: theme-color("primary");
$pagination-active-bg: theme-color("primary");
$pagination-active-border-color: $pagination-active-bg;
$pagination-hover-border-color: $pagination-active-bg;
$pagination-color: $black;

//Modal
$modal-xl: none;
$modal-lg: none;
$modal-md: none;
$modal-sm: none;
$modal-header-border-color: $white;
$modal-footer-border-color: $white;

// Table
$table-border-color: #DDDDDD;
$table-hover-header: #DDDDDD;
$table-hover-row: #FCFFD5;
$table-selected-row: #FFFFBF;
$table-border-hover: #E5BDBA;
$table-color: $black;
$table-highlight-cell: #FDFFD9;

// Tooltip
$tooltip-disabled-bg-color: #999999;
$tooltip-disabled-color: $white;
$tooltip-explain-bg-color: #EAE1D3;
$tooltip-explain-color: #666666;

$form-group-margin-bottom: 20px;
