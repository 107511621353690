@import 'src/variables';

.ad-management {
  height: 100%;
}

.ad-management__list {
  .table-control {
    float: left;
    margin: 10px 0px 10px 15px;
    .form-checkbox {
      margin: 0 0 0 10px;
      vertical-align: middle;
    }
  }
  .table-control__icon {
    margin-left: 15px;
    &:after {
      content: '';
      display: block;
      width: 1px;
      margin: 0 20px;
      background-color: $table-border-color;
    }
    > .btn-icon--no-text {
      margin-right: 10px;
    }
  }
  .table-control__remain {
    margin-left: 10px;
    align-self: center;
  }
  .pagination-wrap {
    padding: 15px 30px 15px 0;
  }
}

.ad-management__tabs {
  padding-left: 20px;
  padding-top: 13px;
  border-bottom: 1px solid $table-border-color;

  a {
    color: $black;
    &:hover {
      text-decoration: none;
      font-weight: bold;
    }
  }
}

.ad-settings-modal {
  .modal-body {
    &.scrollbar-wrapper {
      margin: 0 -30px;
      overflow: hidden;
      > .scrollbar-wrapper__content {
        padding: 0 30px;
      }
    }
    .popover {
      max-width: 400px;
    }
  }
  .modal-body--loading {
    min-height: 532px;
  }

  .ebis-form-group {
    .form-check {
      &:not(.checkbox-item) {
        margin: 0;
      }
      label.form-check-label {
        padding-top: 0;
      }
      .form-check-type-input {
        display: flex;
        align-items: center;
        margin-left: 7px;
        &.disabled {
          opacity: 0.3;
        }
        .form-control {
          margin-left: 7px;
          background-color: transparent;
        }
      }
      &:first-child {
        .form-check-type-input {
          margin-right: 30px;
        }
      }
    }
    .form-period {
      .form-label {
        margin: 0 5px 0 0;
      }
      .date-range-picker--simple {
        margin-right: 0;
      }
    }
    .form-input {
      position: relative;
      .popover {
        top: calc(100% - 8px);
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .collapse {
    margin-top: 30px;
    padding-bottom: 1px;
    .ebis-form-group:last-child {
      margin-bottom: 0;
    }
  }
  .searchable-select {
    .searchable-select__footer {
      position: relative;
      top: 5px;
      padding: 0;
      border: none;
      .input-collapse {
        margin-top: -5px;
        padding: 10px 15px 15px;
        border-top: 1px solid color(gray-light);
        .popover {
          max-width: 210px;
        }
      }
    }
  }

  .ebis-form-group--ad-id {
    .form-input {
      flex-direction: column-reverse;
      align-items: flex-start !important;
    }
    .form-control.error {
      margin-top: 5px;
    }
    .popover {
      position: relative !important;
      transform: none !important;
    }
  }
}

.ad-table-multi-url.table {
  margin-bottom: 0;
  tr {
    &:nth-of-type(odd) {
      background-color: transparent;
    }
    &:nth-of-type(even) {
      background-color: theme-color('base');
    }
  }
  th {
    padding: 10px 15px;
    font-weight: normal;
    background-color: theme-color('base');
    border-bottom-width: 1px;
  }
  td {
    border: none;
    padding: 5px 15px;
    vertical-align: middle;
    &:not(:first-child) {
      border-left: 1px solid $table-border-color;
    }
    div {
      line-height: 1.2;
    }
  }
  .symbol {
    display: flex;
    span {
      margin-top: 8px;
    }
  }
  thead {
    display: block;
  }
  tbody {
    max-height: 241px;
    display: block;
    overflow-y: scroll;
    // overflow-x: scroll;
  }
}

.tooltip[variant='over-limit'] {
  margin-top: 3px;
  margin-bottom: 3px;
  .arrow {
    &::before {
      border-top-color: #999999;
      border-bottom-color: #999999;
    }
  }
  .tooltip-inner {
    text-align: left;
    background-color: #999999;
    color: #ffffff;
    font: normal normal normal 11px/14px メイリオ, Meiryo, sans-serif;
    box-shadow: 0px 0px 5px #00000029;
    padding: 5px 10px;
  }
}
