@import 'src/variables';
@import 'src/mixins';

.tag-management {
  .controls__button {
    display: inline-flex;
  }
  .grid-table--content-category {
    .grid-table__wrapper--freeze {
      width: 78px !important;
    }
    .grid-table__separator .grid-table__separator-resize {
      display: none;
    }
  }
  .controls {
    .controls__button {
      .btn-icon--no-text {
        &:focus {
          background-color: #313166;
        }
        &:active,
        &:hover {
          background-color: #25254c;
        }
      }
    }
  }
}

.modal-tag-detail {
  .page-loader {
    height: 461px;
  }
}

.tag-detail-container {
  .row-detail {
    display: flex;
    .item-row__left {
      flex: 0.3;
      border-right: 1px solid #dddddd;
      padding: 10px;
    }
    .item-row__right {
      flex: 0.7;
      padding: 10px;
    }
  }
  .row-grey {
    background-color: #f4f4f4;
  }
  .dropdown-toggle {
    width: 100%;
  }
  .dropdown-menu {
    width: 100%;
  }
  .form-group {
    margin-bottom: 5px;
  }
  .ebis-form-group {
    .form-label {
      margin-bottom: 0px;
      .label {
        font-size: 13px;
        font-weight: normal;
      }
    }
    .form-text {
      margin-top: 0px;
    }
  }
  .tag-various__content {
    font-size: 13px;
  }
  .tag-various__container {
    margin-bottom: 10px;
  }
  .tag-various__button {
    font-size: 11px;
    display: flex;
    margin-bottom: 10px;
    padding: 3px 6.5px;
    span {
      padding-right: 5px;
    }
  }
  .dropdown-toggle {
    &:after {
      margin-left: 0;
    }
  }
  .google-tag-button {
    padding: 3px 6.5px;
    margin-left: 5px;
    font-size: 11px;
  }
}

.tag-google {
  padding: 30px 250px;
  .tag-goole-part {
    margin-bottom: 20px;
    p {
      margin-bottom: 0;
    }
    .tag-goole__title {
      font-size: 14px;
      font-weight: bold;
      padding-bottom: 10px;
    }
    .tag-goole__frame {
      padding: 15px 20px;
      background-color: color('base');
    }
    .tag-goole__textarea {
      margin: 5px 0px;
      font-size: 13px;
    }
  }
  .tag-google__title {
    font-weight: bold;
    font-size: 18px;
  }
  .tag-google__note {
    color: color('gray-dark');
    font-size: 11px;
  }
}

.modal-common-tag {
  .modal-content {
    .modal-header {
      margin-bottom: 5px;
    }
    .modal-body {
      .common-tag__content {
        height: 150px;
        font-size: 13px;
      }
    }
    .common-tag__note {
      color: color('gray-dark');
      font-size: 11px;
      padding-bottom: 5px;
    }
    .common-tag__button {
      font-size: 11px;
      padding: 3px 6.5px;
      margin-left: 10px;
      span {
        padding-right: 5px;
      }
    }
    .common-tag__title {
      font-size: 18px !important;
    }
  }
}
.modal-conversion-setting {
  .modal-title {
    margin-right: 10px;
  }
  .form-page-setting--min-height {
    min-height: 533px;
  }
  form > .ebis-form-group:last-child {
    margin-bottom: 0;
  }
  .ebis-form-group--page_id,
  .ebis-form-group--page_title,
  .ebis-form-group--cv_title {
    .form-control {
      width: 250px;
    }
  }
  .ebis-form-group--condition {
    margin-bottom: 5px;
    .form-group {
      margin-bottom: 0;
    }
    .dropdown-toggle,
    .dropdown-menu {
      min-width: 144px;
    }
  }
  .ebis-form-group--matching {
    background-color: #f5f5f5;
    border-radius: 3px;
    padding: 10px 20px;
    margin-left: 20px;
    > .form-group {
      margin-bottom: 17px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .form-label .label {
      font-weight: normal;
    }
    .form-matching {
      padding: 0;
    }
    .checkbox-item {
      margin-top: -2px;
    }
  }
  .ebis-form-group--route_urls {
    .form-label:not(:first-child) {
      margin-bottom: 0;
      > span {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .form-matching {
      margin: 5px 0;
    }
  }
  .ebis-form-group--owned_media_flag {
    margin-bottom: 5px;
  }
  .ebis-form-group--content_category_id {
    background-color: #f4f4f4;
    padding: 10px 20px;
    border-radius: 3px;
  }
  .ebis-form-group--disabled {
    .form-matching[disabled] {
      .form-matching__note,
      .form-control,
      .btn:disabled {
        opacity: unset;
      }
    }
  }
  .form-check--ebis-radio.form-check-inline {
    margin-right: 30px;
  }
  .table-modal {
    th:first-child,
    td:first-child {
      width: 128px;
    }
    .table {
      table-layout: fixed;
    }
    .table tr td {
      padding: 5px 15px;
    }
    .table tr:not(:last-child) td {
      border-bottom: none;
    }
    .scrollbar-wrapper__content {
      max-height: none;
    }
    .form-control[disabled] {
      background-color: #ffffff;
      opacity: 0.3;
    }
  }
  .page-loader--default {
    min-height: 271px;
  }
  .page-loader--attribute {
    min-height: 413px;
  }
  .page-loader--pageview {
    min-height: 408px;
  }
}
.content-category__modal-setting {
  .modal-content {
    .modal-header {
      justify-content: flex-start;
      .modal-title {
        padding-right: 10px;
      }
    }
    .modal-body {
      .content-category__designation {
        background-color: #f5f5f5;
        border-radius: 3px;
        padding: 10px 20px;
        margin-top: -15px;
        .form-matching {
          padding: 0;
        }
      }
      .dragonball-icon {
        margin-bottom: 0;
      }
      .form-check-inline {
        margin-right: 30px;
      }
      .content-category__modal-loading {
        height: 230px;
      }
      .content-category__form {
        max-height: 533px;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .ebis-form-group--content-category-name {
        margin-bottom: 15px;
        .form-control {
          width: 250px;
        }
      }
      th {
        border-bottom: none;
        font-weight: normal;
        padding: 9px 16px;
      }
      td {
        border-top: none;
        padding: 9px 16px;
        div {
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      .content-category__left-column {
        width: 263px;
        vertical-align: middle;
      }
      .content-category__right-column {
        overflow-wrap: anywhere;
      }
      .content-category__pd-bottom {
        padding-bottom: 10px;
        overflow-wrap: anywhere;
      }
      .content-category__note {
        color: color('gray-dark');
        font-size: 11px;
      }
      .table {
        margin-bottom: 0;
      }
    }
  }
}

.sort-list__modal {
  .modal-header {
    flex-direction: column;
    & > span {
      font-size: 11px;
      color: #666666;
    }
  }
  .modal-content {
    .modal-body {
      .sort-list__container {
        display: flex;
        flex-direction: column;
      }
      .sort-list__box {
        padding: 15px 10px;
        background-color: #f4f4f4;
        border-radius: 3px;
        .sort-list__box-table {
          max-height: 486px;
          overflow-y: auto;
          @include scrollbars(10px);
          &::-webkit-scrollbar-track {
            background-color: #f4f4f4;
          }
          list-style-type: none;
          padding-inline-start: 0;
          margin-bottom: 0;
          padding-right: 10px;
          li:first-child {
            margin-top: 0;
          }
          li:last-child {
            margin-bottom: 0;
          }
          li {
            display: flex;
            align-items: center;
            background-color: #ffffff;
            padding: 5px 10px;
            margin: 5px 0px 5px 5px;
            border: 1px solid #dbcdb4;
            border-radius: 3px;
            cursor: move;
            user-select: none;
            i {
              color: #dbcdb4;
              margin-right: 7px;
            }
            &:hover {
              background-color: #f9f6ef;
            }
            &.sort-list__drop-area {
              color: white !important;
              background: white !important;
              position: relative;
              cursor: move;
              border: none;
              &::before {
                content: '';
                color: #dbcdb4;
                text-transform: uppercase;
                width: 100%;
                height: 100%;
                border: 1px dashed #dbcdb4;
                border-radius: 3px;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                left: 0;
              }
            }
            & > span {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          img {
            padding-right: 7px;
          }
        }
      }
      .pagination-wrap--settings {
        padding: 0 0 10px 0;
      }
      .sort-list__modal-loading {
        height: 230px;
      }
    }
  }
}

.modal-content--settings {
  .ebis-form-group--content_category_id {
    background-color: #f4f4f4;
    padding: 10px 20px;
    border-radius: 3px;
    margin-bottom: 5px;
    &__description {
      margin-bottom: 20px;
      font-size: 11px;
      color: #666666;
      i {
        margin-left: 1px;
        margin-right: 4px;
      }
    }
  }
}
