@import "src/variables";

.notification__drawer {
  padding: 15px 0 0 20px !important;
  width: 300px !important;
  //height: calc(100vh - 60px);
  left: 20px !important; 
}
.notification__header {
  color: $black;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
  .btn-link {
    float: right;
    color: color("gray-dark");
    padding: 0 6px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 25px;
    &:hover {
      background-color: color("base");
      color: color("gray-dark");
    }
  }
}
.notification__content-box {
  height: calc(100vh - 110px);
  overflow: auto;
  padding-right: 20px;
}
.notification__icon {
  font-size: 17px !important;
  color: color("gray-dark");
  cursor: pointer;
}

.notification__content {
  color: $black;
  cursor: pointer;
  white-space: pre-wrap;
  background-color: color("base");
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  &:hover, &.active {
    border-radius: 5px;
  }
  .d-flex {
    span {
      font-size: 11px;
    }
  }
}

.icon--unread {
  font-size: 8px;
  padding-left: 5px;
  color:theme-color("primary");
  padding-bottom: 1px;
}
.text--truncate-two-lines {
  -webkit-line-clamp: 2;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}