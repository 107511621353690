@import "src/variables";

.tag-card__container {
 display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin-right: 20px;
}

.tag-card {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  line-height: 1.6;
  padding: 9px 10px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid color(gray-light);
  &:hover:not(.tag-card--actived) {
    border-color: #EBD9A2;
    background-color: #FFFBF1;
  }
  + .tag-card  {
    margin-left: 10px;
  }
}
.tag-card--actived {
  background-color: #FFFAF1;
  border-color: #DBCDB4;
}
.tag-card--disabled {
  opacity: 0.3;
  pointer-events: none;
}
.tag-card__text {
  position: relative;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    display: inline-block;
    margin-right: 5px;
    height: 4px;
    width: 16px;
    border-radius: 30px;
    background-color: #C8C8C8;
  }
}
.tag-card__icon {
  margin-right: 5px;
  line-height: 0;
  img {
    max-height: 20px;
  }
  + .tag-card__text:before {
    content: initial
  }
}

.tag-card--vertical {
  flex-flow: column;
  border-color: #dddddd;
  padding: 0 0 16px;
  min-width: 200px;
  min-height: 120px;
  line-height: normal;
  + .tag-card {
    margin-left: 20px;
  }
  &.tag-card--actived {
    background-color: #FFF9E5;
  }
  .tag-card__icon {
    margin: auto 0 10px;
    img {
      max-height: none;
    }
  }
  .tag-card__text {
    font-size: 14px;
  }
}

.tag-card--actived {
  background-color: #FFFAF1;
  border-color: #DBCDB4;
}
.tag-card--disabled {
  opacity: 0.3;
  pointer-events: none;
}
