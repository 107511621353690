.modal-body {
  .scrollbar-wrapper__content {
    .link_redirect {
      display: inline-block;
      i {
        margin-left: 1px;
        margin-right: 4px;
      }
    }
  }
}

.modal-footer {
  .force-confirm {
    margin-right: auto;
    .form-label {
      margin-bottom: 0;
    }
  }
}
