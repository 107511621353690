@import 'src/variables';
@import 'src/mixins';

.data-export-setting {
  margin: 30px 30px 0;
  max-width: 740px;
  .popover.is-error {
    z-index: 1;
  }
  .spinner {
    width: 30px;
    height: 14px;
    & > div {
      width: 3px;
      margin: 0 1px 0 0;
    }
  }
  &__report-header {
    margin-bottom: 10px;
    font-size: 11px;
    color: #666666;
  }
  &__collection-period-header {
    margin-right: 5px;
    &.disabled {
      color: #aaa;
    }
  }
}
.icon-caution {
  margin-right: 3px;
}
.data-export-setting__title-page {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
.data-export-setting__title-line {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  &::after {
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    background-color: $black;
  }
  &.is-error::after {
    background-color: $input-border-color-error;
  }
}

.data-export-setting__title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.data-export-setting__content {
  display: flex;
  align-items: center;
}
.dragonball-icon {
  margin-right: 3px;
}
.data-export-setting__item {
  &:not(.mb-30) {
    margin-bottom: 20px;
  }
  .multiple-emails span {
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 0;
    }
  }
  .grid-table__wrapper--freeze {
    border-left: 1px solid #dddddd;
    .grid-table__scrollable-wrapper {
      margin-left: -1px;
    }
  }
  .grid-table__wrapper--main {
    border-right: 1px solid #dddddd;
    .grid-table__scrollable-wrapper {
      margin-right: -1px;
    }
  }
  .grid-table__header {
    grid-template-rows: auto !important;
  }
  .grid-table__column-name {
    padding: 10px 15px;
  }
  .grid-table__column--child {
    font-size: 13px;
  }
}
.data-export-setting__confirm {
  margin-top: 10px;
  padding: 20px;
  font-size: 14px;
  background-color: #f4f4f4;
}
.data-export-setting__confirm-title {
  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    margin: 5px 0;
    background-color: #dddddd;
  }
}
.data-export-setting__confirm-content {
  word-break: break-all;
  word-wrap: none;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.data-export-setting__modal-filter {
  .modal-header {
    margin-bottom: 10px;
  }
  .data-export-setting__table {
    grid-template-columns: 170px auto;
  }
  .data-export-setting__cell {
    padding: 10px 20px;
    &:first-child {
      justify-content: center;
    }
    .select-popup__value {
      max-height: 100px;
    }
    .form-check-inline {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.data-export-setting__note {
  width: max-content;
  line-height: 20px;
  p {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      margin-right: 5px;
    }
  }
}
.data-export-setting__table {
  display: grid;
  grid-template-columns: 180px auto;
  grid-gap: 1px;
  box-sizing: border-box;
}
.data-export-setting__row {
  display: contents;
  .mb-5 {
    margin-bottom: 5px !important;
  }
}
.data-export-setting__cell {
  box-shadow: 0 0 0 1px #dddddd;
  padding: 10px 15px;
  &:first-child {
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
  }
  & > .d-flex {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    .form-label {
      margin-bottom: 0;
    }
    .single-date-picker-input {
      width: 106px;
    }
    .single-date-picker-separator {
      margin: 0 7px;
    }
    .dragonball-icon {
      margin-bottom: 1px;
    }
    .dropdown-toggle,
    .dropdown-menu {
      min-width: 66px;
    }
    .dropdown-toggle::after {
      margin-left: 10px;
    }
    .form-check--period-mode-normal {
      margin-right: 27px;
    }
    .form-check--period-mode-period {
      margin-right: 21px;
    }
    .form-check--week-day {
      margin-left: 10px;
      margin-right: 0;
    }
  }
}

.data-export-setting__table--attribute {
  grid-gap: 0px;
  grid-template-columns: 119px auto !important;
  .data-export-setting__row:nth-child(2n + 1) {
    .data-export-setting__cell {
      background-color: #f4f4f4;
    }
  }
  .data-export-setting__cell {
    box-shadow: unset;
    padding: 10px 0;
    &:first-child {
      padding-left: 20px;
      justify-content: unset;
      background-color: transparent;
    }
    &:last-child {
      padding-right: 30px;
    }
    & > .d-flex {
      margin-bottom: 0;
    }
  }
  .form-control::placeholder {
    font-size: 11px;
  }
  .data-export-setting__cell--member-name {
    .form-control {
      width: 270px;
    }
  }
  .data-export-setting__cell--amount {
    display: flex;
    align-items: center;
    .form-control {
      width: 100px;
      margin-right: 5px;
    }
  }
}

.data-export-setting__item--report-name {
  .form-control {
    width: 511px;
  }
  .form-input {
    position: relative;
  }
  .popover.is-error {
    transform: translate(-50%, -35px);
    left: 50%;
  }
}
.data-export-setting__item--report-type {
  display: inline-block;
  max-width: 740px;
  margin-bottom: 12px;
  .form-check--ebis-radio {
    margin-right: 30px;
  }
  .data-export-setting__content-title {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .data-export-setting__content-items {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    &:last-child {
      margin-bottom: -7px;
      .form-check--ebis-radio {
        margin-bottom: 7px;
      }
    }
  }
}
.data-export-setting__content-report--channel {
  display: inline-block;
  .form-check--ebis-radio {
    margin-right: 20px;
  }
}
.data-export-setting__item--customview {
  .dropdown {
    display: flex;
    align-items: center;
  }
  .dropdown-toggle {
    min-width: 200px;
    line-height: initial;
    padding: 4px 10px;
  }
  .dropdown-menu {
    min-width: 240px;
  }
  .dragonball-icon {
    margin-bottom: -1px;
  }
}
.data-export-setting__item--sortdata {
  margin-bottom: 25px;
  .dropdown-toggle {
    min-width: 200px;
    line-height: initial;
    padding: 4px 10px;
  }
  .dropdown-menu {
    min-width: 240px;
  }
  .setting-views-dropdown__list {
    padding: 0;
    .scrollbar-wrapper__content {
      padding: 5px 0
    }
  }
  .dropdown.sortdata-direction {
    margin-left: 5px;
    .dropdown-toggle, .dropdown-menu {
      min-width: 75px;
    }
  }
}
.data-export-setting__item--displayitem {
  margin-bottom: 30px;
  .data-export-setting__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 41px;
    grid-row-gap: 10px;
    .form-label {
      display: flex;
      margin-bottom: 0;
    }
    .checkbox-item {
      margin-top: 0;
      & + span {
        line-height: 17px;
        min-height: 20px;
      }
    }
  }
  .bs-popover-top.is-error,
  .is-error.bs-popover-auto[x-placement^='top'] {
    margin-bottom: -7px !important;
  }
  .data-export-setting__button {
    width: fit-content;
  }
  .display-items-form {
    margin-bottom: -10px;
    .form-group__heading {
      margin-bottom: 10px;
    }
    .form-group__heading-text {
      font-size: 16px;
    }
    .axis-selectbox__dropdown {
      width: 141px;
      .dropdown-toggle, .dropdown-menu {
        width: inherit;
      }
    }
  }
}
.data-export-setting__item--send-mail {
  .data-export-setting__table {
    grid-template-columns: 120px auto;
  }
  .form-control {
    width: 230px;
  }
}
.data-export-setting__item--filter {
  .filter-container {
    margin: 0;
    .filter-container__content {
      padding: 14px 20px 8px 0;
      .filter-container__action-area .btn-link {
        font-size: 14px;
      }
    }
    .panel .panel__title {
      font-size: 16px;
      white-space: nowrap;
    }
  }
  .filter-container-contact-history {
    margin: 8px 0 0 0;
  }
  .filter-container-contact-history__item {
    &:first-child {
      margin-right: auto;
    }
    &:last-child {
      margin-right: 0;
    }
    .collapsable-filter__view {
      min-width: 270px;
      span {
        margin-right: auto;
      }
    }
  }
  .filter-type__ten .popover {
    max-width: 220px;
  }
}
.data-export-setting__item--send-mail {
  .data-export-setting__table {
    grid-template-columns: 120px auto;
  }
  .form-control {
    width: 230px;
  }
}
.data-export-setting_cell-title__output {
  margin-right: 20px;
  width: 135px;
}

.data-export-setting__error-text {
  color: #ce4242;
}

.data-export-setting__google-area {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
}

.data-export-setting__cell--custom_filename {
  margin-left: 5px;
}
.form-control[name="custom_filename"] {
  width: 200px;
}
