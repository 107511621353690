@import "src/variables";

.navbar {
  .nav-link--icon.setting.ebis--disabled {
    pointer-events: auto !important;
  }
  .dropdown > .dropdown-menu {
    margin-top: 2px !important;
  }
  .dropdown-compare {
    .dropdown-toggle {
      min-width: 135px;
    }
    .dropdown-menu {
      min-width: 135px;
    }
  }
}

.dropdown.show {
  .dropdown-toggle {
    border: 1px solid color("gray-hover");
  }
}

.dropdown-month {
  min-width: 137px;
  margin-right: 30px;
  .scrollbar-wrapper {
    position: absolute;
  }
  & > .dropdown-menu, & > .dropdown-toggle {
    width: 100%;
  }
  & > .dropdown-menu {
    & > .dropdown-item:hover {
      &:first-child {
        border-radius: $dropdown-border-radius $dropdown-border-radius 0 0;
      }
    }
  }
  .dropright {
    .dropdown-toggle {
      min-width: 135px;
      width: 100%;
      padding: 3px 8px 3px 10px;
      border: none;
      border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;
      &:hover, &:focus {
        background-color: color("base");
      }
      &:after {
        border: none;
      }
      i {
        color: color("gray-dark");
        font-size: 12px;
        transform: scale(1.6, 1.3);
      }
    }
    .dropdown-menu {
      width: auto;
      min-width: auto;
      max-height: 130px;
      overflow: hidden;
      left: calc(100% - 5px);
      .dropdown-item {
        border-radius: 0;
        padding-right: 20px;
      }
    }
  }
}
