@import "src/variables";

.ebis-popover {
  max-width: 300px;
	.popover-body {
		cursor: default;
		background-color: #ffffff;
		padding: 10px;
		border-radius: 2px;
		div {
			font-weight: normal;
		}
	}
}
.ebis-popover__content {
	font-size: 13px;
	font-weight: normal;
}
.ebis-popover__icon {
	cursor: pointer;
}