.modal--csv-upload {
  .modal-header {
    justify-content: unset;
    flex-wrap: wrap; // used for line break of note text. Can apply flex-basis for note text instead
    .modal-title {
      margin-right: 10px;
    }
  }

  .modal-body {
    .error-message, .warning-message {
      margin-bottom: 10px;
    }
    .error-message + .table-confirm-detail {
      margin-bottom: 30px;
    }
    .table-confirm-detail + .warning-message {
      margin-top: 20px;
    }
  }

  .modal-footer__has-ebis-checkbox {
    justify-content: space-between;
    align-items: flex-end;
    .form-label {
      margin: 0;
    }
  }
}

.modal--csv-upload-delete {
  .modal-header {
    .modal-header__description {
      margin-top: 10px;
      flex-basis: 100%; // used for line break
    }
  }
}
