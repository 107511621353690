@import 'src/variables';

.ebis-collapse__title {
  display: flex;
  align-items: center;
  i {
    color: color('gray-dark');
    margin-left: 3px;
    transition: transform 0.35s ease;
    &.show {
      transform: rotate(-180deg);
    }
  }
}

.ebis-collapse__head {
  display: flex;
  align-items: center;
  i {
    color: color('gray-dark');
    margin-right: 5px;
    transition: transform 0.35s ease;
    &.show {
      transform: rotate(-180deg);
    }
  }
}
