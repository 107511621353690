.tutorial-modal {
  height: 500px;
  max-height: 500px;
}

.tutorial-modal__container {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
}

.tutorial-header__type1 {
  font-size: 22px;
  font-weight: bold;
}

.tutorial-subheader__type1 {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tutorial-header__type2 {
  font-size: 26px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}

.tutorial-modal--mrb-15 {
  margin-bottom: 15px;
}

.tutorial-modal__sub-header {
  text-align: center;
}

.tutorial-modal__button + .tutorial-modal__button {
  margin-left: 10px;
}

.tutorial-modal__button--lg {
  width: 232px;
  height: 44px;
}

.tutorial-modal__button--sm {
  width: 100px;
}

.tutorial-modal__button--md {
  width: 132px;
  padding: 9px 0;
}

.tutorial-modal-gsp__button {
  text-decoration: none;
  background-color: #313166;
  padding: 14px 30px;
  line-height: 1.25;
  color: #fff;
  border-radius: 3px;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

.tutorial-gsp__header {
  button.close {
    top: -45px;
    right: -15px;
    color: #666666;
  }
  &-title {
    font-size: 26px;
    font-weight: bold;
    margin-top: 40px;
  }
}
