@import "src/variables";

.menu-upload {
  position: fixed;
  box-shadow: 0 0 10px $box-shadow-color;
  border-radius: 5px;
  background-color: #ffffff;
  z-index: 1983;
  padding: 5px 0;
  &__item {
    cursor: pointer;
    padding: 0 15px;
    font: normal normal normal 13px/26px Meiryo;
    color: black;
  }
  &__item:hover {
    background: #F4F4F4 0% 0% no-repeat padding-box;
  }
}

#pagination-table {
  .controls {
    .disabled {
      cursor: default;
    }
  }
}
